import React from 'react'
import {WhatsApp} from "@mui/icons-material";
import Bubble from "./Bubble";

const WhatsappBubble = ()=>{
    return (
        <a className="mt-2 fixed bottom-10 right-5" href="https://wa.me/355693049917" target="_blank">
            <Bubble
                // onClick={handleClick}
                textOver="Whatsapp"
                backgroundColor="#25D366"
                style={{background:"#25D366"}}
            >
                <WhatsApp style={{width: "100%", height: "100%" , padding:12, color:"white"}}/>
            </Bubble>
        </a>
    );
}
export default WhatsappBubble

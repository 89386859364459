import React from "react";

export default {
"NavBar": {
        "title": "AFTËSI.AL",
        "plan": "Arsimi Profesional",
        "life": "Formimi Profesional",
        "login": "Hyr me",
        "educationalInstitutions": "Institucionet e Arsimit",
        "planStudies":"Planifiko Studimet",
        "planProffesional":"Planifiko Formimin Profesional",
        "future": "Karriera Profesionale",
        "navMenu1": {
            "school": "Gjej Institucionin Arsimor",
            "studyOrientation": "Gjej Drejtimin",
            "secondaryEducation": "Njihu me Sistemin e Aftësimit Profesional",
            "studyBranches": "Degët e Studimit",
            "howToApply": "Si të Aplikosh",
            "specialStudy": "Mundësi për Personat me Aftësi të Kufizuar",
            "courses": "Kurset",
        },
        "navMenu2": {
            "lesson": "Mësimi dhe Praktika",
            "practice": "Praktika në biznes",
            "campus": "Kampuset dhe Jeta sociale",
            "transport": "Transporti",
            "socialLife": "Jeta Sociale",
            "costScholarship": "Bursa dhe Transporti",
            "dormitory": "Konvikte"
        },
        "navMenu3": {
            "profLife": "Jeta e një Profesionisti të Ri",
            "internWork": "Punësimi dhe Internshipet",
            "internWork2": "Punësimi",
            "advice": "Këshilla karriere",
        }
    },

// Page(/shkollat)
"Shkollat": {
        "banner": "Arsimi dhe Formimi Profesional",
        "introText": "Në institucionet e aftësimit profesional, rrugëtimi i karrierës nis që ditët e para. Duke bërë progres, mundësitë për të zhvilluar lidhjet me bizneset bëhen edhe më të mëdha.",
        "filters": "Filtrat",
        "specialization": "Drejtime",
        "school": "Shkolla profesionale",
        "popupLink": "Hap në Google Maps",
        "schoolCategory": "shkolla profesionale",
        "degreeCategory": "drejtime",
        "center": "Qendra Formimi",
        "search": "Kërko Institucion",
        "institution": "Institucione",
    },

"infoBox": {
        "location": "Vendndodhja: ",
        "established": "Themeluar: ",
        "students": "Nxënës: ",
        "staff": "Staf: ",
        "website": "Website: ",
        "email": "E-mail: ",
        "facebook": "Facebook: ",
        "instagram": "Instagram: ",
    },

// Page(/shkollat-e-mesme-profesionale)
"SchoolDetail": {
        "banner": "Shkollat e Mesme Profesionale",
        "introText": "Një shkollë ku tradita dhe profesionalizmi zhvillon brezin e ri të profesionistëve. Një shkollë ku tradita dhe profesionalizmi zhvillon brezin e ri të profesionistëve",
        "mapLoacation": "Vendndodhja",
        "popupLink": "Open in Google Maps",
        "list": "Lista e Profileve",
        "degreeList": "Drejtimet që ofrohen në këtë shkollë janë: ",
        "centerList": "Kurset që ofrohen në këtë qendër janë: ",
        "middleList": "Drejtimet pas të mesme që ofrohen në këtë shkollë: ",
        "apply": "Nëse dëshiron të aplikosh kliko",
        "link": " këtu",
    },

"inputComponent": {
        "search": "Kërko (Elektronik, TIK, Mekanik etj.)"
    },

//Page(/profesionist-i-ri)
"NewProfessional": {
        "banner": "Jeta e një profesionisti të ri",
        "introText": "Zbuloni udhëtimin shpërblyes të një profesionisti në institucionet e aftësimit profesional dhe dëshmoni transformimin e tyre në individë të aftë dhe të pasionuar.",
        "p1": "Jeta e një profesionisti të ri në shkollat e arsimit profesional është një udhëtim emocionues dhe transformues. Ndërsa hyni në botën e arsimit dhe formimit profesional, ju filloni një rrugë që ndërthur mësimin akademik me përvojat praktike. Që nga dita e parë, ju zhyteni në një mjedis dinamik ku fitoni aftësi praktike dhe njohuri të rëndësishme për industrinë. Natyra praktike e programeve të arsimit profesional ju lejon të aplikoni atë që mësoni në mjedise të botës reale, duke ju përgatitur për sfidat dhe kërkesat e profesionit që keni përzgjedhur.",
        "p2": "Si një profesionist i ri, ju keni mundësinë të punoni ngushtë me instruktorë me përvojë dhe ekspertë të industrisë, të cilët ju udhëheqin dhe udhëzojnë gjatë rrugës. Ju gjithashtu bëheni pjesë e një komuniteti të individëve me të njëjtin mendim, të cilët ndajnë pasionin dhe përkushtimin tuaj. Nëpërmjet praktikave, vendosjeve në punë dhe bashkëpunimeve në industri, ju fitoni njohuri të paçmueshme në botën profesionale dhe krijoni lidhje që mund ta çojnë karrierën tuaj përpara. Jeta e një profesionisti të ri në shkollat e arsimit profesional është e mbushur me rritje, mësim dhe kënaqësi kur dihet se jeni në rrugën drejt suksesit në fushën tuaj të zgjedhur.",
        "quote": "Unë kam 2 vite që kam filluar dhe punë në një nga bizneset që bashkëpunonin me shkollën për të ofruar intrnshipe. Tranzicioni nga shkolla në botën profesionale ka qenë shumë më i lehtë falë trajnimeve praktike, projekteve bashkëpunuese, dhe praktikat në industri, të cilat më dhanë përvojë në botën reale",
    },

//Page(/praktika)
"Practice": {
        "banner": "Praktika në biznese",
        "introText": "Në shkollat e Arsimit Profesional, rrugëtimi i karrierës nis që ditët e para. Duke bërë progres, mundësitë për të zhvilluar lidhjet me bizneset bëhen edhe më të mëdha.",
    },

//Titles
"Titles": {
        "title1": "Punësimi dhe Internshipet",
        "title2": "Këshilla karriere",
        "title3": "Ndihmëza praktike"
    },

//Footer
"Footer": {
        "other": "Faqe të tjera",
        "follow": "Na ndiqni",
        "subtitle": "Lajme.",
        "title1": "Mos e humbisni Newsletter tonë.",
        "title2": "Regjistrohuni!",
        "statistics":"Statistika",
        "aboutUs":"Rreth faqes",
        "privacy":"Politikat e privatësisë",
        "terms":"Kushtet e përdorimit",
        "info":"Informacione të dobishme",
        "contact":"Kontakto",
        "copyright":"Ky sistem hostohet nga Agjencia Kombëtare e Shoqërisë së Informacionit. Ky sistem elektronik mirëmbahet nga operatori ekonomik: Communication Progress"
    },

//Homepage
"Homepage": {
        "banner": "Gjej rrugën Drejt Profesionit",
        "banner2": "Gjej rrugën Drejt",
        "bannerBlock": "Profesionit",
        "directionOfStudies": "Gjej Drejtimin e Studimit",
        "directionOfStudiesDescription": "Zgjidh drejtimin e studimit për të ndërtuar një karrierë të suksesshme të përshtatur plotësisht me kërkesat e tregut të punës.",
        "aboutInstitution": "Gjej Institucionin Arsimor",
        "aboutInstitutionDescription": "Në institucionet e aftësimit profesional, mundësitë për të zhvilluar lidhjet me bizneset bëhen edhe më të mëdha.",
        "applyOnline": "Si të aplikosh online në shkollat e Arsimit Profesional dhe Qendrat e Formimit?",
        "internship": "Punësimi dhe Internshipet",
        "internshipDetails": "A e dini se një në dy studentë punësohen menjëherë me mbarimin e studimeve? Natyrisht, përveç punësimit, ju do të keni mundësi të ndiqni edhe internshipe të ndryshme që kalimi në punësim të jetë super i lehtë.",
        "practicalLearning": "Mësimi dhe Praktika",
        "transportGrants": "Bursat dhe transporti",
        "njhuniMeAfpro": "NJHUNIMEAFPro",
        "njhuniMeAfproDetails": "Jeni një biznes apo mentor, bëhu pjesë e njhuri",
        "socialMedia": "Na ndiqni edhe në mediat sociale",
        "buttonElTitle": "Njihuni me AFPro",
        "buttonElText": "Je një biznes apo mentor. Bëhu pjesë!",
        "afpSistem" : "Njihu me Sistemin e Aftësimit Profesional në Shqipëri",
        "afpSistemDescription": "Sistemi i arsimit dhe formimit profesional publik përbëhet nga 41 institucione nga të cilat 31 shkolla profesionale dhe 10 qendra formimi të cilat ofrojnë kualiﬁkime profesionale të mesme pas të mesme dhe afatshkurtra, për të gjitha moshat.",
        "inputBanner":{
                "infoBall1": <><span>Regjistrohu në</span> <br /><span>Arsim Profesional</span></>,
                "infoBall2": <><span>Regjistrohu në</span> <br /><span>Kurse Profesionale</span></> ,
                "infoBall3": <><span>Zbulo</span> <br /><span>më shumë</span></>
        }
    },

//Search
"Search": {
        "noResults": "Nuk janë gjetur rezultate",
        "categorySchool": "Shkollë",
        "categoryDegree": "Degë",
        "searchResultSchool": "Shkolla",
        "centers": "Qendra Formimi",
        "universities": "Universitete",
    },

"SearchFilters": {
        "institutionType": "Institucioni",
        "typeSchool": "Shkollë Profesionale",
        "typeCenter": "Qendër Formimi",
        "municipality": "Bashkia",
        "ownership": "Pronësia",
        "public": "Publik",
        "private": "Privat"
},

//SearchCard
"SearchCard": {
        "accordionTitleDegree": "Drejtime",
        "accordionTitleCourse": "Kurse"
},

"siTeAplikosh": {
        "mainTitle": "Si të aplikosh",
        "intro": "Procesi i aplikimit për te ndjekur studimet ne sistemin e aftesimit profesional ne Shqiperi ndryshon në varësi të institucionit në të cilin dëshiron të aplikosh.",
        "link": "këtu",
        "register": "Regjistrimi në klasë të dhjetë, në shkollë të mesme profesionale",

        "shkollaTitle": "Regjistrimi në klasë të dhjetë, në shkollë të mesme profesionale",
        "shkollaP1": "Aplikimet për regjistrim në klasë të dhjetë, në shkollë të mesme profesionale në Shqipëri kryhet nëpërmjet platformës e-Albania nëpërmjet shërbimi elektronik ",
        "shkollaP2": ", duke filluar nga data 8 Korrik 2024 deri më datë 30 Shtator 2024 ora 23:59 .",
        "shkollaP3": "Shërbimi elektronik \"Regjistrimi në klasë të dhjetë, në shkollë të mesme profesionale\" u vjen në ndihmë prindërve dhe personave që ushtrojnë përgjegjësinë prindërore të regjistrojnë fëmijën në klasën e 10-të, në shkollë të mesme profesionale. Personi që ushtron përgjegjësinë prindërore, bën aplikimin online duke plotësuar të dhënat e nevojshme të formularit dhe pret përgjigjen e miratimit ose refuzimit të aplikimit brenda 10 ditëve nga data e gjenerimit të dëftesës.",
        "shkollaP4": "Ofruesit e Arsimit Profesional pranojnë nxënës nga të gjitha shkollat e arsimit bazë, pavarësisht zonës gjeografike. Në klasën e dhjetë të arsimit të mesëm profesional pranohen nxënës të moshës jo më të madhe se 18 vjeç dhe lejohen të ndjekin shkollën deri në moshën 22 vjeç. Në arsimin e mesëm profesional nxënësit pranohen pa konkurs. Në raste të veçanta, drejtoria e ofruesit të AFP-së, përcakton kuota bazuar në kritere të posaçme, në bazë të prioriteteve dhe objektivave të planit afatmesëm.",
        "shkollaP5": "Kur në një ofrues i AFP-së, në vitin akademik paraardhës ka pasur kërkesa më të mëdha se numri i nxënësve të regjistruar për drejtim mësimor, për aplikimet e bëra brenda 7 ditëve nga hapja e regjistrimeve, nxënësit përzgjidhen sipas vlerësimit të kritereve në radhë: vendbanimit të nxënësit brenda qarkut ku operon ofruesi, mesatares më të lartë të notave të dëftesës së arsimit bazë, datës më të hershme të aplikimit. Në rast se ka kapacitete të lira pas vlerësimit të bërë për aplikimet e bëra në 7 diteshin e parë, pranimi bëhet sipas parimit kush aplikon i pari pranohet i pari bazuar në mesataren minimale të pranuar një vit më parë për drejtimin mësimor.",
        "shkollaP6Title": "Sugjerim: ",
        "shkollaP6": "Para se të aplikoni, ju inkurajojmë të identifikoni institucionet arsimore që ofrojnë arsim profesional në fushën e interesit tuaj. Për tu informuar mbi programet e disponueshme, ju mund të kontrolloni dhe shfletoni ",
        "shkollaProfesionale": "Shkollat Profesionale",
        "degree": "Drejtimet",
        "and": " dhe ",
        "shkollaDokTitle": "Dokumentacioni e nevojshëm për tu ngarkuar në aplikim:",
        "shkollaL1": "Kur aplikimi bëhet nga kujdestari ligjor i fëmijës, duhet të ngarkohet dokumenti i kujdestarisë ligjore;",
        "shkollaP7": "Personi që ushtron përgjegjësinë prindërore, bën aplikimin online duke plotësuar të dhënat e nevojshme të formularit dhe pret përgjigjen e miratimit ose refuzimit të aplikimit. ",
        "shkollaP8": "Shkolla do të njoftojë elektronikisht aplikuesin në rast pranimi ose mospranimi të regjistrimit brenda 5 ditëve. ",
        "shkollaP9": "Për informacion të detajuar për programet mësimore që ofrohen në sistemin publik të AP-së, klikoni në linkun ",

        "univTitle": "Regjistrimi në drejtimin pas të mesëm:",
        "univP1": "Regjistrimi i nxënësit në arsimin pas të mesëm bëhet në shkollë brenda afatit të aplikimit të shpallur nga shkolla.",
        "univP2": "Avantazhet e regjistrimit në arsimim profesional: ",
        "univL1": "Vajzat që regjistrohen në arsimin profesional përfitojnë 50 % të bursës.",
        "univL2": "Të gjithë nxënësit që regjistrohen në drejtimet mësimore: bujqësi, veterinari, pyje, ndërtim, peshkim, detari, gjeologji-miniera dhe shpim e shfrytëzim i vendburimeve të naftës dhe gazit, përfitojnë bursë për të gjitha vitet e shkollimit. ",
        "univL3": "Të gjithë nxënësve që regjistrohen në arsimin profesional iu mbulohen shpenzimet e transportit për udhëtimet nga vendbanimi i përhershëm në një vendbanim tjetër, në distanca nga 5 km deri në 50 km larg nga ofruesi publik i arsimit profesional.",
        "univL4": "Pas mbarimit të Arsimit Profesional, nxënësit pajisen me aftësi që kërkon tregu i punës, dhe nuk penalizohen në vijimin e studimeve universitare. ",
        "centerTitle": "Regjistrimi në kurs formimi profesional:",
        "centerP1": "Sistemi Publik i Formimit Profesional ofron mundësi për kualifikim dhe rikualifikim në profesionet më të kërkuara në qarqet kryesore të vendit. Kurset profesionale, të ofruara nga Qendrat e Formimit Profesional Publike, konsiderohen si kualifikime profesionale afatshkurtra dhe ofrohen falas për të gjithë punëkërkuesit e papunë të regjistruar në zyrën e punësimit përkatëse (me përjashtim të kurseve të gjuhëve të huaja)",
        "centerP2": "Nëse jeni të papunë dhe nuk jeni të regjistruar në zyrën e punësimit, për të përfituar këtë shërbim pa pagesë, duhet të regjistroheni si punëkërkues përmes platformës e-Albania në shërbimin",
        "linkP2": "Aplikim për regjistrim si punëkërkues",
        "centerP3": "Pas marrjes së statusit \"Punëkërkues i Papunë\", ju mund të referoheni nga Zyra e Punësimit për ndjekjen e një kursi formimi profesional pa pagesë ose mund të aplikoni vetë nëpërmjet platformës e-Albania. Aplikimi për kursin e formimit profesional bëhet online përmes portalit qeveritar e-Albania, në shërbimin ",
        "linkP3": "Aplikim për regjistrim në kurs formimi profesional",
        "centerP4": "Dokumentacioni i nevojshëm për aplikim",
        "centerP5": "-Dëftesë/Diploma e nivelit më të lartë arsimor.",
        "centerP6": "Koha e nevojshme e marrjes së shërbimit",
        "centerP7": "14 ditë",
        "centerP8": "Për çdo pyetje apo paqartësi rreth procesit, mund të kontaktoni në adresën ",
        "centerP9": "kontakt@akpa.gov.al.",
        "centerP10": "Para se të aplikoni, ju inkurajojmë të informoheni mbi kurset profesionale në fushën e interesit tuaj dhe programet përkatëse, duke shfletuar",
        "centerP11": "Qendrat e Formimit Profesional Publike ",
        "centerP12": "Kurset e Formimit Profesional ",
        "centerP13": " që ofrohen në institucionet publike të formimit profesional.",

        "univP3": "Për të gjithë nxënësit që janë regjistruar në një gjimnaz apo në një shkollë tjetër, dhe kanë dëshirë të vijojnë studimet në arsimin profesional, kanë të drejtë të bëjnë ç'regjistrimin apo anullimin e aplikimit deri një ditë para vazhdimit të shkollës dhe mund të regjistrohen në shkollat e Arsimit Profesional në shërbimin",
        "univP4": "Dokumentacioni i nevojshëm",
        "univP5": "- Dokumenti i kujdestarisë ligjore në rastet kur fëmija regjistrohet nga personi që ka kujdestarinë ligjore (vetëm kur aplikimi bëhet nga kujdestari ligjor i fëmijës);",
        "univP6": "Koha e nevojshme e marrjes së shërbimit",
        "univP7": "10 ditë",
        "univP8": "Për çdo pyetje apo paqartësi rreth procesit, mund të kontaktoni në adresën ",
        "univP9": "kontakt@akpa.gov.al.",
        "univP10": "Sugjerim:",
        "univP11": "Para se të aplikoni, ju inkurajojmë të identifikoni institucionet arsimore që ofrojnë arsim profesional në fushën e interesit tuaj. Për tu informuar mbi programet e disponueshme, ju mund të kontrolloni dhe shfletoni",
        "univP12": "Shkollat Profesionale",
        "univP13": "Drejtimet",
        "aplikimKursProfesional": "“Aplikim për regjistrim në kurs formimi profesional” ",
        "kliko": " këtu",
    },

//Page(bursa)
"BursaTransport": {
        "mainTitle": "Bursa dhe Transporti",
        "intro": "Shkollat profesionale ofrojnë bursa dhe programe të rimbursimit të transportit, duke siguruar akses dhe mbështetje për studentët që ndjekin udhëtimin e tyre të arsimit profesional.",
        "p1": "Barrierat financiare nuk duhet të pengojnë asnjëherë ndjekjen e arsimit professional, për këtë arsye ne ofrojmë një program gjithëpërfshirës bursash që vlerësojnë arritjet akademike, talentin dhe përkushtimin. Bursat tona u ofrojnë studentëve të kategorive përftuese ndihmë financiare, duke i fuqizuar ata të ndjekin ëndrrat e tyre dhe të arrijnë qëllimet e tyre të karrierës.",
        "p2": "Përveç bursave, ne e kuptojmë se transporti mund të jetë një sfidë për nxënësit tanë, të cilët jo domosdoshmërisht banojnë në afërsi të kampuseve. Ne duam që udhëtimi drejt dhe nga institucionet tona arsimore të jetë i përshtatshëm dhe i përballueshëm. Kjo është arsyeja pse ne ofrojmë një program të rimbursimit të transportit, duke i lejuar studentët e kualifikuar të marrin mbështetje financiare për të mbuluar shpenzimet e tyre të transportit.",
        "p3": "Duke kombinuar bursat dhe rimbursimin e transportit, ne përpiqemi të krijojmë një mjedis mësimor gjithëpërfshirës dhe mbështetës për të gjithë studentët tanë.",
        "criterionsTitle": "Afati për dorëzimin e dokumenteve është jo më vonë se muaji nëntor i çdo viti.",
        "criterions": "Lista e dokumentacionit për aplikim për bursë sipas kritereve të përcaktuara në VKM 666, datë 10.10.2019, i ndryshuar, për sistemin arsimor parauniversitar profesional:",
        "docs": "Dokumentat që duhet të depozitohen:",
        "EconomicCriterionTitle": "Kriteri ekonomik:",
        "EconomicCriterionDesc": "Nxënësit që ndjekin procesin mësimor në një nga shkollat e arsimit profesional me largësi 5 km nga vendbanimi i tyre përfitojnë akomodim falas në konvikte si dhe gjysmë burse ose bursë të plotë në varësi të përbërjes familjare dhe të ardhurave për frymë. Nxënësit të cilët nuk jetojnë në konvikt por plotësojnë kriteret për bursë u paguhet vlera e plotë e bursës në bankat e nivelit të dytë.",
        "economicL5": "Vërtetim të ardhurash/page për çdo anëtar madhor të familjes",
        "economicL6": "Vërtetim punëkërkuesi për çdo madhor të familjes (të papunë)",
        "economicL7": "Vërtetim pagese PAK/NE (vërtetim i muajit të fundit me vulë të njomë nga Njësia Administrative)",
        "economicL8": "Vërtetim pagese pensioni pleqerie",
        "economicL9": "Vërtetim pagese pensioni invaliditeti",
        "economicL11": "Vërtetim nga Njësia Administrative që nuk ka shkollë 9-vjeçare brenda territorit të Njësisë Administrative (vetëm për pikën 2.1/b)",
        "ResultCriterionTitle": "Kriteri i rezultateve të arritura:",
        "ResultCriterionDesc": "Nxënësit e arsimit profesional të cilët kanë mbaruar vitin shkollor paraardhës me notën mesatare 9(nëntë) deri në 10(dhjetë), përfitojnë bursë të plotë.",
        "resultL1": "Certifikatë personale",
        "resultL2": "Certifikatë familjare",
        "resultL3": "Vërtetim nga shkolla (për regjistrim dhe frekuentim të rregullt)",
        "resultL5": "Dëftese shkollë profesionale për vitin pararendës me të gjitha notat 9-10.",
        "NonEconomicCriterionTitle": "Kriteri joekonomik:",
        "Ol1": "Nxënësit, të cilët kanë përfituar statusin ligjor të jetimit, u sigurohen falas:",
        "Ol1Li1": "bursë,",
        "Ol1Li2": "akomodimi/fjetja,",
        "Ol1Li3": "shërbimi shëndetësor,",
        "Ol1Li4": "veshmbathje, libra dhe fletore (10,000 lekë në vit)",
        "Ol1Li5": "shërbimi dentar dhe medikamentet.",
        "Ol1Li6": "pagesë suplementare edhe për periudhën e pushimeve verore",
        "Ol2": "Përfitojnë bursë të plotë nxënësit të cilët ndjekin me kohë të plotë arsimin profesional në drejtimet mësimore:",
        "Ol2Li1": "Bujqësi",
        "Ol2Li2": "Veterinari,",
        "Ol2Li3": "Pyje,",
        "Ol2Li4": "Ndërtim,",
        "Ol2Li5": "Peshkim,",
        "Ol2Li6": "Detari,",
        "Ol2Li7": "Gjeologji-miniera,",
        "Ol2Li8": "Shpime shfrytëzim i vendburimeve të naftës dhe të gazit;",
        "Ol3": "Nxënëset vajza që regjistrohen në arsimin profesional në të gjitha drejtimet mësimore përfitojnë gjysmë burse, ndërsa për drejtimet: ",
        "Ol3Li1": "mekanikë,",
        "Ol3Li2": "elektronikë,",
        "Ol3Li3": "elektroteknikë,",
        "Ol3Li4": "përpunim druri,",
        "Ol3Li5": "gjeodezi,",
        "Ol3Li6": "shërbime mjete transporti, ",
        "Ol3Li7": "termo hidraulikë, përfitojnë bursë të plotë.",
        "Ol4": "Nxënësit e pakicës kombëtare rom dhe egjiptiane, të cilët ndjekin shkollat e mesme profesionale, përfitojnë falas:",
        "O4Li1": "Bursë e plotë,",
        "Ol4Li2": "veshmbathje, libra dhe fletore, (10,000 lekë në vit)",
        "Ol5": "Nxënësit të cilët vërtetojnë statusin ligjor të invalidit paraplegjik, tetraplegjik, si dhe personat me aftesi të kufizuar të percaktuar nga komisioni mjekoligjor, u sigurohen falas:",
        "Ol5L1": "Bursë e plotë,",
        "Ol5L2": "akomodimi/fjetja,",
        "Ol5L3": "shërbimi shëndetësor,",
        "Ol5L4": "shërbimi dentar dhe medikamentet,",
        "Ol5L5": "mësues ndihmës, pas vlerësimit të nevojës për arsim profesional të nxënësit me aftësi të kufizuar.",
        "Ol6": "Nxënësit me një prind kur njëri nga prindërit nuk jeton, u sigurohen falas:",
        "Ol6Li1": "Pagesë suplementare edhe për periudhën e pushimeve verore",
        "costTitle": "RIMBURSIM I KOSTOS SË TRANSPORTIT",
        "quote": "Të gjithë nxënësit/prindërit dhe drejtoritë e shkollave profesionale, për nxënësit që regjistrohen për herë të parë në klasë të 10-të dhe që përfitojnë bursë sipas kritereve të përcaktuara në Vendimin nr.666, datë 10.10.2019 \"Për kuotat financiare të ushqimit në mensa e konvikte, përcaktimin e kritereve për përfitimin e bursave dhe pagesave për nxënësit e arsimit parauniversitar në institucionet arsimore publike\", i dërgojnë zyrtarisht kërkesat dhe dokumentacionin përkatës sipas kategorive përfituese për miratimin e bursës në njësitë e vetëqeverisjes vendore të vendbanimit të përhershëm të nxënësit.",
        "costDesc" : "Në zbatim të VKM-së nr.119, datë 01.03.2023 ‘Për përdorimin e fondeve publike për transportin e punonjësve arsimorë që punojnë dhe të nxënësve që mësojnë jashtë vendbanimit’ nxënësve që ndjekin arsimin profesional publik, që udhëtojnë nga vendbanimi i përhershëm në një vendbanim tjetër, mbi 5 km deri në 50 km larg ofruesit publik të arsimit profesional iu mbulohet kosto e transportit.",
        "cat1L1": "Certifikatë personale (mund të shkarkohet nga e-albania)",
        "cat1L2": "Certifikatë familjare (mund të shkarkohet nga e-albania)",
        "cat1L3": "Vërtetim nga shkolla (për regjistrim, frekuentim të rregullt dhe jo mbetës)",
        "cat1L4": "Dëshmi e statusit të jetimit. ",
        "cat1L5": "Vërtetim nga tatimet që ka/nuk ka biznes për cdo anëtar madhor të familjes (Shkarkohet nga e-albania në shërbimin Vërtetim për individë të parregjistruar)",
        "catL6": "Vërtetim nga konviktet (mbi regjistrimin apo pamundësinë për akomodim)",
        "catL7": "Vërtetim nga tatimet që ka/nuk ka biznes për çdo anëtar madhor të familjes (shkarkohet nga e-albania në shërbimin Vërtetim për individë të pa-regjistruar)",
        "catL8": "Vërtetim nga shkolla profesionale në degët parësore të zhvillimit ekonomik të vendit.",
        "catL9": "Vërtetim për nxënëset (vajza) nga shkolla profesionale në drejtimet mësimore mekanikë, elektroteknikë, përpunim druri, gjeodezi, shërbim mjete transporti, termo-hidraulikë.",
        "catL10": "Vërtetim si anëtar i komunitetit Rom/Egjiptian",
        "catL11": "Vërtetim mbi statusin e invaliditetit para-tetraplegjik dhe pagesën e PAK. (mund të shkarkohet nga e-albania ose të merret tek Njësia Administrative)",

    },

//Page(arsimi-i-mesem)
"Highschool": {
        "banner": "Njihu me Sistemin  e Aftësimit Profesional",
        "introText": "Sistemi i arsimit dhe formimit profesional publik përbëhet nga 41 institucione nga të cilat 31  shkolla profesionale dhe 10 qendra formimi të cilat ofrojnë kualifikime profesionale të mesme pas të mesme dhe afatshkurtra, për të gjitha moshat.",
        "list1": "nxënës qe kane mbaruar arsimin 9 vjeçar dhe duan te vijojnë arsimin e mesëm (sistemi i arsimit profesional),",
        "list2": "maturante qe kane përfunduar studimet e mesme dhe duan te vijojnë studimet ne nivele me te larta (sistemi pas i mesëm apo universitar)",
        "list3": "te rritur me aftësi apo pa aftësi qe duan te pasurojnë portofolin e tyre me aftësi shtese afatshkurtër (sistemi i formimit profesional)",
        "ShkollaP1": "Arsimi i mesëm profesional i ofron një individi mundësi kualifikimi të nivelit II, III, IV të Kornizës Shqiptare të Kualifikimeve nga punonjës të kualifikuar në teknik të mesëm në fushën përkatëse të studimit. ",
        "ShkollaP2": "Institucionet e arsimit të mesëm profesional publik ofrojnë 22 drejtime studimi dhe 83 mundësi profilizimi.",
        "ShkollaP3": "Në krahasim me arsimin e përgjithshëm, arsimi profesional ofron një fokus më të thellë në një fushë të caktuar dhe përgatit studentët për profesione specifike. Ky lloj arsimimi përqendrohet në aftësitë praktike dhe zgjerimin e njohurive të aplikueshme në vend të njohurive teorike të gjerësisë së lëndëve.",
        "ShkollaP4": "Pas përfundimit të këtij programi, studentët marrin një certifikatë ose një diplomë profesionale, që i jep atyre mundësinë për t'u integruar direkt në tregun e punës si specialist në fushën përkatëse të studimit. Ata gjithashtu mund të vazhdojnë studimet e larta në arsimin profesional pas të mesëm ose ato universitare.",
        "ShkollaP5": "Për informacion të detajuar për programet mësimore që ofrohen në sistemin publik të Aftësimit Profesional njihuni me ",
        "link": "drejtimet që ofrohen",
        "and": " dhe ",

        "arsimiPasTeMesem": <>
                Arsimi profesional pas i mesëm i ofron një individi mundësi kualifikimi të Nivelit të V të Kornizës Shqiptare të Kualifikimeve, dizenjuar për të përmbushur nevojat e tregut të punës për profesionistë të kualifikuar me njohuri të thelluara dhe aftësi të specializuara. Kualifikimet e këtij niveli përfshijnë shkathtësi të specializuara teknike, menaxhuese dhe të komunikimit, si dhe njohuri të thelluara të proceseve dhe procedurave të fushës së tyre të specializimit. Në përfundim te këtij niveli, individët janë të përgatitur për të kryer detyra komplekse duke marr përgjegjësi të mëdha dhe të menaxhojnë projekte të rëndësishme në fushën e tyre të specializimit.<br/><br/>
                Pas përfundimit të programit studimor profesional, studentët marrin një certifikatë profesionale, që i jep atyre mundësinë për t'u integruar direkt në tregun e punës si specialist në fushën përkatëse të studimit.<br/><br/>
                Institucionet e arsimit të mesëm profesional publike ofrojnë 7 drejtime pas të mesme me mundësi vijimi në studimet e larta në arsimin universitar.<br/><br/>
                Për informacion të detajuar për programet mësimore që ofrohen në sistemin publik të Aftësimit Profesional njihuni me {" "}
        </>,
        "arsimiLink":"drejtimet pas të mesme",

        "centerP1": "Sistemi Publik i Formimit Profesional ofron mundësi për kualifikim dhe rikualifikim në profesionet më të kërkuara në qarqet më të rëndësishme të vendit.",
        "centerP2": "Qendrat e Formimit Profesional Publike në vend ofrojnë një gamë prej 100 kurseve afat-shkurtra të formimit profesional, si dhe kurse të Aftësisë Digjitale, Start Smart dhe Gjuhëve të Huaja (Anglisht, Gjermanisht dhe Italisht). Kurset profesionale konsiderohen kualifikime profesionale afatshkurtra, të ofruara nga Qendrat e Formimit Profesional Publike, për kategori të ndryshme individësh, dhe ofrohen falas për të gjithë punëkërkuesit e papunë të regjistruar në zyrën përkatëse të punësimit.",
        "centerP3": "Kurrikulat e këtyre kurseve janë të unifikuara në nivel kombëtar dhe në nivel qendror, të orientuara dhe të hartuara nga Agjencia Kombëtare e Arsimit dhe e Formimit Profesional & Kualifikimeve (AKAFP&K) dhe të miratuara nga Ministria Përgjegjëse për AFP-në. Ato ofrohen në të njëjtën formë nga të gjithë institucionet publike të formimit profesional. Pas përfundimit me sukses të kursit, ju mund të pajiseni me certifikata të vlefshme në tregun e punës.",
        "centerP4": "Për informacion të detajuar për kualifikimet profesionale që ofrohen në sistemin publik të Formimit Profesional njihuni me ",
        "centerLink": "kurset e formimit profesional ",
        "apply": "Si te aplikosh",

        "shkolla": "Lista e shkollave profesionale",
        "qendra": "Lista e qendrave te formimit profesionale publike",
    },

//Page(aftesi-ndryshe)
"SpecialAbilities": {
        "banner": "Mundësi për personat me Aftësi të Kufizuar",
        "introText": "Arsimi dhe Formimi Profesional në Shqipëri ofron mundësi Studimi edhe për Personat me Aftësi të kufizuara, nëpërmjet përshtatjes së arsyeshme të ambjenteve në institucione si dhe mbështetje të veçanta nëpërmjet mësuesve ndihmës me specializime të posacme për ta. Gjatë studimit nxënësit PAK përvecse nuk humbin pagesat për Aftësinë e Kufizuar por ata mbështeten edhe me bursa studimore.",
        "socialTitle": "Rreth Fondit Social",
        "socialText": "Qëllimi i Fondit Social të Punësimit është sigurimi dhe administrimi i mekanizmave financiare për nxitjen e punësimit, integrimin dhe përfshirjen sociale të personave me aftësi të kufizuara, përfshirë personat që nuk dëgjojnë dhe invalidët e punës të vlerësuar pjesërisht të aftë për punë nga komisionet përkatëse.\n",

    },

//Page(CampusesAndSocialLife)
"CampusesAndSocialLife": {
        "banner": "Kampuset dhe Jeta sociale",
        "generalActivity": "Aktivitete të përgjithshme ",
        "generalActivityL1": "Kampionati Kombëtar i Shahut në bashkëpunimin me Federatën Shqiptare të Shahut. Faza e parë të zhvillohet në bazë shkolle në muajin dhjetor, faza e dytë në bazë qarku në muajin shkurt dhe finalja në mars-prill.",
        "generalActivityL2": "Java Evropiane e Sportit,",
        "generalActivityL3": "Java Olimpike ,",
        "generalActivityL4": "Olimpiadat kombëtare ",
        "generalActivityL5": "Dita Evropiane e Gjuhëve, ",
        "generalActivityL6": "Dita Botërore e Gjuhës Rome, UNESCO,",
        "generalActivityL7": "Dita Ndërkombëtare e të Drejtave të Fëmijëve, ",
        "generalActivityL8": "Dita e Luftës Kundër Bullizmit,",
        "generalActivityL9": "Dita Ndërkombëtare e të Drejtave të Personave me Aftësi të Kufizuar, ",
        "generalActivityL10": "Dita e të Drejtave të Njeriut, UNESCO",
        "generalActivityL11": "Dita Ndërkombëtare e Emigrantëve, UNESCO, ",
        "generalActivityL12": "Përkujtimi i Holokaustit dhe i krimeve kundër njerëzimit, ",
        "generalActivityL13": "Dita Botërore e Gjuhës Amtare, ",
        "generalActivityL14": "Dita Ndërkombëtare kushtuar Sportit për Zhvillim e Paqe,",
        "generalActivityL15": "Dita Ndërkombëtare e Romëve,",
        "generalActivityL16": "Dita Botërore e Librit, ",
        "generalActivityL17": "Dita Ndërkombëtare e-twinning,",
        "generalActivityL18": "Dita kundër Shfrytëzimit të Punës së Fëmijëve, ",
        "generalActivityL19": "Dita Evropiane e Kujtesës për të gjitha viktimat e regjimeve totalitare",
        "generalActivityL20": "Dita Ndërkombëtare e Edukimit, UNESCO, ",
        "generalActivityL21": "Dita Ndërkombëtare e Matematikës, UNESCO, ",
        "generalActivityL22": "Dita Botërore e Poezisë, UNESCO,",
        "generalActivityL23": "Dita Botërore e Ujit, UNESCO, ",
        "generalActivityL24": "Dita Ndërkombëtare e Bashkëjetesës në Paqe, UNESCO, ",
        "generalActivityL25": "Dita Botërore për Diversitet Kulturor, Dialog dhe Zhvillim. UNESCO, ",
        "generalActivityL26": "Dita Botërore e Mjedisit, UNESCO, ",
        "generalActivityL27": "Dita Ndërkombëtare e Rinisë, UNESCO, ",
        "generalActivityL28": "Kalendari i Ditëve të Evropës dhe veprimtaritë gjithëvjetore për njohjen e institucioneve të Bashkimit Evropian dhe të Këshillit të Evropës.",
        "generalActivityL29": "Konkurset në nivel institucioni arsimor vendor, përgjegjës për arsimin parauniversitar, me tematikë ” Të njohim Evropën” ",
        "generalActivityL30": "Java Evropiane e Lëvizjes “Now we move”",
        "generalActivityL31": "Ditët e festave kombëtare.",
        "activity": "Aktivitete",
        "activityL1": "“Ditët e dyerve të hapura” ",
        "activityL1Desc": "Shkollat Profesionale presin nxënësit e klasave të 9-ta, ku synohet: Informimin e nxënësve dhe prindërve të tyre mbi ofertën publike të arsimit profesional dhe mundësitë për punësim apo studime të mëtejshme, njohjen me mjediset e shkollës, kryesisht atyre të praktikave profesionale ku mësohet profesioni, ndergjegjesimin e brezit të ri mbi rëndësinë e profesionit dhe lidhjen e tij me tregun e punës, promovimin e impaktit të arsimit dhe formimit profesional në rritjen e nivelit të punësueshmërisë së të rinjve, informimin mbi nevojat e tregut të punës për profesionistë dhe mundësitë për praktika profesionale në bizneset më të njohura.",
        "activityL2": "Takime me bizneset partnere të shkollës",
        "activityL2Desc": "me aktorë të tregut të punës zyrat e punës etj për rritjen e mundësive për praktikë profesionale dhe punësim.",
        "competition": "Konkurse",
        "competitionL1": "\"Gastro Alb 6\", National Proffesional Schools",
        "competitionL1Desc": "Gastro Alb eshte konkursi me i rendesishem deri me tani per sektorin i cili organizohet cdo vit i cili u zhvillua në Food & Drink Expo 2023, ku konkurojnë të gjithë shkollat profesionale të turizmit në vend.",
        "competitionL2": "Konkursi “Re-Debate”",
        "competitionL2Desc": "Qëllimi kryesor i konkursit është promovimi i kulturës së debatit në Shqipëri tek të rinjtë e shkollave të mesme, kultivimin e të menduarit kritik, etikën në komunikim, tolerancën dhe vlert e qytetarisë active. Ky konkurs ka tre faza:",
        "competitionL2DescL1": "Faza e trajnimeve ",
        "competitionL2DescL2": "Faza e debateve lokale",
        "competitionL2DescL3": "Faza finale",
        "competitionL3": "Panairi Ndërkombëtar FOOD & DRINK EXPO 2023",
        "competitionL3Desc": "Edicioni i 5-të,me pjesëmarrjen e shkollave profesionale në fushën  Hoteleri-Turizëm",
        "competitionL4": "Konkurse midis shkollave në të njëjtat drejtime",
        "competitionL4Desc": "Pjesëmarrja e nxënësve të shkollave profesionale në konkurse në nivel shkolle, kombëtare dhe ndërkombëtare.",
        "expo": "Panaire",
        "expoL1": "Expo Turizëm 2023, dhe konkursi Tourism Ideation Hackathon",
        "expoL1Desc": "Zhvillohet cdo vit konkursi Tourism Ideation Hackathon, si një mundësi për t’i ofruar të rinjve të apasionuar pas turizmit, natyrës dhe mjedisit një ambjent krijues për paketën turistike më tërheqëse për të gjithë turistët, duke shpalosur zgjidhje inteligjente për zhvillimin e turizmit të qëndrueshëm, bazuar edhe në premisën se teknologjia mund të luajë një rol kyç në bërjen e destinacioneve turistike më të qëndrueshme si dhe më konkurruese.",
        "expoL2": "Agrotech EXPO",
        "expoL2Desc": "Edicioni i 4-t i Panairit Ndërkombëtar të Bujqësisë, Blegtorisë dhe Kopshtarisë AGROTECH EXPO, i organizuar nga Expocity Albania, do të zhvillohet në datat 18-20 Tetor, 2023.",
        "expoL3": "Panairi i Punës dhe Aftësive në nivel kombëtar me titull “Mundësi. Aftësi. Punësim”",
        "expoL3Desc": "Panairi i Punës ndihmon përshtatjen e ofertës dhe kërkesës në tregun e punës dhe do të ndihmojë për të menaxhuar në mënyrë adeguate të gjitha mundësitë e ofruara dhe potencialin e fuqisë punëtore.\n" +
            "Ky panair zhvillohet në të gjitha qytetet dhe përmbyllja u bë me datë 25-26 Prill.\n",
        "expoL4": "Panairet që organizohen në nivel shkollë",
        "expoL4Desc": "Nxënësit e shkollave profesionale zhvillojnë panaire në periudha të ndryshme të vitit, duke demostruar aftësitë e fituara gjatë mësimnxënies në shkollat profesionale.",
    },

//Page(internship)
"Internship": {
        "banner": "Internship"
    },

//Page(learning)
"LearningAndPractice": {
        "banner": "Mësimi dhe Praktika",
        "intro": "Procesi i të mësuarit në shkollat e Arsimit Profesional është dinamik dhe praktik, duke i përgatitur studentët për sukses në botën reale.",
        "p": "Kurrikula jonë fokusohet në aftësitë praktike dhe njohuritë përkatëse për industrinë, duke i lejuar studentët të zbatojnë atë që mësojnë në një mjedis mbështetës dhe ndërveprues. Nëpërmjet një kombinimi të mësimdhënies në klasë, orëve praktike në shkollë dhe atyre në industri, studentët fitojnë përvojë të vlefshme dhe zhvillojnë një themel të fortë në fushat e tyre të zgjedhura. Mësuesit tanë drejtojnë dhe mentorojnë studentët, duke nxitur një pasion për të mësuarit gjatë gjithë jetës dhe duke u siguruar që ata janë të pajisur mirë për të përmbushur kërkesat e tregut të punës gjithnjë në zhvillim. Në institucionet e aftësimit profesional, procesi i të mësuarit është një udhëtim gjithëpërfshirës dhe transformues, duke i fuqizuar studentët të hyjnë me besim në botën profesionale.",
    },

//Page(dormitory)
"Dormitory": {
        "banner": "Konviktet",
        "intro": "Akomodimi në konvikte i nxënësve të arsimit profesional.",
        "p": "Nxënësit e Shkollave të Mesme Profesionale në të gjithë Shqipërinë kanë mundësi akomodimi pa pagesë pranë konvikteve të shkollave të mesme, të cilët administrohen nga bashkitë që i kanë në juridiksion. Në shumë bashki, konviktet janë shumë afër vendodhjes së këtyre shkollave dhe kanë kapacitete të mjaftueshme për të mbuluar nevojat për akomodim të nxënësve.",
        "address": "Adresa: ",
        "shkoder": "Qarku i Shkodrës ",
        "shkoderL1": "Nxënësit e Shkollës industriale \"Arben Broci\", Shkollës  Teknike Pyjore \"Kolë Margjini\", Shkollës Teknologjike \"Hamdi Bushati\", akomodohen në konviktin e shkollës së mesme të muzikës \"Preng Jakova\". ",
        "shkoderL1Location": "Rruga \"Hotaj\", lagjia Rus, Shkodër.",
        "shkoderL2": "Shkolla profesionale \"Ndre Mjeda\" Bushat, ka konvikt të dedikuar për nxënësit e saj, i cili ndodhet shumë pranë shkollës. ",
        "shkoderL2Location": "Njësia administrative Bushat ",
        "lezhe": "Qarku i Lezhës",
        "lezheL1": "Nxënësit e shkollës profesionale \"Kolin Gjoka\", Lezhë akomodohen në konviktin e vetëm që ka kjo bashki, i cili ndodhet në oborrin e kësaj shkolle. Ky konvikt është rikonstruktuar në vitin 2022 dhe ka një  infrastrukturë bashkëkohore.",
        "lezheL1Location": "Rruga: L. Gurakuqi, përballë Xhamisë Lezhë.",
        "diber": "Qarku i Dibres",
        "diberL1": "Nxënësit e shkollës profesionale \"Nazmi Rushiti\", Dibër akomodohen në konviktin e në konviktin e vetëm që ka kjo bashki, i cili ndodhet në oborrin e kësaj shkolle. ",
        "diberL1Location": "Rruga “Tercilio Kardinale”, Sheshi Demokracia, Dibër.",
        "tirane": "Qarku i Tiranës",
        "tiraneL1": "Drejtoria e Konvikteve të Shkollave të Mesme Tiranë ofron mundësi akomodimi për nxënësit e shkollave profesionale në dy konvikte njëri për djemtë dhe tjetri për vajzat.  Nxënësit e shkollave  të mesme profesionale Hoteleri –Turizëm, \"Karl Gega\", Teknike  Elektrike \"Gjergji Canco\" dhe Teknike-Ekonomike, akomodon në këto konvikte. Konvikti për djemtë: Adresa e konviktit Hoteleri – Turizëm: Rruga e Kavajës pranë pallatit me shigjeta. Konvikti për vajzat: Adresa e konviktit të shkollës Teknike-Ekonomike: Rruga e Elbasanit.",
        "tiraneL2": "Nxënësit e Shkollës së Mesme Kombëtare të Agrobiznesit Golem \"Charles Telford Erickson\" akomodohen në konviktin e vetëm që ka kjo bashki, i cili ndodhet pranë kësaj shkolle.. Ky konvikt është rikonstruktuar në vitin 2022 dhe ka një  infrastrukturë bashkëkohore. ",
        "tiraneL2Location": "Golem, Kavajë",
        "durres": "Qarku i Durrësit",
        "durresL1": "Nxënësit e Shkollës së Mesme Profesionale \"Beqir Çela\",  akomodohen në konviktin e kësaj bashkie, i cili ndodhet pranë kësaj shkolle.",
        "durresL1Location": "Lagjja Nr.14.Rr.Bajram Tusha Shkozet.",
        "durresL2": "Nxënësit e Shkollës së Mesme Teknologjike \"Hysen Çela\", akomodohen tek konvikti shkollës së mesme të fizkulturës \"Benardina Qeraxhia\". ",
        "durresL2Location": "Rruga “Kristo Sotiri”, Durrës.",
        "elbasan": "Qarku i Elbasanit",
        "elbasanL1": "Nxënësit e Shkollës së Mesme Profesionale \"Ali Myftiu\" dhe Shkollës së Mesme Profesionale \"Sali Ceka\", akomodohen në konviktin e vetëm që ka kjo bashki. ",
        "elbasanL1Location": "Pranë Shkollës Pedagogjike Elbasan. ",
        "elbasanL2": "Nxënësit e Shkollës së Mesme Profesionale \"Mihal Shahini\" Cërrik, akomodohen në konviktin e vetëm që ka kjo bashki, i cili ndodhet pranë kësaj shkolle",
        "elbasanL2Location": "Rruga \"Gjimnazistët\", Cërrik.",
        "fier": "Qarku i Fierit",
        "fierL1": "Nxënësit e Shkollës \"Petro Sota\", dhe Shkollës së Mesme Bujqësore \"Rakip Kryeziu\"(Çlirim), akomodohen tek konvikti i shkollës profesionale bujqësore \"Rakip Kryeziu\". Ky konvikt është rikonstruktuar në vitin 2022. ",
        "fierL1Location": "Ferma Çlirim, Fier.",
        "fierL2": "Nxënësit e Shkollës Mekanike Bujqësore, Lushnjë akomodohen në konviktin e kësaj bashkie, i cili ndodhet në oborrin e kësaj shkolle ",
        "fierL2Location": "Lagjja “Skënder Libohova”, Lushnje.",
        "berat": "Qarku i Beratit",
        "beratP": "Nxënësit e shkollës së mesme profesionale \"Kristo Isak\" dhe të Shkollës \"Stiliano Bandilli\" akomodohen në konviktin e kësaj bashkie që ndodhet pranë shkollës Kristo Isak.",
        "beratPLocation": "Rruga “Dasaret”, Berat.",
        "korce": "Qarku i Korçës",
        "korceL1": "Nxënësit e shkollës së mesme Teknike - ekonomike \"Isuf Gjata\", shkollës së mesme të Ndërtimit, shkollës së mesme Teknike Profesionale \"Demir Progri\", shkollës së mesme Agrobiznesit  \"Irakli Terova\", akomodohen në konviktin e vetëm të kësaj bashkie,  cili ndodhet pranë shkollës së mesme Agrobiznesit  \"Irakli Terova\" ",
        "korceL1Location": "lagjja 17, Rruga “Ajet  Gjindoli”, Korçë.",
        "vlore": "Qarku i Vlorës",
        "vloreL1": "Nxënësit e Shkollës së Mesme Industriale \"Pavarësia\" dhe Shkollës së Mesme \"Tregtare\" akomodohen në konviktin e përbashkët, i cili ndodhet pranë Shkollës së Mesme Industriale \"Pavarësia\". ",
        "vloreL1Location": "Bulevardi Ismail Qemali”, në afërsi të Kalit të Trojës, Vlorë.",
        "vloreL2": "Nxënësit e Shkollës së Mesme Profesionale \"Antoni Athanas\", Sarandë akomodohen në konviktin e vetëm që ka kjo bashki, i cili ndodhet pranë kësaj shkolle ",
        "vloreL2Location": "Lagjja nr. 2, Rruga “Adem Sheme” Sarandë.",
        "gjirokaster": "Qarku i Gjirokastrës",
        "gjirokasterL1": "Nxënësit e Shkollës së Mesme Profesionale \"Thoma Papapano\", Gjirokastër akomodohen në konviktin e shkollës pedagogjike",
        "gjirokaterL1Location": "Lagjja 18 shtatori Gjirokastër.",
    },

//Page(transport)
"Transport": {
        "banner": "Transport",
        "intro": "RIMBURSIM I KOSTOS SË TRANSPORTIT",
        "desc": "Në zbatim të VKM-së nr.119, datë 01.03.2023 ‘Për përdorimin e fondeve publike për transportin e punonjësve arsimorë që punojnë dhe të nxënësve që mësojnë jashtë vendbanimit’ nxënësve që ndjekin arsimin profesional publik, që udhëtojnë nga vendbanimi i përhershëm në një vendbanim tjetër, mbi 5 km deri në 50 km larg ofruesit publik të arsimit profesional iu mbulohet kosto e transportit.",
    },

//Page(drejtime)
"Drejtime": {
        "banner": "Gjej drejtimin e studimit dhe degët",
        "introText": "Zgjidh drejtimin e studimit për të ndërtuar një karrierë të suksesshme të përshtatur plotësisht me kërkesat e tregut të punës.",
        "filters": "Filtra",
        "degreeCategory": "Drejtime",
        "profileCategory": "Profile",
        "schoolCategory": "Drejtimi ne Arsimin e Mesem profesional",
        "centerCategory": "Kurset e Formimit Profesional",
        "middleCategory": "Drejtimi pas i mesem",
        "universityCategory": "Drejtimi  ne Studimet Univesitare",
        "degree": "Drejtime",
        "center": "Kurse",
        "institution": "Drejtime dhe Kurse",
        "middle": "Drejtimi pas i mesem",
        "search": "Kërko Drejtime",
    },

//Page(career-advice)
"CareerAdvice": {
        "banner": "Këshilla karriere",
        "intro": "Këshilla karriere",
        "p1": "Mirë se vini në faqen tonë kushtuar shkollave të AFP-së! Ne e kuptojmë rëndësinë e aftësive praktike në arsimin profesional dhe jemi këtu për t'ju ofruar këshilla dhe ndihmëza praktike të vlefshme për rrugëtimin tuaj profesional.",
        "l1": "Eksploroni Rrugët e Karrierës: Merrni kohë për të hulumtuar dhe eksploruar rrugë të ndryshme karriere brenda fushës suaj të zgjedhur. Identifikoni industritë dhe rolet e punës që përputhen me interesat dhe aspiratat tuaja. Kjo do t'ju ndihmojë të merrni vendime të informuara dhe të vendosni synime të qarta për të ardhmen tuaj.",
        "l2": "Ndërtoni një rrjet profesional: Ndërtimi i një rrjeti të fortë profesional është thelbësor për zhvillimin e karrierës. Merrni pjesë në aktivitete të industrisë, lidhuni me profesionistë në fushën tuaj dhe bashkohuni me shoqatat përkatëse ose komunitetet në internet. Rrjetëzimi mund të hapë dyert për mundësi punësimi, mentorim dhe njohuri të vlefshme nga profesionistë me përvojë",
        "quote": "Në shkollën time ka shumë mundësi për të zhvilluar rrjetin tënd të kontakteve. Ndihma e parë mua më erdhi nga njësia e zhvillimit në shkollë, por me kalimin e kohës edhe mes nxënësve ka më shumë bashkëpunim për t’u njohur me mundësitë e ndryshme.",
        "afproText": "Një nga këto rrjete është",
        "afproLink": "AFPRO",
        "l3": "Kërkoni përvojë në industri: Përvoja praktike vlerësohet shumë nga punëdhënësit. Kërkoni mundësi për të fituar përvojë përmes praktikave ose punës me kohë të pjesshme në fushën tuaj. Këto përvoja jo vetëm që do të përmirësojnë CV-në tuaj, por gjithashtu do të ofrojnë njohuri të vlefshme në industri dhe do t'ju ndihmojnë të zhvilloni aftësitë tuaja teknike.",
        "l4": "Përmirësoni vazhdimisht aftësitë tuaja: Qëndroni të përditësuar me tendencat më të fundit të industrisë dhe përparimet teknologjike. Kërkoni mundësi për të përmirësuar dhe zgjeruar njohuritë tuaja përmes seminareve, certifikimeve ose kurseve në internet.Përshtatshmëria ndaj teknologjive dhe praktikave të reja do t'ju bëjë më të kërkuar nga punëdhënësit.",
        "l5": "Kërkoni mentorim dhe udhëzim: Të kesh një mentor mund të ofrojë udhëzime dhe mbështetje të vlefshme gjatë gjithë rrugëtimit të karrierës. Lidhuni me profesionistë në fushën tuaj, të cilët mund të ofrojnë këshilla, të ndajnë përvojat e tyre dhe t'ju ndihmojnë të lundroni në sfidat. Njohuritë e tyre mund të jenë të dobishme në marrjen e vendimeve të informuara për karrierën.",
        "l6": "Zhvillimi i aftësive të buta: Krahas aftësive teknike, punëdhënësit vlerësojnë edhe aftësitë e buta si komunikimi, puna në grup dhe zgjidhja e problemeve. Përmirësoni këto aftësi përmes projekteve në grup, marrjes së roleve drejtuese dhe pjesëmarrjes në aktiviteteve jashtëshkollore. ",
        "l7": "Qëndroni të informuar për kërkesat e industrisë: Mbani veten të përditësuar mbi tendencat e industrisë, kërkesat e tregut të punës dhe mundësitë në zhvillim. Ndiqni lajmet e industrisë, merrni pjesë në konferenca dhe angazhohuni në aktivitete të zhvillimit profesional. Këto njohuri do t'ju ndihmojnë të merrni vendime strategjike për karrierën.",
        "p2": "Mbani mend, koha juaj në shkollën AFP është një hap i rëndësishëm drejt një karriere të suksesshme. Duke ndjekur këto ndihmëza praktike dhe këshilla për karrierën, do të jeni më të pajisur për të bërë zgjedhje të informuara, për të fituar aftësi përkatëse dhe për të lulëzuar në udhëtimin tuaj profesional.",
    },

//Page(degreeDetail)
"DegreeDetail": {
   "list": "Lista e institucioneve",
    "apply": "Nëse dëshiron të aplikosh për këtë drejtim kliko",
    "link": " këtu",
    "mapLocation": "Vendndodhja",
    "popupLink": "Hap në Google Maps",
},

//Page(community)
"Community": {
  "intro": "Raste Suksesi",
},

"Courses":{
  "nr_rendor": "Numri Rendor",
  "curricula_id": "Kurrikula",
  "center_id": "Qëndër",
  "course_id": "Kursi",
  "total_hours": "Orët në total",
  "weekly_periodicity": "Frekuenca javore",
  "session_time": "Koha e seancës",
  "duration": "Kohëzgjatja",
  "course_start_date": "Data e fillimit",
  "course_end_date": "Data e mbarimit",
  "fee": "Tarifa",
  "description": "Përshkrimi",
}
}
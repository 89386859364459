import React from 'react'
import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import withLanguage from "../HOC/withLanguage";
import {AccordionBody, AccordionContainer, AccordionTitle} from "../Components/Shared/Layout/InstitutionAccordion";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {degreeFilterChange} from "../redux/reducers/degreeFiltersSlice";
import {schoolFilterChange} from "../redux/reducers/schoolFiltersSlice";


const HowToApply = ({language}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return (
        <>
            <Banner img='/howToApplyImages/banner.jpg' title={language.mainTitle}/>
            <IntroBlock>
                {language.intro}
            </IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <AccordionContainer panel="panel1">
                    <AccordionTitle title="Shkolla Profesionale" />
                    <AccordionBody>
                        <p className="text-lg py-2 font-bold">{language.shkollaTitle}</p>
                        <p className="text-lg py-2">
                            {language.shkollaP1}
                            <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=10058" target="_blank" className="text-blue-600 font-bold">
                                {language.register}{" "}
                            </a>
                            {language.shkollaP2}
                        </p>

                        {/*<p className="text-lg py-2">{language.shkollaP3}</p>*/}
                        {/*<p className="text-lg py-2">{language.shkollaP4}</p>*/}
                        {/*<p className="text-lg py-2">{language.shkollaP5}</p>*/}


                        {/*<p className="text-lg py-2 font-bold">{language.univP2}</p>*/}
                        {/*<ul className="list-disc pl-8 text-lg">*/}
                        {/*    <li>{language.univL1}</li>*/}
                        {/*    <li>{language.univL2}</li>*/}
                        {/*    <li>{language.univL3}</li>*/}
                        {/*    <li>{language.univL4}</li>*/}
                        {/*</ul>*/}
                        {/*<p className="text-lg py-2 ">*/}
                        {/*    {language.univP3}{" "}*/}
                        {/*    <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=10058" target="_blank" className="text-blue-600 font-bold">*/}
                        {/*        {language.register}{" "}*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                        {/*<p className="text-lg py-2 font-bold">{language.univP4}</p>*/}
                        {/*<p className="text-lg py-2">{language.univP5}</p>*/}
                        {/*<p className="text-lg py-2 font-bold">{language.univP6}</p>*/}
                        {/*<p className="text-lg py-2">{language.univP7}</p>*/}

                        <p className="text-lg py-2 font-bold">{language.univP8}</p>
                        <a href={`mailto:${language.univP9}`} className="text-blue-600 font-bold">{language.univP9}</a>
                        <p className="text-lg pt-2 font-bold">{language.univP10}</p>
                        <p className="text-lg py-2">{language.univP11}
                            <Link to="/shkollat" className="text-blue-600 font-bold">{language.univP12}</Link>
                            {language.and}
                            <Link to="/drejtime" className="text-blue-600 font-bold">{language.univP13}</Link>
                        </p>

                    </AccordionBody>
                </AccordionContainer>

                <AccordionContainer panel="panel2">
                    <AccordionTitle title="Qendra Formimi" />
                    <AccordionBody>
                        <p className="text-lg py-2 font-bold">{language.centerTitle}</p>
                        <p className="text-lg py-2">{language.centerP1}</p>
                        <p className="text-lg py-2">{language.centerP2}
                            <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=12550" target="_blank" className="text-blue-600">{language.linkP2}</a>
                        </p>
                        <p className="text-lg py-2">{language.centerP3}
                            <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=12550" target="_blank" className="text-blue-600">{language.linkP3}</a>
                        </p>
                        {/*<p className="text-lg py-2 font-bold">{language.centerP4}</p>*/}
                        {/*<p className="text-lg py-2">{language.centerP5}</p>*/}
                        {/*<p className="text-lg py-2 font-bold">{language.centerP6}</p>*/}
                        {/*<p className="text-lg py-2">{language.centerP7}</p>*/}
                        <p className="text-lg py-2 font-bold">{language.centerP8}</p>
                        <a href={`mailto:${language.centerP9}`} className="text-blue-600 font-bold">{language.centerP9}</a>
                        <p className="text-lg pt-2 font-bold">{language.univP10}</p>
                        <p className="text-lg py-2">{language.centerP10}{" "}
                            <Button onClick={()=>{
                                dispatch(schoolFilterChange("qendra"))
                                navigate("/shkollat")
                            }} sx={{ color: 'text-blue-600', background: 'transparent', textTransform:'capitalize', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                                "&:hover": {background: 'transparent', boxShadow: 'none'},}}> {language.centerP11}</Button>
                            {language.and}
                            <Button onClick={()=>{
                                dispatch(degreeFilterChange("kurs"))
                                navigate("/drejtime")
                            }} sx={{ color: 'text-blue-600', background: 'transparent', textTransform:'capitalize', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                                "&:hover": {background: 'transparent', boxShadow: 'none'},}}> {language.centerP12}</Button>
                            {language.centerP13}
                        </p>
                    </AccordionBody>
                </AccordionContainer>
            </div>

        </>
    )
}


export default withLanguage(HowToApply, "siTeAplikosh");
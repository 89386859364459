import { MapContainer, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import L from "leaflet";
import customIcon from '../../school.png';


const center = [41.327953, 19.819025];

//Custom Marker Icon
const iconMarker = new L.Icon({
    iconUrl: customIcon,
    iconSize: new L.Point(20, 25),
});

export { iconMarker };

export default function MapWrapper({ children }) {
    return (
        <MapContainer center={center} zoom={8} scrollWheelZoom={true} className="h-full w-full">
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>; contributors'
                url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {children}
        </MapContainer>
    )
}

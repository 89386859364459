import React, {useEffect, useState} from 'react'
import Banner from "../Components/Shared/Layout/Banner";
import withLanguage from "../HOC/withLanguage";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {DormitoryAccordion, Description} from "../Components/Shared/Layout/DormitoryAccordion";

const Dormitory = ({language}) => {

    return (
        <>
            <Banner img="/dormitory/banner.jpg" title={language.banner}/>

            <IntroBlock>{language.intro}</IntroBlock>
            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <p className="text-lg py-2">{language.p}</p>

                {/*Shkoder*/}
                <DormitoryAccordion county={language.shkoder}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.shkoderL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.shkoderL1Location}
                            </li>
                            <li className="text-lg py-2">
                                {language.shkoderL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.shkoderL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Lezhe*/}
                <DormitoryAccordion county={language.lezhe}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.lezheL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.lezheL1Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Diber*/}
                <DormitoryAccordion county={language.diber}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.diberL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.diberL1Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Tirane*/}
                <DormitoryAccordion county={language.tirane}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.tiraneL1}
                            </li>
                            <li className="text-lg py-2">
                                {language.tiraneL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.tiraneL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Durres*/}
                <DormitoryAccordion county={language.durres}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.durresL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.durresL1Location}
                            </li>
                            <li className="text-lg py-2">
                                {language.durresL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.durresL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Elbasan*/}
                <DormitoryAccordion county={language.elbasan}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.elbasanL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.elbasanL1Location}
                            </li>
                            <li className="text-lg py-2">
                                {language.elbasanL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.elbasanL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Fier*/}
                <DormitoryAccordion county={language.fier}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.fierL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.fierL1Location}
                            </li>
                            <li className="text-lg py-2">
                                {language.fierL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.fierL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Berat*/}
                <DormitoryAccordion county={language.berat}>
                    <Description>
                        <p className="text-lg py-2">{language.beratP}</p>
                        <p className="text-lg py-2"><strong className="font-bold">{language.address}</strong>{language.beratPLocation}</p>
                    </Description>
                </DormitoryAccordion>

                {/*Korce*/}
                <DormitoryAccordion county={language.korce}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.korceL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.korceL1Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Vlore*/}
                <DormitoryAccordion county={language.vlore}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.vloreL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.vloreL1Location}
                            </li>
                            <li className="text-lg py-2">
                                {language.vloreL2}<br />
                                <strong className="font-bold">{language.address}</strong>{language.vloreL2Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>

                {/*Gjirokaster*/}
                <DormitoryAccordion county={language.gjirokaster}>
                    <Description>
                        <ul className="list-disc pl-4">
                            <li className="text-lg py-2">
                                {language.gjirokasterL1}<br />
                                <strong className="font-bold">{language.address}</strong>{language.gjirokaterL1Location}
                            </li>
                        </ul>
                    </Description>
                </DormitoryAccordion>
            </div>
        </>
    )
}

export default withLanguage(Dormitory, 'Dormitory')
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

//Sentry
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://81c3261ca3867848c8c16f14cd247b09@o1290671.ingest.sentry.io/4505640327118848",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", "https://aftesi.al/"],
        }),
        new Sentry.Replay(),
    ],
// Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);


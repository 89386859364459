import Image from "./Image";
import Box from "@mui/material/Box";
import SearchInput from "../../NavBar/NavBarComponents/SearchInput";
import Skeleton from "@mui/material/Skeleton";
import InfoBalls from "../../HomePage/InfoBalls";

function Banner({ img, title, input = false, alt, block, containerClass = "bg-gray-500", infoBallsConfig = [] }) {
    return (
        <Box className={`${containerClass} flex flex-col items-center relative h-[80vh]`}>
            {/* Background image container */}
            <Box className="absolute top-0 left-0 w-full h-full z-0">
                <Image src={img} className="h-full w-full object-cover opacity-70" alt={alt} />
            </Box>
            {/* Content overlaying the background image */}
            <Box className="z-10 text-center flex flex-col justify-center h-full w-[90%] md:w-[70%] px-4">
                <h1 className={`${input ? "md:text-[#C82121] text-black max-md:text-[44px] text-7xl text-center" : "text-center text-7xl text-white"} font-bold`} >
                    {title || <Skeleton animation="wave" />}
                </h1>
                {block &&
                    <p className={`${input ? "text-[#C82121] text-6xl" : "text-white"} font-bold`}>{block}</p>
                }
                {input && <SearchInput showFilters={false} />}
            </Box>
            {/* InfoBalls container with explicit background color */}
            {input &&
                <div style={{ backgroundColor: "white", zIndex:2 }} className="flex gap-4 justify-around w-full pt-2 px-4 md:px-20 lg:px-52">
                    {infoBallsConfig.map(infoItem => (
                        <InfoBalls {...infoItem} />
                    ))}
                </div>
            }
        </Box>
    );
}

export default Banner;

import React, {useEffect, useState} from 'react'
import Banner from "../Components/Shared/Layout/Banner";
import withLanguage from "../HOC/withLanguage";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import Axios from "axios";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";

const CampusesAndSocialLife = ({language})=>{

    const generalActivities = [
        {id: 1, name: language.generalActivityL1},
        {id: 2, name: language.generalActivityL2},
        {id: 3, name: language.generalActivityL3},
        {id: 4, name: language.generalActivityL4},
        {id: 5, name: language.generalActivityL5},
        {id: 6, name: language.generalActivityL6},
        {id: 7, name: language.generalActivityL7},
        {id: 8, name: language.generalActivityL8},
        {id: 9, name: language.generalActivityL19},
        {id: 10, name: language.generalActivityL10},
        {id: 11, name: language.generalActivityL11},
        {id: 12, name: language.generalActivityL12},
        {id: 13, name: language.generalActivityL13},
        {id: 14, name: language.generalActivityL14},
        {id: 15, name: language.generalActivityL15},
        {id: 16, name: language.generalActivityL16},
        {id: 17, name: language.generalActivityL17},
        {id: 18, name: language.generalActivityL18},
        {id: 19, name: language.generalActivityL19},
        {id: 20, name: language.generalActivityL20},
        {id: 21, name: language.generalActivityL21},
        {id: 22, name: language.generalActivityL22},
        {id: 23, name: language.generalActivityL23},
        {id: 24, name: language.generalActivityL24},
        {id: 25, name: language.generalActivityL25},
        {id: 26, name: language.generalActivityL26},
        {id: 27, name: language.generalActivityL27},
        {id: 28, name: language.generalActivityL28},
        {id: 29, name: language.generalActivityL29},
        {id: 30, name: language.generalActivityL30},
        {id: 31, name: language.generalActivityL31},
    ]

    return(
        <>
            <Banner img="/CampusesAndSocialLife/banner.jpg" title={language.banner}/>
            <IntroBlock>{language.banner}</IntroBlock>

            <LayoutContent className="pb-6">
                <LayoutLeft className="col-span-1">
                    <p className="text-2xl py-3 text-justify font-bold">{language.generalActivity}</p>
                    <ol className="list-decimal pl-4">
                        {generalActivities.map((acitivity) => {
                            return (
                                <li key={acitivity.id} className="py-2 text-lg text-justify">{acitivity.name}</li>

                            )
                        })}
                    </ol>
                    <p className="text-lg pt-5 text-justify">{language.p2}</p>
                </LayoutLeft>
                <LayoutRight>
                    <div className="flex items-center h-full md:justify-start justify-center">
                        <div className="max-w-[400px]">
                            <img src='/CampusesAndSocialLife/girls_image.jpg' alt="image-element" className="min-h-[400px] w-full object-cover pb-6"/>
                            <h3 className="text-lg font-bold">{language.quote}</h3>
                        </div>
                    </div>
                </LayoutRight>
            </LayoutContent>


            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                {/*Activities*/}
                <p className="text-2xl py-3 text-justify font-bold">{language.activity}</p>
                <ol className="list-decimal pl-4 pb-6">
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.activityL1}</p>
                        {language.activityL1Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.activityL2}</p>
                        {language.activityL2Desc}
                    </li>
                </ol>

                {/*Competition*/}
                <p className="text-2xl py-3 text-justify font-bold">{language.competition}</p>
                <ol className="list-decimal pl-4 pb-6">
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.competitionL1}</p>
                        {language.competitionL1Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.competitionL2}</p>
                        {language.competitionL2Desc}
                        <ul className="list-disc pl-8">
                            <li>{language.competitionL2DescL1}</li>
                            <li>{language.competitionL2DescL2}</li>
                            <li>{language.competitionL2DescL3}</li>
                        </ul>
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.competitionL3}</p>
                        {language.competitionL3Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.competitionL4}</p>
                        {language.competitionL4Desc}
                    </li>
                </ol>

                {/*Expo*/}
                <p className="text-2xl py-3 text-justify font-bold">{language.expo}</p>
                <ol className="list-decimal pl-4">
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.expoL1}</p>
                        {language.expoL1Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.expoL2}</p>
                        {language.expoL2Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.expoL3}</p>
                        {language.expoL3Desc}
                    </li>
                    <li className="py-2 text-lg text-justify">
                        <p className="font-bold">{language.expoL4}</p>
                        {language.expoL4Desc}
                    </li>
                </ol>
            </div>
        </>
    )
}

export default withLanguage(CampusesAndSocialLife, 'CampusesAndSocialLife')
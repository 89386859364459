import { createSlice } from "@reduxjs/toolkit";


export const degreeFiltersSlice = createSlice({
    name: 'degreeFilters',
    initialState:{
        currentFilter: "drejtim",
    },
    reducers: {
        degreeFilterChange: (state, action) => {
            state.currentFilter = action.payload
        },
    }
})

export const { degreeFilterChange } = degreeFiltersSlice.actions;
export default degreeFiltersSlice.reducer;
import React from 'react'

const InfoBalls = ({name, src, hideMobile = false, path = "/"})=>{
    return(
        <a key={name} style={{zIndex: 1}} className={`flex flex-col text-center ${hideMobile ? "max-md:hidden" : "block"}`} href={path}>
            <img className="w-[180px] md:w-[220px] h-[140px]" src={src} />
            <p className="text-lg md:text-2xl pt-1">{name}</p>
        </a>
    )
}
export default InfoBalls
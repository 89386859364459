import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import withLanguage from "../HOC/withLanguage";
import React from "react";
import YoutubeEmbed from "../Components/Shared/Layout/YoutubeEmbed";

const AftesiNdryshe = ({language}) => {

    return (
        <>
            <Banner img='/AftesiNdryshe/banner.jpg' title={language.banner}/>

            <IntroBlock>{language.introText}</IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem] max-w-full md:max-w-[40%]">
                <YoutubeEmbed className="pb-5 h-300" embedId="pM0tgn84Gg8" height={400}/>
            </div>

        </>
    )
}

export default withLanguage(AftesiNdryshe, "SpecialAbilities")
export default function YoutubeEmbed({ embedId, className, height }) {

    return (
        <div className={className}>
            <iframe
                width="100%"
                height={height}
                src={`https://www.youtube.com/embed/${embedId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    )

}
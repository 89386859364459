import React, { useEffect } from 'react'
import {useSelector} from "react-redux";
import withLanguage from "../../../HOC/withLanguage";
import MenuItem from "@mui/material/MenuItem";
import Axios from "axios";
import FilterSelect from "./FilterSelect";

function SearchFilters({ language }) {


    const [municipalityData, setMunicipalityData] = React.useState([])
    const institutionType = useSelector(state => state.searchFilters.institutionType);
    const municipality = useSelector(state => state.searchFilters.municipality);
    const ownership = useSelector(state => state.searchFilters.ownership);

    useEffect(() => {
        Axios.get(`/api/data/municipality`)
            .then((res) => {
                setMunicipalityData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },[])

    return (
        <div className="grid grid-cols-3 gap-3 pt-4 md:mr-[221px]">

            {/*Institution Type*/}
            <FilterSelect
                title={language.institutionType}
                value={institutionType}
                type="institutionType"
            >
                <MenuItem value="shkolle_profesionale">{language.typeSchool}</MenuItem>
                <MenuItem value="qender_formimi">{language.typeCenter}</MenuItem>
            </FilterSelect>

            {/*Municipality*/}
            <FilterSelect
                title={language.municipality}
                value={municipality}
                type="municipality"
            >
                {
                    Array.isArray(municipalityData) && !!municipalityData.length &&
                    municipalityData.map((city) => {
                        return <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                    })

                }
            </FilterSelect>

            {/*Ownership*/}
            <FilterSelect
                title={language.ownership}
                value={ownership}
                type="ownership"
            >
                <MenuItem value="publik">{language.public}</MenuItem>
                <MenuItem value="privat">{language.private}</MenuItem>
            </FilterSelect>
        </div>
    )
}

export default withLanguage(SearchFilters, 'SearchFilters')

import TablePagination from "@mui/material/TablePagination";
import React from "react";


export default function Pagination({ count, page, action, pageSize }) {

    return(
        <>
            {
                !isNaN(count) && count > 0 &&
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={action}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={pageSize}
                    sx={{
                        display: 'flex',
                        'p, button': {fontWeight: '700'},
                        '.MuiTablePagination-selectLabel': {display: 'none'},
                        '.MuiInputBase-root': {display: 'none'},
                        '.MuiToolbar-root': {display: 'flex', flexDirection: 'row-reverse', padding: '0'},
                        '.MuiToolbar-root .MuiTablePagination-actions': {ml: '0'},
                        '.MuiTablePagination-actions button ': {paddingLeft: '0'}
                    }}
                />
            }
        </>

    )
}
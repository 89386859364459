import withLanguage from "../../../HOC/withLanguage";
import {FormControl, InputLabel} from "@mui/material";
import Select from "@mui/material/Select";
import React from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import {useDispatch} from "react-redux";
import {updateSearchFilters} from "../../../redux/reducers/searchFiltersSlice";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Inside your component

function FilterSelect ({ language, title, value, type, children }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Assuming 'sm' breakpoint for mobile
    const dispatch = useDispatch();

    const handleClear = () => {
        dispatch(updateSearchFilters({
            property: `${type}`,
            value: ""
        }))
    }

    return (
            <FormControl fullWidth
                 sx={{
                     position: 'relative',
                     "fieldset.Mui-focused": {
                         borderColor: "#C82121"
                     },
                     "legend": {
                         color: "#C82121"
                     },
                     ".MuiSelect-select.MuiInputBase-input": {
                         paddingLeft: "22px"
                     }
                 }}
                         style={{borderRadius:"50px"}}
            >
                <InputLabel
                    sx={{
                        left: isMobile ? "0px": "10px",
                        fontSize: "20px",
                        color: "#ABABAB",
                        "&.Mui-focused, &.MuiFormLabel-filled": {
                            color: "#ABABAB"
                        },
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    {title}
                </InputLabel>

                {
                    value &&
                    <ReplayIcon className="absolute z-10 top-[12px] ml-1 text-[#C82121] cursor-pointer" sx={{ fontSize: "1rem" }} onClick={handleClear} />
                }

                <Select
                    id="institution-type"
                    value={value}
                    label="Tipi i Institucionit"
                    className="bg-white"
                    style={{borderRadius:isMobile ? "15px" : "50px"}}
                    sx={{
                        ".MuiSelect-select": {
                            paddingY: "20px"
                        },
                        "svg": {
                            fill: "#C82121"
                        }
                    }}
                    onChange={(event) => {
                        dispatch(updateSearchFilters({
                            property: `${type}`,
                            value: event.target.value
                        }))
                    }}
                >
                    {children}
                </Select>
            </FormControl>
    )
}

export default withLanguage(FilterSelect, 'SearchFilters')
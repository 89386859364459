import React, {useState, useEffect} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import SchoolItem from "../Components/Shared/Layout/SchoolItem";
import Banner from "../Components/Shared/Layout/Banner";
import TablePagination from "@mui/material/TablePagination";
import MapWrapper, {iconMarker} from "../Components/Maps/Map";
import { Marker, Popup } from "react-leaflet";
import withLanguage from "../HOC/withLanguage";
import Axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import {useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "../Components/Shared/Layout/Pagination";

let pageSize = 14;
function Shkollat({ onClick=()=>{}, language,}) {
    const openInGoogleMaps = (lat, lng) => {
        const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        window.open(url, "_blank");
    };
    //Map Data
    const [mapData, setMapData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [listData, setListData] = useState({});
    const [category, setCategory] = useState("shkolla");
    const schoolFilter = useSelector(state=> state.schoolFilters.currentFilter)
    const [inputValue,setInputValue] = React.useState("");
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // On radio filter change
    const handleRadioChange= (event)=>{
        if(event.target.value === 'shkollat'){
            fetchSchools();
            getSchoolMap();
            setCategory("shkolla")
            setInputValue("");
        }else if(event.target.value === 'qendra'){
            fetchCenters();
            getCentersMap();
            setCategory("qendra")
            setInputValue("");
        }
        setPage(0)
    }

    useEffect(()=>{
        setCategory(schoolFilter)
    },[schoolFilter])
    //Get School Map
    const getSchoolMap = () => {
        setIsLoading(true);
        Axios.get("/api/data/institution/shkolle_profesionale")
            .then ((res)=>{
                setMapData(res.data.records)
                setIsLoading(false)
            })
            .catch(() => {
            setIsLoading(true)
        })
    }

    //Get Formation Centers Map
    const getCentersMap = () => {
        setIsLoading(true);
        Axios.get("/api/data/institution/qender_formimi")
            .then ((res)=>{
                setMapData(res.data.records)
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(true)
            })
    }

    //Get Schools List
    const fetchSchools = () => {
        setIsLoading(true);
        Axios.get(` /api/data/institution/shkolle_profesionale/${page+1}/${pageSize}`).then(response => {
            if (!response.data.error) {
                setListData(response.data)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(true)
        })
    }

    //Get Formation Centers List
    const fetchCenters = () => {
        setIsLoading(true);
        Axios.get(` /api/data/institution/qender_formimi/${page+1}/${pageSize}`).then(response => {
            if (!response.data.error) {
                setListData(response.data)
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(true)
        })
    }

    //Get Institution Search Data
    const getSearchData = () => {
        Axios.get(`/api/search_institution/${inputValue}/${page+1}/${pageSize}`)
            .then((res) => {
                setListData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setCategory('');
    }

    const submitHandler = (e)=>{
        e.preventDefault();
        if (inputValue !== "") {
            getSearchData();
        } else {
            setCategory('shkolla');
            fetchSchools();
        }
    }

    useEffect(()=>{
        if(category==="shkolla"){
            fetchSchools();
            getSchoolMap();
        }else if(category === "qendra"){
            fetchCenters();
            getCentersMap();
        }
    },[page,category])

    return (
        <>
            <Banner img='/findInstitution_banner.jpg' title={language.banner}/>

            <IntroBlock>{language.introText}</IntroBlock>

            {/*Search*/}
            <Paper
                component="form"
                className={`flex items-center w-[90%] md:w-[40%] m-auto py-[2px] px-[4px] mt-10`}
                onClick={onClick}
                onSubmit={submitHandler}

            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={language.search}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={submitHandler}>
                    <SearchIcon sx={{ color: '#DB3222'}}/>
                </IconButton>
            </Paper>

            <LayoutContent>
                <LayoutLeft>
                    <h3 className="text-xl pt-5 text-[#DB3222] font-bold">{language.filters}</h3>

                    {/*Filters*/}
                    <FormGroup>
                        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="shkollat" onChange={handleRadioChange}
                                    name="radio-buttons-group" className="flex pb-5" sx={{flexDirection: 'row'}}>
                            <FormControlLabel
                                value="shkollat"
                                control={<Radio sx={{color: '#DB3222', '&.Mui-checked': {color: '#DB3222'}}}/>}
                                label={language.school}
                                className="text-[#DB3222]"
                                checked={category === "shkolla"}
                            />
                            <FormControlLabel
                                value="qendra"
                                control={<Radio sx={{color: '#DB3222', '&.Mui-checked': {color: '#DB3222'}}}/>}
                                label={language.center}
                                className="text-[#DB3222]"
                                checked={category === "qendra"}
                            />
                        </RadioGroup>
                    </FormGroup>

                    {/*Map*/}
                    <div className="md:h-[800px] h-[500px] w-full border-2 border-[#db3222]">
                        <MapWrapper>
                            {mapData && mapData.map((item) =>  (
                                    <Marker position={{ lat: item.latitude, lng: item.longitude }} key={item.id} icon={iconMarker}>
                                        <Popup>
                                            <div className='max-w-[200px]'>
                                                <span className="font-bold">{item.name} ({item?.municipality_id?.name})</span>
                                                <button className="text-[#DB3222] pt-1 flex mx-auto" onClick={() => openInGoogleMaps(item.latitude, item.longitude)}>
                                                    {language.popupLink}
                                                </button>
                                            </div>
                                        </Popup>
                                    </Marker>
                                )
                            )}
                        </MapWrapper>
                    </div>

                </LayoutLeft>

                <LayoutRight>
                    <h3 className="text-lg py-5 text-[#DB3222] font-bold">{listData.amount} {category==="shkolla" ? language.schoolCategory : category==="qendra" ? language.center : language.institution } </h3>
                    <div className="school-list">
                        {!isLoading ? listData?.records?.map((item) => {
                            return (
                                <SchoolItem key={item.id} title={item.name} qyteti={item?.municipality_id?.name} id={item.id} category={category}/>
                            )
                        })
                            :
                            <>
                                <Skeleton variant="rectangular" className="w-full mb-3" height={50}/>
                                <Skeleton variant="rectangular" className="w-full mb-3" height={50}/>
                                <Skeleton variant="rectangular" className="w-full " height={50}/>
                            </>
                        }
                    </div>

                    <Pagination count={listData?.amount} pageSize={pageSize} page={page} action={handleChangePage} />
                </LayoutRight>
            </LayoutContent>
        </>
    )
}

export default withLanguage(Shkollat, "Shkollat")
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchLanguage } from "../../redux/reducers/LanguageSlice";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const AvailableLanguages = [
    {
        alt: "al_flag",
        code: "albanian",
        name: "Shqip",
        logo: "/languageSelector/alb_flag.png"
    },
    {
        alt: "en_flag",
        code: "english",
        name: "English",
        logo: "/languageSelector/eng_flag.png"
    }
]
function LanguageSwitcher({ className }) {
    const language = useSelector((state) => state.language.currentLanguage);
    const dispatch = useDispatch();

    const handleLanguageChange = (e) => {
        dispatch(switchLanguage({ language: e.target.value }));
    };

    return (
        <div onChange={handleLanguageChange} className={className}>

            <Select
                value={language}
                onChange={handleLanguageChange}
                style={{zIndex: 1300}}
                variant="standard"
                disableUnderline={true}
                inputProps={{ 'aria-label': 'Select language' }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                {
                    AvailableLanguages?.map(language => (
                        <MenuItem key={'generated_language_' + language.code} value={language.code}>
                            <img src={language.logo} alt={language.alt} height={20} width={30}/>
                        </MenuItem>
                    ))
                }
            </Select>
        </div>
    );
}

export default LanguageSwitcher;
import React, {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

const NavbarMenuCollapse = ({children, title, panel, expanded, onChange}) => {
    return (
        <>
            <Accordion expanded={expanded === panel} onChange={onChange(panel)} style={{boxShadow: "none", paddingY:0, marginY:"10px", border: "none"}}>
                <AccordionSummary style={{border: "none"}}>
                    <div className="font-bold uppercase text-2xl md:text-3xl text-center w-full text-[#DB3222]">
                        {title}
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="flex flex-col justify-center">
                        {children}
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
export default NavbarMenuCollapse
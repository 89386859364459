import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "../reducers/LanguageSlice";
import navReducer from '../reducers/navSlice'
import schoolFiltersReducer from '../reducers/schoolFiltersSlice'
import degreeFiltersReducer from "../reducers/degreeFiltersSlice";
import searchFiltersReducer from "../reducers/searchFiltersSlice";
export const store = configureStore({
    reducer: {
        language: languageReducer,
        nav:navReducer,
        schoolFilters:schoolFiltersReducer,
        degreeFilters:degreeFiltersReducer,
        searchFilters:searchFiltersReducer,
    }
});
import React from 'react';
import {Box, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import {LayoutContent,LayoutLeft,LayoutRight} from "../Shared/Layout/LayoutContent";
import withLanguage from "../../HOC/withLanguage";
function Footer({language}){
    const menuItems = [
        { text: language.statistics, href: '/' },
        { text: language.aboutUs, href: '/arsimi-i-mesem' },
        { text: language.privacy, href: '/' },
        { text: language.terms, href: '/' },
        { text: language.info, href: '/' },
        { text: language.contact,href: '/' },
    ];
    return(
        <div className="bg-[#201d1d] flex flex-col w-full h-full">
            <div className="m-5 xl:mx-[20rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem] mb-0 xl:mb-0 lg:mb-0 md:mb-0 py-3.5 font-bold flex flex-col justify-between md:grid-cols-4 gap-3">
                <div className="flex max-sm:flex-col max-sm:justify-center items-center max-sm:gap-4 sm:justify-between">
                    <div className="flex max-sm:w-full items-center max-sm:justify-between">
                        <img src="/Footer/akpaLogoo.svg" className='cursor-pointer max-w-[120px] max-h-[60px]' alt='S4J-icon' />
                        <img src="/Footer/ministriaIcon.svg" className='cursor-pointer max-w-[120px] max-h-[120px] ml-6' alt='S4J-icon' />
                    </div>
                    <div className="flex max-sm:w-full max-sm:justify-between">
                        <img src="/Footer/googlePlay.svg" className='cursor-pointer max-w-[120px] max-h-[120px] mr-6' alt='S4J-icon' />
                        <img src="/Footer/appStore.svg" className='cursor-pointer max-w-[120px] max-h-[120px]' alt='S4J-icon' />
                    </div>
                </div>

                <Divider color="white" />
                <ul className="flex max-md:flex-col justify-between items-center text-white flex-wrap">
                    {menuItems.map((item, index) => (
                        <li key={index}>
                            <a href={item.href}>{item.text}</a>
                        </li>
                    ))}
                </ul>
                <Divider color="white" />
                <p className="text-center text-white">Copyright © 2024 - Agjencia Kombëtare e Punësimit dhe Aftësive. </p>
                <p className="text-center text-white">{language.copyright}</p>
            </div>
        </div>
    )
}
export default withLanguage(Footer, "Footer")
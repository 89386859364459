import React from 'react'
import Image from "../Shared/Layout/Image";
const ImageText = ({title,description,src, alt="image", format, className, href, imageStyle})=>{
    return (
        <a href={href} className={`${format === "1" ? "flex flex-col max-md:items-center bg-white" : format === "3"? "grid md:grid-cols-2 md:gap-4 bg-[#DB3222]": format === "4" ? "flex flex-col bg-white" : "grid md:grid-cols-2 md:gap-4 bg-white"} justify-between w-full mt-3 cursor-pointer ${className}`}>
            <img src={src} alt={alt} className={`bg-center bg-cover object-cover ${format === "1" ? "w-40 h-40 p-4" : format === "3" ? "order-last w-72 justify-self-center md:self-center p-4" : format ==="4" ? "w-full h-96" : "w-full max-md:h-full md:min-h-[400px] max-md:hidden p-4" } `}/>
            <div className={`flex flex-col p-4 col-span-1`}>
                <h2 className={` ${format === "4" ? "md:text-4xl text-2xl" : "md:text-5xl text-3xl"} ${format === "3" ? "text-white " : "text-[#C82121]"} font-bold mb-4`}>{title}</h2>
                <div className={`${format === "1" ? "" : "grid grid-cols-8 md:flex"} `}>
                    <p className="md:text-xl text-lg col-span-5">{description}</p>
                    {format === "2" &&
                        <Image
                            src={src}
                            alt={alt}
                            className={`object-cover h-full col-span-3 md:hidden pl-1 md:p-4`}
                            style={imageStyle}
                        />
                    }
                </div>
            </div>
        </a>
    );
}
export default ImageText
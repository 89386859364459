import { createSlice } from "@reduxjs/toolkit";


export const searchFiltersSlice = createSlice({
    name: 'searchFilters',
    initialState:{
        institutionType: "",
        municipality: "",
        ownership: ""
    },

    reducers: {
        updateSearchFilters: (state, action) => {
            const { property, value } = action.payload;
            switch (property) {
                case "institutionType":
                    state.institutionType = value;
                    break;
                case "municipality":
                    state.municipality = value;
                    break;
                case "ownership":
                    state.ownership = value;
                    break;
            }
        },
    }
})

export const { updateSearchFilters } = searchFiltersSlice.actions;
export default searchFiltersSlice.reducer;
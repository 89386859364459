import React from "react";
import {useNavigate} from "react-router-dom";

export default function SchoolItem(props) {

    const navigate = useNavigate();
    const navigateHandler = () => {
        if(props.category === "shkolla"){
            navigate(`/shkollat-e-mesme-profesionale/${props.id}`);
        }else if(props.category === "qendra") {
            navigate(`/formation-centers/${props.id}`);
        } else if(props.category === "profile") {
            navigate('');
        } else{
            navigate(`/drejtime/${props.id}`);
        }
    }

    return(
        <div className="bg-[#db3222] text-white my-2 p-3 font-bold cursor-pointer" key={props.id} onClick={navigateHandler} category={props.category}>
            {props.title}
            {!!props.qyteti &&
                <>, {props.qyteti}</>
            }
        </div>
    )
}
import React, {useEffect, useState, useRef} from "react";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import Banner from "../Components/Shared/Layout/Banner";
import InfoBox from "../Components/Shared/Layout/InfoBox";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import {iconMarker} from "../Components/Maps/Map";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import withLanguage from "../HOC/withLanguage";
import Axios from "axios";
import {useParams} from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YoutubeEmbed from "../Components/Shared/Layout/YoutubeEmbed";
import Pagination from "../Components/Shared/Layout/Pagination";

let pageSize = 8;

const openInGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, "_blank");
};

function InstitutionDetail({language}) {
    const [data, setData] = useState([])
    const [imageIds, setImageIds] = useState([]);
    const [imageData, setImageData] = useState([]);
    const [degreeIds, setDegreeIds] = useState([]);
    const [degreeData, setDegreeData] = useState([]);
    const [page, setPage] = useState(0);
    const [ isLoading, setIsLoading ] = useState(false);
    const center = [data.latitude, data.longitude]
    const {id} = useParams();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect( () => {
        const getSchoolData = () => {
            setIsLoading(true);
            Axios.get(`/api/data/institution/${id}`)
                .then((res) => {
                    setData(res.data[0]);
                    setImageIds(res.data[0].image_ids);
                    setDegreeIds(res.data[0].configuration_ids);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        getSchoolData();
    }, []);

    useEffect( ()=>{
    const getImageContent = () => {
        Axios.post(`/api/binary_data/`, {
            "ids": imageIds
        }, {
            headers: {
                "Content-Type": 'text/plain'
            }
        })
            .then((res) => {
                setImageData(res.data);
            })
            .catch((err) => console.log(err))
    }

    if(imageIds.length){
        getImageContent();
    }
},[imageIds])

    useEffect( ()=>{
        const getDegrees = () => {
            Axios.post(`/api/institution_configuration/details/${page+1}/${pageSize}`, {
                "ids": degreeIds
            }, {
                headers: {
                    "Content-Type": 'text/plain'
                }
            })
                .then((res) => {
                    setDegreeData(res.data);
                })
                .catch((err) => console.log(err))
        }

        if(degreeIds.length){
            getDegrees();
        }
    },[degreeIds, page])

    function replaceWithBr(item) {
        return item?.replace(/\/n/g, "<br />");
    }
    const downloadFile = (name,mimetype,base64)=>{
        let element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:" +
            mimetype +
            ";base64," +
            base64
        );
        element.setAttribute("download", name);
        element.click();
    }
    return (
        <>
            {
                isLoading
                ? <>loading...</>
                : <>
                        <IntroBlock> {data.name} </IntroBlock>

                        <IntroBlock> {data.title} </IntroBlock>

                        <LayoutContent>
                            <LayoutLeft>
                                {/*InfoBox*/}
                                <InfoBox vendndodhja={data?.municipality_id?.name}
                                         themeluar={data.established}
                                         // email={data.email}
                                         fb_link={data.facebook_link}
                                         facebook={data.facebook}
                                         insta_link={data.instagram_link}
                                         instagram={data.instagram}/>

                                {/*Map*/}
                                <h3 className="text-xl py-5 text-[#DB3222]">{language.mapLoacation}</h3>

                                <div className="h-[300px] border-2 border-[#db3222]">
                                    {!!data?.latitude &&
                                        <MapContainer center={center} zoom={8} scrollWheelZoom={true} className="h-full w-full">
                                            <TileLayer
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>; contributors'
                                                url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                                            />
                                            <Marker position={{lat: data.latitude, lng: data.longitude}} icon={iconMarker}>
                                                <Popup>
                                                    <div className='max-w-[200px]'>
                                                        <span className="font-bold">{data.name}({data?.municipality_id?.name})</span>
                                                        <button className="text-[#DB3222] pt-1 flex mx-auto"
                                                                onClick={() => openInGoogleMaps(data.latitude, data.longitude)}>
                                                            {language.popupLink}
                                                        </button>
                                                    </div>
                                                </Popup>
                                            </Marker>
                                        </MapContainer>
                                    }
                                </div>
                            </LayoutLeft>

                            <LayoutRight>
                                {/*Youtube Embed Video*/}
                                {
                                    data.url &&
                                    <YoutubeEmbed className="pb-5 h-300" embedId={data.url} height={300}/>
                                }
                                {/*Text*/}
                                {data.description &&
                                    <p className="font-normal text-justify py-5" dangerouslySetInnerHTML={{__html: replaceWithBr(data?.description)}}></p>
                                }

                                {
                                    degreeData.records &&
                                    degreeData.records[0]?.degree_type === 'drejtim'
                                        ? <h3 className="text-lg">{language.degreeList}</h3>
                                        : <h3 className="text-lg">{language.centerList}</h3>

                                }
                                <div className="font-normal text-justify text-red-500">
                                    {
                                        degreeData.records &&
                                        degreeData.records.filter(item=>item.degree_type !== "middle").map((degree) => {
                                        return(
                                            <Accordion key={degree.degree_id} sx={{marginTop: '20px'}}>
                                                <AccordionSummary className="font-bold" sx={{color: '#DB3222'}} expandIcon={<ExpandMoreIcon sx={{color: '#DB3222'}}/>}>{degree.degree_name}</AccordionSummary>
                                                <AccordionDetails>
                                                    {
                                                        degree.degree_document &&
                                                        <>
                                                            <p className="text-blue-500 cursor-pointer mb-1" onClick={() => downloadFile(degree.document_name, degree.document_format, degree.degree_document)}>{degree.document_name ? degree.document_name : "Dokument" }</p>
                                                        </>
                                                    }
                                                    {
                                                        degree.degree_description &&
                                                        <p dangerouslySetInnerHTML={{__html: replaceWithBr(degree.degree_description)}}></p>
                                                    }

                                                    {
                                                        degree.degree_type === 'drejtim' &&

                                                        <h3 className="text-lg py-2">{language.list}</h3>

                                                    }
                                                    <ul className="list-disc pl-4">
                                                        {degree?.profiles && degree.degree_type === 'drejtim' && degree.profiles.map((profile) => {
                                                            return (
                                                                <li key={profile.profile_id}>{profile.profile_name}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })}

                                    <Pagination count={degreeData?.total_records} pageSize={pageSize} page={page} action={handleChangePage} />

                                </div>

                                {!!degreeData?.records?.filter(item=>item.degree_type === "middle").length && <h3 className="text-lg">{language.middleList}</h3>}

                                <div className="font-normal text-justify text-red-500">
                                    {
                                        !!degreeData?.records?.filter(item=>item.degree_type === "middle").length &&
                                        <>
                                            {degreeData.records.filter(item => item.degree_type === "middle").map((degree) => {
                                                return (
                                                    <Accordion key={degree.degree_id} sx={{marginTop: '20px'}}>
                                                        <AccordionSummary className="font-bold" sx={{color: '#DB3222'}}
                                                                          expandIcon={<ExpandMoreIcon
                                                                              sx={{color: '#DB3222'}}/>}>{degree.degree_name}</AccordionSummary>
                                                        <AccordionDetails>
                                                            {
                                                                degree.degree_document &&
                                                                <>
                                                                    <p className="text-blue-500 cursor-pointer mb-1" onClick={() => downloadFile(degree.document_name, degree.document_format, degree.degree_document)}>{degree.document_name ? degree.document_name : "Dokument" }</p>
                                                                </>
                                                            }
                                                            {degree.degree_description &&
                                                                <p dangerouslySetInnerHTML={{__html: replaceWithBr(degree.degree_description)}}></p>
                                                            }

                                                            {
                                                                degree.degree_type === 'drejtim' &&

                                                                <h3 className="text-lg py-2">{language.list}</h3>

                                                            }
                                                            <ul className="list-disc pl-4">
                                                                {degree?.profiles && degree.degree_type === 'drejtim' && degree.profiles.map((profile) => {
                                                                    return (
                                                                        <li key={profile.profile_id}>{profile.profile_name}</li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })}
                                            <Pagination count={degreeData?.total_records} pageSize={pageSize} page={page} action={handleChangePage} />
                                        </>
                                    }
                                </div>
                                {
                                    !!imageData ? imageData.map((image) => (
                                            <img
                                                key={image.name}
                                                src={`data:image/jpg;base64,${image.file_name}`}
                                                alt={image.name}
                                                className="pt-5"
                                            />
                                        ))
                                        : <Skeleton variant="rectangular" width={"100%"} height={300}/>
                                }

                                <p className="text-lg py-3">
                                    {language.apply}
                                    {data.type === 'shkolle_profesionale'
                                     ? <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=10058" target="_blank" className="text-blue-600">{language.link}</a>
                                     : <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=12549" target="_blank" className="text-blue-600">{language.link}</a>
                                    }
                                </p>

                            </LayoutRight>

                        </LayoutContent>
                </>
            }
        </>
    )
}

export default withLanguage(InstitutionDetail, "SchoolDetail")
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import MenuIcon from '@mui/icons-material/Menu';
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import LanguageSwitcher from "../helperComponents/LanguageSwitcher";
import withLanguage from "../../HOC/withLanguage";
import SearchInput from "./NavBarComponents/SearchInput";
import SearchButton from "./NavBarComponents/SearchButton";
import {useSelector} from "react-redux";
import HomeIcon from '@mui/icons-material/Home';
import {AccordionBody, AccordionContainer, AccordionTitle} from "../Shared/Layout/InstitutionAccordion";
import NavbarMenuCollapse from "./NavBarComponents/NavbarMenuCollapse";
import Typography from "@mui/material/Typography";

const initialAnimationState = {left: null, right:"translate-x-[100%]"}
function NavBar({language}) {
    const navMenu = {
        menuItems:[
            {
                title:language.plan,
                id:0,
                menuItems: [
                    {
                        title:language.planStudies,
                        id:0,
                        menuItems: [
                            {title: language?.navMenu1?.secondaryEducation , path:"/arsimi-i-mesem"},
                            {title: language?.navMenu1?.school ,path:"/shkollat"},
                            {title: language?.navMenu1?.studyOrientation , path:"/drejtime"},
                            {title: language?.navMenu1?.howToApply , path:"/si-te-aplikosh"},
                            {title: language?.navMenu1?.specialStudy , path:"/aftesi-ndryshe"}
                        ]
                    },
                    {
                        title:language.educationalInstitutions,
                        id:1,
                        menuItems: [
                            {title: language?.navMenu2?.lesson , path:"/learning-practice"},
                            {title: language?.navMenu2?.campus , path:"/campus-social-life"},
                            {title: language?.navMenu2?.costScholarship , path:"/bursa-transport"},
                        ]
                    },
                    {
                        title:language.future,
                        id:2,
                        menuItems: [
                            {title: language?.navMenu3?.profLife, path:"/profesionist-i-ri"},
                            {title: language?.navMenu3?.internWork, path:"/internship"},
                            {title: language?.navMenu3?.advice, path:"/career-advice"},
                        ]
                    },
                ]
            },
            {
                title:language.life,
                id:1,
                menuItems: [
                    {
                        title:language.planProffesional,
                        id:3,
                        menuItems: [
                            {title: language?.navMenu1?.secondaryEducation , path:"/arsimi-i-mesem"},
                            {title: language?.navMenu1?.school ,path:"/shkollat"},
                            {title: language?.navMenu1?.howToApply , path:"/si-te-aplikosh"},
                            {title: language?.navMenu3?.profLife, path:"/profesionist-i-ri"},
                            {title: language?.navMenu3?.internWork2, path:"/internship"},
                            {title: language?.navMenu3?.advice, path:"/career-advice"},
                        ]
                    },
                    {
                        title:language.future,
                        id:4,
                        menuItems: [
                            {title: language?.navMenu3?.internWork2, path:"/internship"},
                            {title: language?.navMenu3?.advice, path:"/career-advice"},
                        ]
                    },

                ]}
        ]

    }
    const navSelector = useSelector(state=> state.nav)
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [menuList,setMenuList]  = React.useState([])
    const [menuID,setMenuID]  = React.useState(null)
    const [animationClass,setAnimationClass]  = React.useState(initialAnimationState)
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, newExpanded) => {
        event.stopPropagation()
        setExpanded(newExpanded ? panel : false);
    };
    const handleClick = (id) => {
        if(id === 4){
            setMenuList(null)
        }else{
            setMenuList(navMenu?.menuItems[id]?.menuItems)
        }
        if(!open || (menuID === id)){
            // !!open ? document.documentElement.style.overflow = 'auto' : document.documentElement.style.overflow = 'hidden';
            setOpen(!open);
        }
        setMenuID(id)
    };
    const closeCollapse = ()=>{
        setOpen(false)
    }
    const currentPath = window.location.pathname;
    React.useEffect(()=>{
        if(menuID !== null) setMenuList(navMenu.menuItems[menuID].menuItems)
    },[language])
    React.useEffect(()=>{
        if(navSelector.navId !== null) {
            setMenuList(navMenu?.menuItems[navSelector.navId]?.menuItems)
            setMenuID(navSelector.navId)
            setOpen(true);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    },[navSelector])
    return (
            <Box>
                <AppBar position="static"  sx={{backgroundColor:'white', color:'black'}}>
                    <Toolbar className="flex flex-col justify-center items-center">
                        <div className='flex flex-row flex-grow w-full'>
                                <IconButton
                                    onClick={()=> {
                                        closeCollapse()
                                       navigate('/')
                                    }}
                                >
                                    {/*<HomeIcon sx={{color: 'white', fontSize: '2.5rem'}} />*/}
                                    <img src="/01_sekondare_anesore_transparente.png" alt="site-logo" className="max-w-[250px]"/>

                                </IconButton>

                            <Box className='absolute right-0 md:hidden block'>
                                <SearchButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setAnimationClass({left: '-translate-x-[100%]', right: 'translate-x-0'})
                                        handleClick(4)
                                    }}/>
                                    {!open && <IconButton
                                        size="large"
                                        color="inherit"
                                        onClick={() => {
                                            handleClick(0)
                                            setAnimationClass(initialAnimationState)
                                        }}
                                    >
                                        <MenuIcon/>
                                    </IconButton>}
                                    {open && <IconButton type="button" sx={{
                                        p: '10px', ":hover": {
                                            bgcolor: "transparent"
                                        }
                                    }} aria-label="search" onClick={() => {
                                        closeCollapse()
                                        setAnimationClass(initialAnimationState)
                                    }}>
                                        <CloseIcon sx={{color: 'black'}}/>
                                    </IconButton>}
                                </Box>

                            <div className="md:flex md:flex-row pt-3 w-full justify-center hidden">
                                <div className="mx-auto flex justify-center">
                                    {/*Menu Navbar*/}
                                    {navMenu.menuItems.map(item => (
                                        <Button
                                            variant="inherit"
                                            sx={{
                                                fontFamily:'RobotoBold, sans-serif',
                                                pb:'10px', pr: '40px',
                                                textTransform: 'initial',
                                                letterSpacing: '0',
                                                fontSize: '1.3rem',
                                                ":hover": {
                                                bgcolor: "transparent",
                                                textDecoration: 'underline'
                                                }
                                            }}
                                            onClick={() => handleClick(item.id)}
                                            key={item.id}
                                        >
                                            {item.title}
                                        </Button>
                                    ))}
                                </div>
                                <Box sx={{position: 'relative', display: 'flex', alignItems: 'center'}}  >
                                    <a className="flex w-52 cursor-pointer justify-center items-center" href="https://e-albania.al/">
                                        <img src="/logoEalbania.svg" className="mr-2" width="30px" fontSize="large" alt="e-albania-logo" />
                                        <div className="text-lg text-[#C82121] ml-1">
                                            <Typography variant="body1" style={{color: "#c82121", fontWeight: "600"}} className="cursor-pointer mr-4" >
                                                {language.login}
                                            </Typography>
                                            <Typography variant="body1" style={{color: "#c82121", fontWeight: "600"}} className="cursor-pointer mr-4" >
                                                e-albania
                                            </Typography>
                                        </div>
                                    </a>
                                    <LanguageSwitcher className="relative top-1 hidden md:block" />
                                    {
                                        currentPath !== "/" &&
                                        <SearchButton onClick={() => {handleClick(4)}} />
                                    }

                                </Box>


                            </div>

                        </div>


                    </Toolbar>
                    <Collapse in={open} timeout="auto" className='flex flex-col pt-5' onClick={closeCollapse} unmountOnExit>
                        {/* Menu NavBar list of buttons of specific menu */}
                        <div className="min-h-[calc(100vh-84px)] md:flex md:flex-col hidden">
                            {
                                menuList && menuList.map(item => {
                                    if ("menuItems" in item) {
                                        return (
                                            <NavbarMenuCollapse
                                                key={item.title}
                                                panel={`panel${item.id}`}
                                                title={item.title}
                                                expanded={expanded}
                                                onChange={handleChange}
                                            >
                                                {item.menuItems.map(subMenu=>(
                                                    <Button
                                                        variant="inherit"
                                                        sx={{
                                                            fontFamily: 'RobotoBold, sans-serif',
                                                            fontSize: '1.8rem',
                                                            padding: "0",
                                                            letterSpacing: 0,
                                                            textTransform: 'initial',
                                                            ":hover": {
                                                                bgcolor: "transparent",
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                        key={subMenu.title + subMenu.path}
                                                        onClick={() => {
                                                            if (subMenu.path === "/internship") {
                                                                window.open('https://puna.gov.al/', '_blank');
                                                            } else {
                                                                navigate(subMenu.path);
                                                            }
                                                        }}
                                                    >
                                                        {subMenu.title}
                                                    </Button>
                                                ))}
                                            </NavbarMenuCollapse>
                                        );
                                    } else {
                                        return (
                                            <Button
                                                variant="inherit"
                                                sx={{
                                                    fontFamily: 'RobotoBold, sans-serif',
                                                    fontSize: '2.3rem',
                                                    padding: "0",
                                                    letterSpacing: 0,
                                                    textTransform: 'initial',
                                                    ":hover": {
                                                        bgcolor: "transparent",
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                                key={item.title}
                                                onClick={() => {
                                                    if (item.path === "/internship") {
                                                        window.open('https://puna.gov.al/', '_blank');
                                                    } else {
                                                        navigate(item.path);
                                                    }
                                                }}
                                            >
                                                {item.title}
                                            </Button>
                                        );
                                    }
                                })
                            }

                            {
                                menuID===4 &&
                                <SearchInput onClick={(e)=>e.stopPropagation()} closeCollapse={closeCollapse} className="w-[90%] md:w-[40%]" />
                            }
                            <IconButton type="button" sx={{
                                p: '10px',
                                mt: '5%',
                                ":hover": {
                                    bgcolor: "transparent"
                                }
                            }}>
                                <CloseIcon sx={{width: '40px', height: '40px', color: 'black'}}/>
                            </IconButton>
                        </div>
                        {/* Mobile Nav */}
                        <div className='h-[calc(100vh-76px)] overflow-x-hidden md:hidden flex flex-row relative top-2'>
                            {/* Menu NavBar items for mobile*/}
                            <div className={`flex flex-col md:hidden transition ease-in-out duration-300 w-full ${animationClass.left}`}>
                                {navMenu.menuItems.map(item=>(
                                    <Button
                                        variant="inherit"
                                        sx={{
                                            fontFamily:'RobotoBold, sans-serif',
                                            fontSize:'20px',
                                            ":hover": {
                                            bgcolor: "transparent",
                                            textDecoration: 'underline'
                                            }
                                        }}
                                        key={item.id}
                                        onClick={(e)=>{
                                            setMenuList(navMenu.menuItems[item.id].menuItems)
                                            setAnimationClass({left:'-translate-x-[100%]',right:'translate-x-0'})
                                            e.stopPropagation()
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                ))}
                                <LanguageSwitcher className='relative mx-auto'/>

                            </div>
                            {/* Menu items list of specific menu for mobile*/}
                            <div className={`flex flex-col md:hidden  transition ease-in-out duration-300 w-full absolute ${animationClass.right}`}>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    sx={{":hover": {
                                        bgcolor: "transparent"
                                    }}}
                                    onClick={(e)=> {
                                        setMenuID(null)
                                        e.stopPropagation();
                                        setAnimationClass(initialAnimationState);
                                    }}
                                >
                                    <ArrowBack />
                                </IconButton>
                                {menuList && menuList.map((item)=>{
                                    if("menuItems" in item){
                                        return <NavbarMenuCollapse
                                            key={item.title}
                                            panel={`panel${item.id}`}
                                            title={item.title}
                                            expanded={expanded}
                                            onChange={handleChange}
                                        >
                                            {item.menuItems.map(subMenu=>(
                                                <Button
                                                    variant="inherit"
                                                    sx={{
                                                        fontFamily: 'RobotoBold, sans-serif',
                                                        fontSize: '1.6rem',
                                                        padding: "0",
                                                        letterSpacing: 0,
                                                        textTransform: 'initial',
                                                        ":hover": {
                                                            bgcolor: "transparent",
                                                            textDecoration: 'underline'
                                                        }
                                                    }}
                                                    key={subMenu.title + subMenu.path}
                                                    onClick={() => {
                                                        if (subMenu.path === "/internship") {
                                                            window.open('https://puna.gov.al/', '_blank');
                                                        } else {
                                                            navigate(subMenu.path);
                                                        }
                                                    }}
                                                >
                                                    {subMenu.title}
                                                </Button>
                                            ))}
                                        </NavbarMenuCollapse>
                                    }else{
                                        return   <Button
                                            variant="inherit"
                                            sx={{
                                                fontFamily:'RobotoBold, sans-serif',
                                                fontSize:'20px',
                                                ":hover": {
                                                    bgcolor: "transparent",
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                            key={item.title}
                                            onClick={()=>{
                                                if(item.path === "/praktika") {
                                                    document.location.href = "https://puna.gov.al/"
                                                }else {
                                                    navigate(item.path)
                                                }
                                            }}
                                        >{item.title}
                                        </Button>
                                    }

                                })}

                                {
                                    menuID===4 &&
                                    <SearchInput onClick={(e)=>e.stopPropagation()} closeCollapse={closeCollapse} className="max-w-[90%] md:w-[40%]" />
                                }
                            </div>

                        </div>

                    </Collapse>
                </AppBar>
            </Box>
    );
}
export default withLanguage(NavBar,"NavBar")
import {useNavigate} from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withLanguage from "../../../HOC/withLanguage";

function SearchCard({language, id, title, text, category, list, onClick}) {

    const navigate = useNavigate();
    const navigateHandler = () => {
        if(category === "shkolla"){
            navigate(`/shkollat-e-mesme-profesionale/${id}`);
        }else if(category === "qendra") {
            navigate(`/formation-centers/${id}`);
        } else if(category === "universitete") {
            navigate(`/universities/${id}`);
        }
    }

    return (
        <div className="shadow-lg p-8 mt-7 bg-gray-100 cursor-pointer" key={id} onClick={navigateHandler}>
            <h3 className="pt-4 text-2xl font-bold">{title}</h3>
            <p className="font-normal pt-4">{text}</p>

            <div onClick={((e) => {
                                e.stopPropagation()
                                onClick()
                            })}
            >
                <Accordion sx={{boxShadow: 'none', color: '#DB3222', borderBottom: '1px solid #DB3222', borderRadius: 'initial !important', background: 'transparent'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{color: '#DB3222'}} />}
                        sx={{paddingLeft: '0px', paddingRight: '0px', fontWeight: '600'}}
                    >
                        { category==='shkolla' ? language.accordionTitleDegree : language.accordionTitleCourse }
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul className="list-disc">
                            {!!list &&
                                list.map((item) => {
                                    return <li key={item.degree_id}>{item.degree_name}</li>;
                                })}
                        </ul>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div>
    )
}

export default withLanguage(SearchCard,"SearchCard")
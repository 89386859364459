import React, {useState, useEffect} from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import SchoolItem from "../Components/Shared/Layout/SchoolItem";
import Banner from "../Components/Shared/Layout/Banner";
import TablePagination from "@mui/material/TablePagination";
import withLanguage from "../HOC/withLanguage";
import Axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {useSelector} from "react-redux";
import Pagination from "../Components/Shared/Layout/Pagination";

let pageSize = 14;
function Drejtime({onClick=()=>{}, language,}) {

    const [isLoading, setIsLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [listData, setListData] = useState([]);
    const [category, setCategory] = useState("drejtim");
    const [inputValue,setInputValue] = React.useState("");
    const degreeFilter = useSelector(state=> state.degreeFilters.currentFilter)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //Get Schools List
    const getDegrees = () => {

        setIsLoading(true);
        Axios.post(`/api/degree_institution/${page+1}/${pageSize}`, {
            "filter": category
        }, {
            headers: {
                "Content-Type": 'text/plain'
            }
        })
            .then((res) => {
                setListData(res.data);
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(true)
            })
    }


    const getSearchData = () => {
        Axios.get(`/api/search_degree/${inputValue}/${page+1}/${pageSize}`)
            .then((res) => {
                setListData(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setCategory('');
    }
    useEffect( ()=>{
        getDegrees();

        if (inputValue) {
            getSearchData();
        }
    },[page, category])

    // On radio filter change
    const handleRadioChange= (event)=>{
        if(event.target.value === 'drejtim'){
            setCategory("drejtim");
            setInputValue("");
        }else if(event.target.value === 'kurs'){
            setCategory("kurs");
            setInputValue("");
        }
        else if(event.target.value === 'middle'){
            setCategory("middle");
            setInputValue("");
        }
        setPage(0)
    }

    const submitHandler = (e)=>{
        e.preventDefault();
        if (inputValue !== "") {
            getSearchData();
        } else {
            getDegrees();
        }
    }

    useEffect(()=>{
        setCategory(degreeFilter)
    },[degreeFilter])

    return (
        <>
            <Banner img='/findDirection_banner.jpg' title={language.banner}/>

            <IntroBlock>{language.introText}
            </IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                {/*Search*/}
                <Paper
                    component="form"
                    className={`flex items-center w-[90%] md:w-[40%] m-auto py-[2px] px-[4px] mt-10`}
                    onClick={onClick}
                    onSubmit={submitHandler}

                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={language.search}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={submitHandler}>
                        <SearchIcon sx={{ color: '#DB3222'}}/>
                    </IconButton>
                </Paper>

                {/*Filters*/}
                <h3 className="text-xl pt-5 text-[#DB3222] font-bold">{language.filters}</h3>
                <FormGroup>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="drejtim" onChange={handleRadioChange}
                                name="radio-buttons-group" className="flex pb-5" sx={{flexDirection: 'row'}}>
                        <FormControlLabel
                            value="drejtim"
                            control={<Radio sx={{color: '#DB3222', '&.Mui-checked': {color: '#DB3222'}}}/>}
                            label={language.schoolCategory}
                            className="text-[#DB3222]"
                            checked={category === "drejtim"}
                        />
                        <FormControlLabel
                            value="middle"
                            control={<Radio sx={{color: '#DB3222', '&.Mui-checked': {color: '#DB3222'}}}/>}
                            label={language.middleCategory}
                            className="text-[#DB3222]"
                            checked={category === "middle"}
                        />
                        <FormControlLabel
                            value="kurs"
                            control={<Radio sx={{color: '#DB3222', '&.Mui-checked': {color: '#DB3222'}}}/>}
                            label={language.centerCategory}
                            className="text-[#DB3222]"
                            checked={category === "kurs"}
                        />
                    </RadioGroup>
                </FormGroup>

                <h3 className="text-lg py-5 text-[#DB3222] font-bold">{listData.amount} {category==="drejtim" ? language.degree : category==="kurs" ? language.center : language.middle }</h3>
                <div className="school-list">
                    {!isLoading ? listData?.records?.map((item) => {
                            return (
                                <SchoolItem key={item.id} title={item?.name} id={item?.id} category={category}/>
                            )
                        })
                        :
                        <>
                            <Skeleton variant="rectangular" className="w-full mb-3" height={50}/>
                            <Skeleton variant="rectangular" className="w-full mb-3" height={50}/>
                            <Skeleton variant="rectangular" className="w-full " height={50}/>
                        </>
                    }
                </div>

                <Pagination count={listData?.amount} pageSize={pageSize} page={page} action={handleChangePage} />

            </div>
        </>
    )
}

export default withLanguage(Drejtime, "Drejtime")
import SearchCard from "../Components/Shared/Layout/SearchCard";
import Axios from "axios";
import {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import SearchInput from "../Components/NavBar/NavBarComponents/SearchInput";
import withLanguage from "../HOC/withLanguage";
import {useSearchParams} from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

function SearchResult({language}) {
    const [schoolsData, setSchoolsData] = useState([])
    const [centersData, setCentersData] = useState([])
    const [degreesData, setDegreesData] = useState([])
    const [ loading, setLoading] = useState(false)
    const [query] = useSearchParams();
    const queryValue = query.get("query")
    const institutionFilter = useSelector(state => state.searchFilters.institutionType)
    const municipalityFilter = useSelector(state => state.searchFilters.municipality);
    const ownershipFilter = useSelector(state => state.searchFilters.ownership);
    console.log(institutionFilter,municipalityFilter,ownershipFilter)
    const [filterData, setFilterData] = useState([])

    useEffect(()=>{
        if(!queryValue){
            setSchoolsData([])
            setCentersData([])
            return
        }
        setLoading(true)
        Axios.get(`/api/institution_info/${queryValue.trim()}`)
            .then( (response) =>{
                if( !response.data.error ){
                    const responseJson = response.data

                    const schoolsDataHolder = responseJson
                        .filter((schoolItem) => schoolItem.type === 'shkolle_profesionale')
                        .map((schoolItem) => ({
                            id: schoolItem.id,
                            name: schoolItem.name,
                            category: 'shkolla',
                            degrees: schoolItem.configuration_ids,
                        }));

                    const centersDataHolder = responseJson
                        .filter((schoolItem) => schoolItem.type === 'qender_formimi')
                        .map((schoolItem) => ({
                            id: schoolItem.id,
                            name: schoolItem.name,
                            category: 'qendra',
                            degrees: schoolItem.configuration_ids,
                        }));

                    setSchoolsData(schoolsDataHolder);
                    setCentersData(centersDataHolder);
                }
            })
            .catch( (error) =>{
                if( error.response && error.response.data){
                    //showToastMessage(error.response.data.error, "ERROR", 3000);
                    console.log("No Resultsssss")
                }
                else{
                    console.log("No Results")
                }
            });
        setLoading(false)

    },[queryValue])




    useEffect(() => {
        const requestData = {}
        if (institutionFilter) {
            requestData.type = institutionFilter
        }

        if (municipalityFilter) {
            requestData.municipality = municipalityFilter
        }

        if (ownershipFilter) {
            requestData.ownership = ownershipFilter
        }
        //Post Filters
        Axios.post(`/api/filter_institution_list`,
            requestData ,
    {
            headers: {
                "Content-Type": 'text/plain'
            }
        })
            .then((res) => {
                setFilterData(res.data.records);
            })
            .catch((err) => console.log(err))

    }, [institutionFilter,municipalityFilter,ownershipFilter])


    const filteredSchoolsData = schoolsData.filter(schoolItem => (
        filterData.some(filterItem => filterItem.id === schoolItem.id)
    ));

    const filteredCentersData = centersData.filter(schoolItem => (
        filterData.some(filterItem => filterItem.id === schoolItem.id)
    ));

    const getDegrees = (item) => {
        Axios.post(`/api/institution/degree`, {
            "ids": item.degrees
        }, {
            headers: {
                "Content-Type": 'text/plain'
            }
        })
            .then((res) => {
                setDegreesData(res.data);
            })
            .catch((err) => console.log(err))
    }

    return (
        <div className="flex flex-col items-center m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
            <SearchInput showFilters={true} value={queryValue} className="w-full bg-gray-100"/>
            <div className="flex md:flex-row flex-col mt-8 w-full justify-between h-full">
                {(!centersData.length && !schoolsData.length && queryValue) &&
                    <div className="pt-5 font-bold text-lg">{language.noResults}</div>
                }
                {(!!centersData.length || !!schoolsData.length) &&
                    <div className="w-full">

                        {/*Schools*/}
                        {!!filteredSchoolsData.length &&
                            <div className="w-full">
                                <h2 className="text-[#DB3222] font-bold text-lg uppercase md:mt-0 mt-5">{language.searchResultSchool}</h2>
                                        {!loading ? filteredSchoolsData.map(item=>(
                                            <SearchCard key={item.id} title={item.name} text={item.title} category={item.category} id={item.id} list={degreesData} onClick={() => {getDegrees(item)}}/>
                                    ))
                                    :
                                    <Skeleton variant="rectangular" width={"90%"} height={200} />
                                    }
                            </div>
                        }

                        {/*Formation Centers*/}
                        {!!filteredCentersData.length &&
                            <div className="w-full">
                                <h2 className="text-[#DB3222] font-bold text-lg uppercase mt-5">{language.centers}</h2>
                                {!loading ? filteredCentersData.map(item=>(
                                        <SearchCard key={item.id} title={item.name} text={item.title} category={item.category} id={item.id} list={degreesData} onClick={() => {getDegrees(item)}}/>
                                    ))
                                    :
                                    <Skeleton variant="rectangular" width="90%" height={200} />
                                }
                            </div>
                        }
                    </div>
                }

            </div>
        </div>
    )

}

export default withLanguage(SearchResult,"Search")
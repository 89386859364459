import React, {useEffect, useState} from 'react'
import Banner from "../Components/Shared/Layout/Banner";
import withLanguage from "../HOC/withLanguage";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import {AccordionBody, AccordionContainer, AccordionTitle} from "../Components/Shared/Layout/InstitutionAccordion";

const BursaTransport = ({language}) => {

    return (
        <>
            <Banner img="/bursa/bursaBanner.jpg" title={language.mainTitle}/>
            <IntroBlock>{language.intro}</IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <p className="text-lg text-justify">{language.p1}</p>
                <p className="text-lg py-3 text-justify">{language.p2}</p>
                <p className="text-lg text-justify">{language.p3}</p>
            </div>

            <div className="flex lg:flex-row flex-col max-h-full text-white roboto-light">
                <div className="lg:w-1/2 w-full">
                    <img src='/bursa/quote.jpg' alt="image-element" className="h-full w-full object-cover object-top"/>
                </div>
                <div className="lg:w-1/2 w-full bg-[#db3222] flex flex-col items-center justify-center p-5">
                    <h3 className="lg:text-2xl text-xl font-bold lg:max-w-[60%] max-w-full">{language.quote}</h3>
                </div>
            </div>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <h3 className="text-2xl font-bold py-4">{language.criterionsTitle}</h3>
                <p className="text-lg font-bold pb-4">{language.criterions}</p>
                <p className="text-lg font-bold pb-2">{language.EconomicCriterionTitle}</p>
                <p className="text-lg">{language.EconomicCriterionDesc}</p>
                <p className="text-lg font-bold py-4">{language.docs}</p>
                <ul className="list-disc pl-4">
                    <li className="text-lg">{language.cat1L1}</li>
                    <li className="text-lg">{language.cat1L2}</li>
                    <li className="text-lg">{language.cat1L3}</li>
                    <li className="text-lg">{language.catL6}</li>
                    <li className="text-lg">{language.economicL5}</li>
                    <li className="text-lg">{language.economicL6}</li>
                    <li className="text-lg">{language.economicL7}</li>
                    <li className="text-lg">{language.economicL8}</li>
                    <li className="text-lg">{language.economicL9}</li>
                    <li className="text-lg">{language.catL7}</li>
                    <li className="text-lg">{language.economicL11}</li>
                </ul>

                <p className="text-lg font-bold pb-2 pt-8">{language.ResultCriterionTitle}</p>
                <p className="text-lg">{language.ResultCriterionDesc}</p>
                <p className="text-lg font-bold py-4">{language.docs}</p>
                <ol className="list-disc pl-4">
                    <li className="text-lg">{language.resultL1}</li>
                    <li className="text-lg">{language.resultL2}</li>
                    <li className="text-lg">{language.resultL3}</li>
                    <li className="text-lg">{language.catL7}</li>
                    <li className="text-lg">{language.resultL5}</li>
                </ol>

                <p className="text-lg font-bold pt-8">{language.NonEconomicCriterionTitle}</p>
                <ol className="list-decimal pl-4">
                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol1}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.Ol1Li1}</li>
                            <li>{language.Ol1Li2}</li>
                            <li>{language.Ol1Li3}</li>
                            <li>{language.Ol1Li4}</li>
                            <li>{language.Ol1Li5}</li>
                            <li>{language.Ol1Li6}</li>
                        </ul>

                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.catL7}</li>
                            <li>{language.catL7}</li>
                        </ul>
                    </li>

                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol2}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.Ol2Li1}</li>
                            <li>{language.Ol2Li2}</li>
                            <li>{language.Ol2Li3}</li>
                            <li>{language.Ol2Li4}</li>
                            <li>{language.Ol2Li5}</li>
                            <li>{language.Ol2Li6}</li>
                            <li>{language.Ol2Li7}</li>
                            <li>{language.Ol2Li8}</li>
                        </ul>
                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.catL6}</li>
                            <li>{language.catL7}</li>
                            <li>{language.catL8}</li>

                        </ul>

                    </li>

                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol3}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.Ol3Li1}</li>
                            <li>{language.Ol3Li2}</li>
                            <li>{language.Ol3Li3}</li>
                            <li>{language.Ol3Li4}</li>
                            <li>{language.Ol3Li5}</li>
                            <li>{language.Ol3Li6}</li>
                            <li>{language.Ol3Li7}</li>
                        </ul>
                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.catL7}</li>
                            <li>{language.catL9}</li>

                        </ul>
                    </li>

                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol4}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.O4Li1}</li>
                            <li>{language.Ol4Li2}</li>
                        </ul>
                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.catL7}</li>
                            <li>{language.catL10}</li>

                        </ul>
                    </li>

                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol5}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.Ol5L1}</li>
                            <li>{language.Ol5L2}</li>
                            <li>{language.Ol5L3}</li>
                            <li>{language.Ol5L4}</li>
                            <li>{language.Ol5L5}</li>
                        </ul>
                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.catL7}</li>
                            <li>{language.catL11}</li>

                        </ul>
                    </li>

                    <li className="py-4 text-lg"><span className="font-bold">{language.Ol6}</span>
                        <ul className="list-disc pl-4">
                            <li>{language.Ol5L1}</li>
                            <li>{language.Ol5L2}</li>
                            <li>{language.Ol5L3}</li>
                            <li>{language.Ol5L4}</li>
                            <li>{language.Ol6Li1}</li>
                        </ul>
                        <p className="text-lg font-bold py-4">{language.docs}</p>
                        <ul className="list-disc pl-4">
                            <li>{language.cat1L1}</li>
                            <li>{language.cat1L2}</li>
                            <li>{language.cat1L3}</li>
                            <li>{language.cat1L4}</li>
                            <li>{language.catL7}</li>
                        </ul>
                    </li>
                </ol>

                <h3 className="text-3xl mb-3 font-bold py-5">{language.costTitle}</h3>
                <p className="text-lg">{language.costDesc}</p>

            </div>
        </>
    )
}

export default withLanguage(BursaTransport, 'BursaTransport')
import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import withLanguage from "../HOC/withLanguage";
import Titles from "../Components/Shared/Layout/Titles";
import React from "react";


const CareerAdvice = ({language}) => {

    return (
        <>
            <Banner img='/CareerAdvice/banner.jpg' title={language.banner}/>

            <IntroBlock>{language.intro}</IntroBlock>
            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <ol className="list-decimal pl-4">
                    <li className="py-2 text-lg text-justify">{language.l1}</li>
                    <li className="py-2 text-lg text-justify">{language.l2}
                        <p className="text-lg py-3 text-justify">
                            {language.afproText}
                            <a href="https://afpro.al/" target="_blank" className="text-blue-600"> {language.afproLink}</a>
                        </p>
                    </li>
                    <li className="py-2 text-lg text-justify">{language.l3}</li>
                    <li className="py-2 text-lg text-justify">{language.l4}</li>
                    <li className="py-2 text-lg text-justify">
                        {language.l5} <br/> <br/>
                        {language.afproText}
                        <a href="https://afpro.al/" target="_blank" className="text-blue-600"> {language.afproLink}</a>
                    </li>
                    <li className="py-2 text-lg text-justify">{language.l6}</li>
                    <li className="py-2 text-lg text-justify">{language.l7}</li>
                </ol>

                <p className="text-lg py-3 text-justify">{language.p2}</p>
            </div>
        </>
    )
}

export default withLanguage(CareerAdvice, "CareerAdvice")
import withLanguage from "../../../HOC/withLanguage";


function InfoBox({vendndodhja,themeluar,email,fb_link,facebook,insta_link,instagram,language}) {

    return (
        <div className="md:w-1/2 w-full bg-[#db3222] p-5 text-white" >
            <ul className="break-all">
                <li className="pb-2">
                    <span className="font-bold">{language.location} </span>
                    {vendndodhja}
                </li>
                <li className="pb-2">
                    <span className="font-bold">{language.established} </span>
                    {themeluar}
                </li>
                {email && <li className="pb-2">
                    <span className="font-bold">{language.email} </span>
                    <a href={"mailto:" + email} target="_blank">{email}</a>
                </li>}
                <li className="pb-2">
                    <span className="font-bold">{language.facebook} </span>
                    <a href={fb_link} target="_blank">{facebook}</a>
                </li>
                <li>
                    <span className="font-bold">{language.instagram} </span>
                    <a href={insta_link} target="_blank">{instagram}</a>
                </li>

            </ul>
        </div>
    )
}

export default withLanguage(InfoBox, "infoBox")
import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DormitoryAccordion = ({ county, children }) => {
    return (
        <Accordion
            sx={{
                marginTop: '20px',
                boxShadow: 'none',
                borderBottom: '1px solid black',
                borderRadius: '0 !important',
                '&:before': { display: 'none' },
            }}
        >
            <AccordionSummary
                className="font-bold"
                sx={{ color: 'black', paddingX: '0' }}
                expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            >
                {county}
            </AccordionSummary>
            <AccordionDetails sx={{ paddingX: '0' }}>{children}</AccordionDetails>
        </Accordion>
    );
};

export const Description = ({ children }) => {
    return <>{children}</>;
};

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CommunityVideo from "./CommunityVideo";

export default function MultiCarousel({children}) {
    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        largeDesktop: {
            breakpoint: {max: 3000, min: 1200},
            items: 5
        },
        desktop: {
            breakpoint: {max: 1200, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
            <Carousel responsive={responsive}>
                {children}
            </Carousel>
        </div>
    )
}
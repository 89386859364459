import Carousel from "../Components/Shared/Layout/Carousel";
import withLanguage from "../HOC/withLanguage";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";

function Community({language}) {

    return (
        <>
            <IntroBlock>{language.intro}</IntroBlock>

            <Carousel type='success_case'/>

            {/*<h3 className="py-7">Institution</h3>*/}
            {/*<Carousel type="institution"/>*/}

            {/*<h3 className="py-7">Degree</h3>*/}
            {/*<Carousel type="degree"/>*/}

        </>

    )
}

export default withLanguage(Community, "Community")
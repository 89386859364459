import React from 'react';
import classNames from 'classnames';
const Image = ({className,src,alt,style})=>{
    const imageClass = classNames(
        'bg-center','bg-cover','object-cover', "w-full",className
    );
    return(
        <img
            src={src}
            className={imageClass}
            alt={alt}
            style={style}
        />
    )
}
export default Image
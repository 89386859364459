import React from 'react';
import classNames from 'classnames';
export const LayoutContent = ({children, className, grid=true, mobileFullWidth = false})=>{

    return(
    <div className={`flex flex-col ${grid ? "md:grid md:grid-cols-7 gap-16 lg:gap-32" : "gap-3"} xl:px-[17rem]  lg:px-[7rem] ${mobileFullWidth ? "" : "md:px-[3rem] sm:px-[1rem]"} ${className}`}>
        {children}
    </div>
    )
}

export const LayoutLeft = ({children, className})=>{
    const leftClass = classNames(
        'col-span-4',
        className
    )
    return(
        <div className={leftClass}>
            {children}
        </div>

    )
}

export const LayoutRight = ({children, className})=>{
    const rightClass = classNames("col-span-3",className)
    return(
        <div className={rightClass} >
            {children}
        </div>
    )
}
export default{
"NavBar": {
    "title": "AFTËSI.AL ",
    "plan": "Professional education",
    "life": "Professional Training",
    "educationalInstitutions": "Educational Institutions",
    "planStudies":"Plan Studies",
    "planProffesional":"Plan Professional Training",
    "future": "Professional Career",
    "login": "Login with",
    "navMenu1":{
      "school":"Find the educational institution",
      "studyOrientation":"Find the direction of study",
      "secondaryEducation":"Get to know Vocational Training System",
      "studyBranches":"Study Branches",
      "howToApply":"How to Apply",
      "specialStudy":"Possibilities for people with disabilities",
      "courses":"Courses",
    },
    "navMenu2":{
      "lesson":"Teaching and practices",
      "practice":"Business practice",
      "campus":"Campuses and social life",
      "transport":"Transport",
      "socialLife":"Social Life",
      "costScholarship":"Scholarship and Transport",
      "dormitory": "Dormitory"
    },
    "navMenu3":{
      "profLife":"The life of a new professional",
        "internWork":"Work and internships",
        "internWork2":"Work",
      "advice":"Career advice",
    }
  },

// Page(/shkollat)
"Shkollat": {
    "banner": "Education and Vocational Training",
    "introText": "In professional training institutions, the career journey starts from the first days. By making progress, the opportunities to develop connections with businesses become even greater.",
    "filters": "Filters",
    "specialization": "Specialization",
    "school": "Professional School",
    "popupLink": "Open in Google Maps",
    "schoolCategory": "professional schools",
    "degreeCategory": "degrees",
    "center": "Formation Centers",
    "search": "Search Institution",
    "institution": "Institutions",
  },

"infoBox":{
    "location": "Location: ",
    "established": "Established: ",
    "students": "Students: ",
    "staff": "Staff: ",
    "website": "Website: ",
    "email": "E-mail: ",
    "facebook": "Facebook: ",
    "instagram": "Instagram: ",
  },

// Page(/shkollat-e-mesme-profesionale)
"SchoolDetail": {
    "banner": "Professional High schools",
    "introText": "A school where tradition and professionalism develop the new generation of professionals. A school where tradition and professionalism develop the new generation of professionals",

    "mapLoacation": "Location",
    "popupLink": "Open in Google Maps",
    "list": "Profiles List",
    "degreeList": "The majors offered at this school are:",
    "centerList": "The courses offered at this formation center are:",
    "middleList": "Post-secondary majors offered at this school: ",
    "apply": "If you want to apply click",
    "link": " here",
  },

//Page(/profesionist-i-ri)
"NewProfessional": {
    "banner": "The life of a young professional",
    "introText": "Discover the rewarding journey of a professional in vocational training institutions and witness their transformation into skilled and passionate individuals.",
    "p1": "The life of a young professional in vocational schools is an exciting and transformative journey. As you enter the world of vocational education and training, you begin a path that combines academic learning with practical experiences. From day one, you are immersed in a dynamic environment where you gain practical skills and industry-relevant knowledge. The practical nature of vocational education programs allows you to apply what you learn in real-world settings, preparing you for the challenges and demands of your chosen profession.",
    "p2": "As a young professional, you have the opportunity to work closely with experienced instructors and industry experts who guide and mentor you along the way. You also become part of a community of like-minded individuals who share your passion and dedication. Through internships, work placements and industry collaborations, you gain invaluable insight into the professional world and make connections that can take your career forward. The life of a young professional in vocational schools is filled with growth, learning, and satisfaction knowing that you are on the path to success in your chosen field.",
    "quote": "It's been 2 years since I started working in one of the businesses that cooperated with the school to offer internships. The transition from school to the professional world has been much easier thanks to hands-on training, collaborative projects, and industry internships, which have given me real-world experience.",
  },

"inputComponent":{
    "search":"Search (Elektronic, TIK, Mekanic etc.)"
  },

//Page(/praktika)
"Practice": {
    "banner": "Practice in businesses",
    "introText": "In Professional High schools, the career journey starts from the first days. By making progress, the opportunities to develop connections with businesses become even greater.",
  },

//Titles
"Titles": {
    "title1": "Employment and Internships",
    "title2": "Career advice",
    "title3": "Practical help"
  },

//Footer
"Footer" : {
    "other": "Other pages",
    "follow": "Follow us",
    "subtitle": "News.",
    "title1": "Don't miss our Newsletter.",
    "title2": "Register!",
    "statistics":"Statistics",
    "aboutUs":"About Us",
    "privacy":"Privacy",
    "terms":"Terms and Conditions",
    "info":"Useful Info",
    "contact":"Contact Us",
    "copyright":"This system is hosted by the National Information Society Agency. This electronic system is maintained by the economic operator: Communication Progress"
  },

//Homepage
"Homepage": {

    "banner": "Find the path to the profession",
    "banner2": "Find the path to the",
    "bannerBlock": "profession",
    "directionOfStudies": "Find the Direction of Study",
    "directionOfStudiesDescription": "Choose the direction of study to build a successful career fully adapted to the demands of the labor market.",
    "aboutInstitution": "Find the Educational Institution",
    "aboutInstitutionDescription": "In vocational training institutions, the opportunities to develop connections with businesses become even greater.",
    "applyOnline": "How to apply online to Vocational Education schools and Training Centers?",
    "internship": "Employment and Internships",
    "internshipDetails": "Did you know that one in two students get a job right after graduation? Of course, in addition to employment, you will have the opportunity to attend various internships so that the transition to employment is super easy.",
    "practicalLearning": "Learning and Practice",
    "transportGrants": "Scholarships and transportation",
    "buttonElTitle": "Get to know AFPro",
    "buttonElText": "Are you a business or a mentor? Take part!",
    "afpSistem" : "Get to know the Vocational Training System in Albania",
    "socialMedia": "Follow us on social media",
    "afpSistemDescription": "The system of professional education and training public consists of 41 institutions from it which 31 vocational schools and 10 training centers that offer post-secondary vocational qualifications and short term, for all ages.",
        "inputBanner":{
        "infoBall1": <><span>Register at</span> <br /><span>Professional education</span></>,
        "infoBall2": <><span>Register at</span> <br /><span>Professional course</span></>,
        "infoBall3": <><span>Discover</span> <br /><span>more</span></>
    }
  },

//Search
"Search": {
    "noResults": "No results found",
    "categorySchool": "School",
    "categoryDegree": "Degree",
    "searchResultSchool": "Schools",
    "centers": "Formation Centers",
    "universities": "Universities",
},

"SearchFilters": {
  "institutionType": "Institution Type",
  "typeSchool": "Professional School",
  "typeCenter": "Formation Center",
  "municipality": "Municipality",
  "ownership": "Ownership",
  "public": "Public",
  "private": "Private"
},

//SearchCard
"SearchCard": {
    "accordionTitleDegree": "Degrees",
    "accordionTitleCourse": "Courses",
  },

"siTeAplikosh": {
    "mainTitle": "How to Apply",
    "intro": "The application process for pursuing studies in the vocational training system in Albania varies depending on the institution you wish to apply to.",
    "link": "here",
    "register": "Registration in the tenth grade, in vocational high school",

    "shkollaTitle": " Registration in the tenth grade, in vocational high school",
    "shkollaP1": "Applications for registration in the tenth grade, in vocational high school in Albania are done through the e-Albania platform via the electronic service",
    "shkollaP2": ", starting from July 8, 2024, until September 30, 2024, at 23:59.",
    "shkollaP3": "The electronic service \"Registration in the tenth grade, in vocational high school\" helps parents and guardians to register the child in the 10th grade, in vocational high school. The person exercising parental responsibility applies online by filling out the necessary form data and awaits the approval or rejection of the application within 10 days from the date of generating the certificate.",
    "shkollaP4": "Vocational Education Providers accept students from all basic education schools, regardless of the geographical area. Students up to the age of 18 are admitted to the tenth grade of vocational high school, and they are allowed to attend school until the age of 22. In vocational high school, students are accepted without a competition. In special cases, the provider's administration sets quotas based on special criteria, according to the priorities and objectives of the medium-term plan.",
    "shkollaP5": "When a vocational education provider had higher demands than the number of registered students for a particular educational direction in the previous academic year, applications made within 7 days from the opening of registrations will be selected based on the following criteria: the student's residence within the district where the provider operates, the highest average grades of the basic education certificate, and the earliest application date. If there are free capacities after evaluating the applications made in the first 7 days, acceptance is based on the principle of first-come, first-served according to the minimum accepted average for the previous year for the educational direction.",
    "shkollaP6Title": "Suggestion:",
    "shkollaP6": "Before applying, we encourage you to identify educational institutions that offer vocational education in your field of interest. To learn about available programs, you can check and browse",
    "shkollaProfesionale": "Vocational Schools",
    "degree": "Directions",
    "and": "and",
    "shkollaDokTitle": "Required documentation to upload in the application:",
    "shkollaL1": "When the application is made by the child's legal guardian, the legal guardianship document must be uploaded;",
    "shkollaP7": "The person exercising parental responsibility applies online by filling out the necessary form data and awaits the approval or rejection of the application.",
    "shkollaP8": "The school will electronically notify the applicant in case of acceptance or rejection of the registration within 5 days.",
    "shkollaP9": "For detailed information on the educational programs offered in the public vocational education system, click on the link",

    "univTitle": "Registration in post-secondary education:",
    "univP1": "The registration of the student in post-secondary education is done at the school within the application deadline announced by the school.",
    "univP2": "Advantages of registering in vocational education:",
    "univL1": "Girls who enroll in vocational education receive 50% of the scholarship.",
    "univL2": "All students who enroll in the educational directions: agriculture, veterinary, forestry, construction, fishing, maritime, geology-mining, and drilling and exploitation of oil and gas fields, receive a scholarship for all years of schooling.",
    "univL3": "All students who enroll in vocational education are covered for transportation expenses for trips from their permanent residence to another residence, at distances from 5 km to 50 km away from the public vocational education provider.",
    "univL4": "After completing Vocational Education, students acquire skills required by the labor market and are not penalized in continuing university studies.",
    "centerTitle": "Registration in a vocational training course:",
    "centerP1": "The Public Vocational Training System offers opportunities for qualification and requalification in the most demanded professions in the main districts of the country. Vocational courses, offered by Public Vocational Training Centers, are considered short-term professional qualifications and are offered free of charge to all unemployed job seekers registered at the respective employment office (excluding foreign language courses).",
    "centerP2": "If you are unemployed and not registered at the employment office, to receive this free service, you need to register as a job seeker through the e-Albania platform in the service",
    "linkP2": "Job seeker registration application",
    "centerP3": "After obtaining the status of \"Unemployed Job Seeker\", you can be referred by the Employment Office to attend a free vocational training course, or you can apply yourself through the e-Albania platform. The application for the vocational training course is done online through the e-Albania government portal, in the service",
    "linkP3": "Application for registration in a vocational training course",
    "centerP4": "Required documentation for application",
    "centerP5": "-Certificate/Diploma of the highest educational level.",
    "centerP6": "Time required to receive the service",
    "centerP7": "14 days",
    "centerP8": "For any questions or uncertainties regarding the process, you can contact at the address",
    "centerP9": "contact@akpa.gov.al.",
    "centerP10": "Before applying, we encourage you to get informed about the vocational courses in your field of interest and the respective programs, by browsing",
    "centerP11": "Public Vocational Training Centers",
    "centerP12": "Vocational Training Courses",
    "centerP13": "offered in public vocational training institutions.",

    "univP3": "For all students who are enrolled in a high school or another school and wish to continue studies in vocational education, they have the right to deregister or cancel the application until one day before the start of school and can enroll in vocational education schools in the service",
    "univP4": "Required documentation",
    "univP5": "-The legal guardianship document in cases when the child is registered by the person who has legal guardianship (only when the application is made by the child's legal guardian);",
    "univP6": "Time required to receive the service",
    "univP7": "10 days",
    "univP8": "For any questions or uncertainties regarding the process, you can contact at the address",
    "univP9": "contact@akpa.gov.al.",
    "univP10": "Suggestion:",
    "univP11": "Before applying, we encourage you to identify educational institutions that offer vocational education in your field of interest. To learn about available programs, you can check and browse",
    "univP12": "Vocational Schools",
    "univP13": "Directions",
    "aplikimKursProfesional": "\"Application for registration in a vocational training course\"",
    "kliko": "here"
},

//Page(bursa)
"BursaTransport":{
    "mainTitle":"Scholarship and Transport",
    "intro": "Vocational schools offer scholarships and transportation reimbursement programs, providing access and support for students pursuing their vocational education journey.",
    "p1": "Financial barriers should never prevent you from pursuing professional education, which is why we offer a comprehensive scholarship program that recognizes academic achievement, talent and dedication. Our scholarships provide eligible students with financial assistance, empowering them to pursue their dreams and achieve their career goals.",
    "p2": "In addition to scholarships, we understand that transportation can be a challenge for our students, who do not necessarily live near campuses. We want travel to and from our educational institutions to be convenient and affordable. That's why we offer a transportation reimbursement program, allowing eligible students to receive financial support to cover their transportation costs.",
    "p3": "By combining scholarships and transportation reimbursement, we strive to create an inclusive and supportive learning environment for all of our students.",
    "criterionsTitle": "The deadline for submitting documents is no later than November of each year.",
    "criterions": "The list of documentation for applying for a scholarship according to the criteria defined in VKM 666, dated 10.10.2019, amended, for the professional pre-university education system:",
    "docs": "List of documentation for scholarship application according to the criteria defined in VKM 666, dated 10.10.2019, amended, for the professional pre-university education system.",
    "EconomicCriterionTitle": "Economic criteria:",
    "EconomicCriterionDesc": "Students who follow the learning process in one of the professional education schools 5 km away from their place of residence benefit from free accommodation in dormitories as well as a half scholarship or a full scholarship depending on the family composition and per capita income. Students who do not live in the dormitory but meet the criteria for the scholarship are paid the full value of the scholarship in second level banks.",
    "ResultCriterionTitle": "Criterion of the achieved results:",
    "ResultCriterionDesc": "Vocational education students who have finished the previous school year with an average grade of 9 (nine) to 10 (ten), benefit from a full scholarship.",
    "economicL5": "Proof of income/salary for each major family member",
    "economicL6": "Job seeker's certificate for each family member (unemployed)",
    "economicL7": "Proof of PAK/NE payment (certificate of the last month with wet stamp from the Administrative Unit)",
    "economicL8": "Proof of old age pension payment",
    "economicL9": "Certificate of disability pension payment",
    "economicL11": "Certification from the Administrative Unit that there is no 9-year school within the territory of the Administrative Unit (only for point 2.1/b)",
    "resultL1": "Personal certificate",
    "resultL2": "Family certificate",
    "resultL3": "Certificate from the school (for registration and regular attendance)",
    "resultL4": "Certificate from taxes that there is/is no business for each major member of the family (Downloaded from e-albania in the Certificate service for unregistered individuals)",
    "resultL5": "Vocational school certificate for the previous year with all grades 9-10.",
    "NonEconomicCriterionTitle": "Non-economic criteria:",
    "Ol1": "Students, who have obtained the legal status of an orphan, are provided free of charge:",
    "Ol1Li1": "scholarship,",
    "Ol1Li2": "accommodation/sleeping,",
    "Ol1Li3": "health service,",
    "Ol1Li4": "clothes, books and notebooks (10,000 ALL per year)",
    "Ol1Li5": "dental service and medications.",
    "Ol1Li6": "supplementary payment also for the period of summer holidays",
    "Ol2": "Full scholarships are available to students who attend full-time professional education in the following fields of study:",
    "Ol2Li1": "Agriculture,",
    "Ol2Li2": "Veterinarian,",
    "Ol2Li3": "Forests,",
    "Ol2Li4": "Construction,",
    "Ol2Li5": "Fishing,",
    "Ol2Li6": "Sailor,",
    "Ol2Li7": "Geology-mining",
    "Ol2Li8": "Drilling and utilization of oil and gas resources;",
    "Ol3": "Students who prove the legal status of paraplegic, tetraplegic invalid, as well as persons with limited abilities determined by the medical-legal commission, are provided free of charge:",
    "Ol3Li1": "mechanics,",
    "Ol3Li2": "electronics,",
    "Ol3Li3": "electrotechnics,",
    "Ol3Li4": "wood processing,",
    "Ol3Li5": "geodesy,",
    "Ol3Li6": "transportation services,",
    "Ol3Li7": "thermal hydraulics, benefit from a full scholarship.",
    "Ol4": "Students of the Roma and Egyptian national minority, who attend secondary vocational schools, benefit for free:",
    "O4Li1": "Full scholarship",
    "Ol4Li2": "clothes, books and notebooks, (10,000 ALL per year)",
    "Ol5": "Students who prove the legal status of paraplegic, tetraplegic invalid, as well as persons with limited abilities determined by the medical-legal commission, are provided free of charge:",
    "Ol5L1": "Full scholarship,",
    "Ol5L2": "accommodation/sleeping,",
    "Ol5L3": "health service,",
    "Ol5L4": "dental service and medications,",
    "Ol5L5": "assistant teacher, after assessing the need for professional education of the student with disabilities.",
    "Ol6": "Students with one parent, when one of the parents is not living, are provided free of charge:",
    "Ol6Li1": "Additional payment also for the period of summer holidays",
    "costTitle": "REFUND OF SHIPPING COSTS",
    "quote": "All students/parents and directorates of vocational schools, for students who register for the first time in the 10th grade and who benefit from a scholarship according to the criteria defined in Decision no. 666, dated 10.10.2019 \"On financial quotas for food in canteen and dormitory, determining the criteria for the benefit of scholarships and payments for students of pre-university education in public educational institutions\", officially send the requests and relevant documentation according to the beneficiary categories for the approval of the scholarship to the local self-government units of the student's permanent residence.",
    "costDesc" : "Pursuant to VKM no. 119, dated 01.03.2023 \"On the use of public funds for the transportation of educational employees who work and students who study outside the place of residence\" for students who attend public vocational education, who travel from their permanent place of residence to a another place of residence, more than 5 km to 50 km away from the public vocational education provider, the cost of transport is covered.",
    "cat1L1": "Personal certificate (can be downloaded from e-albania)",
    "cat1L2": "Family certificate (can be downloaded from e-albania)",
    "cat1L3": "Certificate from the school (for registration, regular and non-residual attendance)",
    "cat1L4": "Proof of orphan status.",
    "cat1L5": "Certificate from taxes that there is/is no business for each major member of the family (Downloaded from e-albania in the Certificate service for unregistered individuals)",
    "catL6": "Certification from the dormitories (on registration or inability to accommodate",
    "catL7": "Certificate from taxes that there is/is no business for each major member of the family (downloaded from e-albania in the Certificate service for non-registered individuals)",
    "catL8": "Certificate from the professional school in the primary branches of the country's economic development.",
    "catL9": "Certification for students (girls) from the vocational school in the fields of mechanical, electrotechnical, woodworking, geodesy, service vehicles, thermo-hydraulics.",
    "catL10": "Certification as a member of the Roma/Egyptian community",
    "catL11": "Certification of pre-tetraplegic disability status and PWD payment. (can be downloaded from e-albania or obtained from the Administrative Unit)",
  },

//Page(arsimi-i-mesem)
"Highschool": {
    "banner": "Get to know the Vocational Training System",
    "introText": "The public vocational education and training system consists of 41 institutions, of which 31 vocational schools and 10 training centers that offer secondary, post-secondary and short-term vocational qualifications for all ages",
    "list1": "students who have finished 9 years of education and want to continue secondary education (professional education system),",
    "list2": "high school graduates who have completed secondary studies and want to continue their studies at higher levels (post-secondary or university system)",
    "list3": "adults with or without skills who want to enrich their portfolio with short-term additional skills (vocational training system)",
    "ShkollaP1": "Vocational secondary education offers an individual the opportunity to qualify level II, III, IV of the Albanian Framework of Qualifications from a qualified employee to a secondary technician in the relevant field of study.",
    "ShkollaP2": "Public vocational secondary education institutions offer 22 study courses and 83 profiling opportunities.",
    "ShkollaP3": "Compared to general education, vocational education provides a deeper focus on a particular field and prepares students for specific occupations. This type of education focuses on practical skills and broadening of applicable knowledge rather than theoretical knowledge of breadth of subjects.",
    "ShkollaP4": "After completing this program, students receive a certificate or a professional diploma, which gives them the opportunity to integrate directly into the labor market as a specialist in the relevant field of study. They can also pursue higher studies in post-secondary vocational or university education.",
    "ShkollaP5": "For detailed information on the educational programs offered in the public Vocational Training system, get to know",
    "link": "the directions that are offered",
    "and": " and ",

    "arsimiPasTeMesem": <>
        Post-secondary professional education provides an individual with the opportunity to qualify at Level V of the Albanian Qualifications Framework, designed to meet the labor market's need for qualified professionals with deep knowledge and specialized skills. Qualifications at this level include specialized technical, managerial, and communication skills, as well as in-depth knowledge of the processes and procedures of their area of specialization. Upon completion of this level, individuals are prepared to perform complex tasks, take on significant responsibilities, and manage major projects in their field of specialization.<br/><br/>
        After completing the professional study program, students receive a professional certificate, which gives them the opportunity to integrate directly into the labor market as specialists in their respective field of study.<br/><br/>
        Public vocational secondary education institutions offer 7 post-secondary tracks with opportunities for continuation in higher education at universities.<br/><br/>
        For detailed information about the educational programs offered in the public Professional Training system, familiarize yourself with {" "}
    </>,
    "arsimiLink": "post-secondary directions",
    "centerP1": "The Public Professional Training System offers opportunities for qualification and requalification in the most demanded professions in the country's most important sectors.",
    "centerP2": "Public Professional Training Centers in the country offer a range of 100 short-term professional training courses, as well as courses in Digital Skills, Start Smart, and Foreign Languages (English, German, and Italian). These professional courses are considered short-term professional qualifications, offered by the Public Professional Training Centers, for various categories of individuals, and are offered free of charge to all unemployed job seekers registered at the relevant employment office.",
    "centerP3": "The curricula of these courses are unified at the national and central level, oriented and designed by the National Agency for Education and Professional Training & Qualifications (AKAFP&K) and approved by the Ministry Responsible for Professional Training. They are offered in the same form by all public professional training institutions. Upon successful completion of the course, you can be equipped with certificates that are valid in the labor market.",
    "centerP4": "For detailed information about the professional qualifications offered in the public Professional Training system, familiarize yourself with ",
    "centerLink": "professional training courses",
    "apply": "How to apply",

    "shkolla": "List of professional schools",
    "qendra": "List of public vocational training centers ",
    "universitet": "List of Universities",

  },

//Page(aftesi-ndryshe)
"SpecialAbilities": {
    "banner": "Opportunities for people with disabilities",
    "introText": "Vocational education and training in Albania offers study opportunities for people with limited abilities, through reasonable adaptation of facilities in institutions as well as special support through assistant teachers with special specializations for them. During the study, students with special disabilities do not lose the payments for the Limited Ability, they are also supported with government scholarships.",
    "socialTitle": "About the Social Fund",
    "socialText": "The purpose of the Social Employment Fund is to provide and administer financial mechanisms for the promotion of employment, integration and social inclusion of persons with disabilities, including deaf persons and work invalids assessed as partially able to work by the relevant commissions.",
  },

//Page(CampusesAndSocialLife)
"CampusesAndSocialLife": {
    "banner": "Campuses and Social Life",
    "generalActivity": "General activities",
    "generalActivityL1": "The National Chess Championship in cooperation with the Albanian Chess Federation. The first phase will take place on a school basis in December, the second phase on a district basis in February and the final in March-April.",
    "generalActivityL2": "European Week of Sport,",
    "generalActivityL3": "Olympic Week,",
    "generalActivityL4": "National Olympics",
    "generalActivityL5": "European Day of Languages,",
    "generalActivityL6": "World Romani Language Day, UNESCO,",
    "generalActivityL7": "International Children's Rights Day,",
    "generalActivityL8": "Anti-Bullying Day,",
    "generalActivityL9": "International Day of the Rights of Persons with Disabilities,",
    "generalActivityL10": "Human Rights Day, UNESCO",
    "generalActivityL11": "International Day of Migrants, UNESCO,",
    "generalActivityL12": "Commemorating the Holocaust and Crimes Against Humanity,",
    "generalActivityL13": "World Mother Language Day,",
    "generalActivityL14": "International Day dedicated to Sport for Peaceful Development,",
    "generalActivityL15": "International Roma Day,",
    "generalActivityL16": "World Book Day,",
    "generalActivityL17": "International e-twinning day,",
    "generalActivityL18": "Day against the Exploitation of Child Labor,",
    "generalActivityL19": "European Day of Remembrance for all victims of totalitarian regimes",
    "generalActivityL20": "International Education Day, UNESCO,",
    "generalActivityL21": "International Day of Mathematics, UNESCO,",
    "generalActivityL22": "World Poetry Day, UNESCO,",
    "generalActivityL23": "World Water Day, UNESCO,",
    "generalActivityL24": "International Day of Coexistence in Peace, UNESCO,",
    "generalActivityL25": "World Day for Cultural Diversity, Dialogue and Development. UNESCO,",
    "generalActivityL26": "World Environment Day, UNESCO,",
    "generalActivityL27": "International Youth Day, UNESCO,",
    "generalActivityL28": "The calendar of the Days of Europe and the annual activities for the recognition of the institutions of the European Union and the Council of Europe.",
    "generalActivityL29": "Competitions at the level of the local educational institution, responsible for pre-university education, with the theme \"Getting to know Europe\"",
    "generalActivityL30": "European Movement Week \"Now we move\"",
    "generalActivityL31": "National holidays.",
    "activity": "Events",
    "activityL1": "“Open Days“",
    "activityL1Desc": "Vocational Schools welcome 9th grade students, with the aim of: Informing students and their parents about the public offer of professional education and opportunities for employment or further studies, familiarization with the school environment, mainly those of professional practices where the profession is taught , raising the awareness of the young generation about the importance of the profession and its connection with the labor market, promoting the impact of education and professional training in increasing the level of employability of young people, informing about the needs of the labor market for professionals and opportunities for internships professional in the most popular businesses.",
    "activityL2": "Meetings with the school's partner businesses",
    "activityL2Desc": "with actors of the labor market, employment offices, etc. to increase opportunities for professional practice and employment.",
    "competition": "Competitions",
    "competitionL1": "\"Gastro Alb 6\", National Proffesional Schools",
    "competitionL1Desc": "Gastro Alb is the most important competition so far for the sector, which is organized every year, which took place at the Food & Drink Expo 2023, where all the professional schools of tourism in the country compete.",
    "competitionL2": "\"Re-Debate\" competition",
    "competitionL2Desc": "The main goal of the competition is to promote the culture of debate in Albania among high school youth, the cultivation of critical thinking, ethics in communication, tolerance and the value of active citizenship. This competition has three stages:",
    "competitionL2DescL1": "Training phase",
    "competitionL2DescL2": "Stage of local debates",
    "competitionL2DescL3": "The final stage",
    "competitionL3": "FOOD & DRINK EXPO 2023 International Fair",
    "competitionL3Desc": "The 5th edition, with the participation of professional schools in the Hospitality-Tourism field",
    "competitionL4": "Competitions between schools in the same directions",
    "competitionL4Desc": "Participation of vocational school students in school, national and international competitions.",
    "expo": "Fairs",
    "expoL1": "Tourism Expo 2023, and the Tourism Ideation Hackathon competition",
    "expoL1Desc": "The Tourism Ideation Hackathon competition is held every year, as an opportunity to offer young people passionate about tourism, nature and the environment a creative environment for the most attractive tourist package for all tourists, revealing intelligent solutions for the development of sustainable tourism, also based on the premise that technology can play a key role in making tourist destinations more sustainable as well as more competitive.",
    "expoL2": "Agrotech EXPO",
    "expoL2Desc": "The 4th edition of the AGROTECH EXPO International Agriculture, Livestock and Horticulture Fair, organized by Expocity Albania, will take place on October 18-20, 2023.",
    "expoL3": "Job and Skills Fair at the national level entitled \"Opportunity. Ability. Hiring\"",
    "expoL3Desc": "The Job Fair helps match supply and demand in the labor market and will help to adequately manage all available opportunities and workforce potential.\n" +
        "This fair takes place in all cities and the conclusion took place on April 25-26.",
    "expoL4": "Fairs organized at school level",
    "expoL4Desc": "Vocational school students hold fairs at different times of the year, demonstrating the skills acquired while studying in vocational schools.",
  },

//Page(internship)
"Internship": {
    "banner": "Internship"
  },

//Page(learning)
"LearningAndPractice": {
    "banner": "Learning and Practice",
    "intro": "The learning process in Vocational Education schools is dynamic and hands-on, preparing students for success in the real world.",
    "p": "Our curriculum focuses on practical skills and industry-relevant knowledge, allowing students to apply what they learn in a supportive and interactive environment. Through a combination of classroom instruction, school-based practicum and industry, students gain valuable experience and develop a strong foundation in their chosen fields. Our teachers guide and mentor students, fostering a passion for lifelong learning and ensuring they are well equipped to meet the demands of the ever-evolving job market. In vocational training institutions, the learning process is an inclusive and transformative journey, empowering students to enter the professional world with confidence.",
  },

//Page(dormitory)
"Dormitory": {
    "banner": "Dormitories",
    "intro": "Accommodation in dormitories of vocational education students.",
    "p": "Students of Vocational High Schools throughout Albania have the option of free accommodation near high school dormitories, which are administered by the municipalities that have jurisdiction over them. In many municipalities, dormitories are very close to the location of these schools and have sufficient capacity to cover the accommodation needs of students.",
    "address": "Address: ",
    "shkoder": "The district of Shkodra",
    "shkoderL1": "The students of the Industrial School \"Arben Broci\", the Forestry Technical School \"Kolë Marjini\", the Technological School \"Hamdi Bushati\" are accommodated in the dormitory of the high school of music \"Preng Jakova\".",
    "shkoderL1Location": "\"Hotaj\" street, Rus quarter, Shkodër.",
    "shkoderL2": "Vocational school \"Ndre Mjeda\" Bushat, has a dedicated dormitory for its students, which is located very close to the school.",
    "shkoderL2Location": "Bushat administrative unit",
    "lezhe": "Lezha district",
    "lezheL1": "Students of the professional school \"Kolin Gjoka\", Lezhë, are accommodated in the only dormitory that this municipality has, which is located in the yard of this school. This dormitory was reconstructed in 2022 and has a contemporary infrastructure.",
    "lezheL1Location": "Street: L. Gurakuqi, in front of the Lezhë Mosque. ",
    "diber": "District of Dibre",
    "diberL1": "The students of the professional school \"Nazmi Rushiti\", Dibër, are accommodated in the dormitory, the only dormitory that this municipality has, which is located in the yard of this school.",
    "diberL1Location": "\"Tercilio Cardinale\" Street, Demokracia Square, Dibër.",
    "tirane": "The district of Tirana",
    "tiraneL1": "The Directorate of High School Dormitories in Tirana offers accommodation options for vocational school students in two dormitories, one for boys and the other for girls. Students of Hotel-Tourism, \"Karl Gega\", Electrical Engineering \"Gjergji Canco\" and Technical-Economic secondary schools are accommodated in these dormitories. Dormitory for boys: Dormitory address Hospitality - Tourism: Rruga e Kavaja near the palace with arrows. Dormitory for girls: Dormitory address of the Technical-Economic school: Rruga e Elbasani.\n",
    "tiraneL2": "The students of Golem \"Charles Telford Erickson\" National High School of Agribusiness are accommodated in the only dormitory that this municipality has, which is located next to this school. This dormitory was reconstructed in 2022 and has a contemporary infrastructure.",
    "tiraneL2Location": "Golem, Kavaje",
    "durres": "Durres County",
    "durresL1": "The students of the Vocational High School \"Beqir Çela\" are accommodated in the dormitory of this municipality, which is located next to this school.",
    "durresL1Location": "Neighborhood No. 14. Bajram Tusha Shkozet St.",
    "durresL2": "The students of the \"Hysen Çela\" Technological High School are accommodated in the dormitory of the \"Benardina Qerazhia\" high school of physical culture.",
    "durresL2Location": "\"Kristo Sotiri\" street, Durrës.",
    "elbasan": "Elbasan district",
    "elbasanL1": "The students of \"Ali Muftiu\" Vocational High School and \"Sali Ceka\" Vocational High School are accommodated in the only dormitory that this municipality has.",
    "elbasanL1Location": "Near Elbasan Pedagogical School. ",
    "elbasanL2": "The students of the Vocational High School \"Mihal Shahini\" Cërrik, are accommodated in the only dormitory that this municipality has, which is located next to this school.",
    "elbasanL2Location": "\"Gimnaziste\" Street, Cërrik.",
    "fier": "Fier district",
    "fierL1": "Students of \"Petro Sota\" School and \"Rakip Kryeziu\" Agricultural High School (Liberation), are accommodated in the dormitory of the professional agricultural school \"Rakip Kryeziu\". This dormitory was reconstructed in 2022.",
    "fierL1Location": "Ferma Çlirim, Fier.",
    "fierL2": "The students of the Agricultural Mechanical School, Lushnjë are accommodated in the dormitory of this municipality, which is located in the yard of this school",
    "fierL2Location": "\"Skënder Libohova\" neighborhood, Lushnje.",
    "berat": "The district of Berat",
    "beratP": "The students of the \"Kristo Isak\" vocational high school and the \"Stiliano Bandilli\" School are accommodated in the dormitory of this municipality, which is located near the Kristo Isak school.",
    "beratPLocation": "\"Dasaret\" Street, Berat.",
    "korce": "Korca district",
    "korceL1": "The students of the technical-economic high school \"Isuf Gjata\", the construction high school, the technical vocational high school \"Demir Progri\", the agribusiness high school \"Irakli Terova\" are accommodated in the only dormitory of this municipality, which is located near Agribusiness High School \"Irakli Terova\"",
    "korceL1Location": "neighborhood 17, \"Ajet Gjindoli\" Street, Korçë.",
    "vlore": "Vlora district",
    "vloreL1": "The students of the \"Independence\" Industrial High School and \"Tregtare\" High School are accommodated in the common dormitory, which is located next to the \"Independence\" Industrial High School.",
    "vloreL1Location": "Bulevardi Ismail Qemali\", near the Trojan Horse, Vlora.",
    "vloreL2": "The students of the \"Antoni Athanas\" Vocational High School, Sarandë are accommodated in the only dormitory that this municipality has, which is located next to this school",
    "vloreL2Location": "Neighborhood no. 2, \"Adem Sheme\" Street, Saranda.",
    "gjirokaster": "Gjirokastra district",
    "gjirokasterL1": "The students of the Vocational High School \"Thoma Papapano\", Gjirokastër are accommodated in the dormitory of the pedagogical school",
    "gjirokaterL1Location": "Lagjja 18 sepeti, Gjirokastër",
  },

//Page(transport)
"Transport": {
    "banner": "Transport",
    "intro": "REFUND OF SHIPPING COSTS",
    "desc": "Pursuant to VKM no. 119, dated 01.03.2023 \"On the use of public funds for the transportation of educational employees who work and students who study outside the place of residence\" for students who attend public vocational education, who travel from their permanent place of residence to a another place of residence, more than 5 km to 50 km away from the public vocational education provider, the cost of transport is covered.",
  },

//Page(drejtime)
"Drejtime": {
    "banner": "Find the direction of study and branches",
    "introText": "Choose the direction of study to build a successful career fully adapted to the demands of the labor market.",
    "filters": "Filters",
    "degreeCategory": "Degrees",
    "profileCategory": "Profiles",
    "schoolCategory": "Direction in vocational Secondary Education",
    "centerCategory": "Professional Training Courses",
    "middleCategory": "Direction after",
    "universityCategory": "Direction in University Studies",
    "degree": "Directions",
    "center": "Courses",
    "institution": "Directions and Courses",
    "middle": "Future Directions",

    "search": "Search Directions",
  },

//Page(career-advice)
"CareerAdvice": {
    "banner": "Career advice",
    "intro": "Career advice",
    "p1": "Welcome to our page dedicated to VET schools! We understand the importance of practical skills in vocational education and are here to provide you with valuable practical advice and support for your professional journey.",
    "l1": "Explore Career Paths: Take the time to research and explore different career paths within your chosen field. Identify industries and job roles that match your interests and aspirations. This will help you make informed decisions and set clear goals for your future.",
    "l2": "Build a professional network: Building a strong professional network is essential for career development. Attend industry events, network with professionals in your field, and join relevant associations or online communities. Networking can open doors to employment opportunities, mentoring and valuable insights from experienced professionals",
    "quote": "At my school there are many opportunities to develop your network of contacts. The first help I received came from the development unit at school, but with the passage of time there is more cooperation among the students to get to know the different possibilities.",
    "afproText": "One of these networks is",
    "afproLink": "AFPRO",
    "l3": "Look for industry experience: Practical experience is highly valued by employers. Look for opportunities to gain experience through internships or part-time work in your field. These experiences will not only enhance your CV, but also provide valuable industry insights and help you develop your technical skills.",
    "l4": "Continuously improve your skills: Stay up to date with the latest industry trends and technological advances. Look for opportunities to improve and expand your knowledge through seminars, certifications or online courses. Adaptability to new technologies and practices will make you more sought after by employers.",
    "l5": "Seek mentorship and guidance: Having a mentor can provide valuable guidance and support throughout your career journey. Connect with professionals in your field who can offer advice, share their experiences, and help you navigate challenges. Their knowledge can be helpful in making informed career decisions.",
    "l6": "Development of soft skills: In addition to technical skills, employers also value soft skills such as communication, teamwork and problem solving. Improve these skills through group projects, taking on leadership roles and participating in extracurricular activities.",
    "l7": "Stay abreast of industry demands: Keep yourself up-to-date on industry trends, job market demands and emerging opportunities. Follow industry news, attend conferences and engage in professional development activities. These insights will help you make strategic career decisions.",
    "p2": "Remember, your time at VET school is an important step towards a successful career. By following these practical tips and career advice, you'll be better equipped to make informed choices, acquire relevant skills and thrive on your career journey.",
  },

//Page(degreeDetail)
"DegreeDetail": {
    "list": "Institution List",
    "apply": "If you want to apply for this direction click",
    "link": " here",
    "mapLocation": "Location",
    "popupLink": "Open in Google Maps",
  },

//Page(community)
"Community": {
    "intro": "Success Cases",
  },

"Courses":{
  "nr_rendor": "Serial number",
  "name": "Name",
  "curricula_id": "Curricula",
  "center_id": "Center",
  "course_id": "Course",
  "total_hours": "Total hours",
  "weekly_periodicity": "Weekly periodicity",
  "session_time": "Session time",
  "duration": "Duration",
  "course_start_date": "Start date",
  "course_end_date": "End date",
  "fee": "Fee",
  "description": "Description",
}
}
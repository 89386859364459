import { createSlice } from "@reduxjs/toolkit";


export const schoolFiltersSlice = createSlice({
    name: 'schoolFilters',
    initialState:{
        currentFilter: "shkolla",
    },
    reducers: {
        schoolFilterChange: (state, action) => {
            state.currentFilter = action.payload
        },
    }
})

export const { schoolFilterChange } = schoolFiltersSlice.actions;
export default schoolFiltersSlice.reducer;
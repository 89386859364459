import React, {useEffect, useState} from 'react'
import Banner from "../Components/Shared/Layout/Banner";
import withLanguage from "../HOC/withLanguage";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import Axios from "axios";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import {AccordionBody, AccordionContainer, AccordionTitle} from "../Components/Shared/Layout/InstitutionAccordion";
import {InnerContent, InnerLeft, InnerRight} from "../Components/Shared/Layout/InnerContent";


const LearningAndPractice = ({language})=>{

    return(
        <>
            <Banner img="/LearningAndPractice/banner.jpg" title={language.banner}/>
            <IntroBlock>{language.intro}</IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <p className="text-lg">{language.p}</p>

                {/*<AccordionContainer panel="panel1">*/}
                {/*    <AccordionTitle title="Shkolla Profesionale" />*/}
                {/*    <AccordionBody>*/}
                {/*        <p className="text-lg">{language.p}</p>*/}
                {/*    </AccordionBody>*/}
                {/*</AccordionContainer>*/}

                {/*<AccordionContainer panel="panel2">*/}
                {/*    <AccordionTitle title="Qendra Formimi" />*/}
                {/*    <AccordionBody></AccordionBody>*/}
                {/*</AccordionContainer>*/}
            </div>
        </>
    )
}

export default withLanguage(LearningAndPractice, 'LearningAndPractice')
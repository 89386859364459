import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import withLanguage from "../HOC/withLanguage";
import {AccordionBody, AccordionContainer, AccordionTitle} from "../Components/Shared/Layout/InstitutionAccordion";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {schoolFilterChange} from "../redux/reducers/schoolFiltersSlice";
import {degreeFilterChange} from "../redux/reducers/degreeFiltersSlice";
import Button from "@mui/material/Button";

const ArsimiIMesem = ({language}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    return (
        <>
            <Banner img='/ArsimiIMesem/banner.jpg' title={language.banner}/>
            <IntroBlock>{language.introText}</IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <AccordionContainer panel="panel1">
                    <AccordionTitle title="Shkolla Profesionale" />
                    <AccordionBody>
                        <p className="text-lg py-2">{language.ShkollaP1}</p>
                        <p className="text-lg py-2">{language.ShkollaP2}</p>
                        <p className="text-lg py-2">{language.ShkollaP3}</p>
                        <p className="text-lg py-2">{language.ShkollaP4}</p>
                        <p className="text-lg py-2">
                            {language.ShkollaP5}
                            <Button onClick={()=>{
                                dispatch(degreeFilterChange("drejtim"))
                                navigate("/drejtime")
                            }} sx={{color: '#1565c0', background: 'transparent', textTransform:'lowercase', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                                "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.link}</Button>
                        </p>
                        <button onClick={()=>{
                            dispatch(schoolFilterChange("shkolla"))
                            navigate("/shkollat")
                        }} style={{color: '#1565c0', background: 'transparent', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                            "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.shkolla}</button>

                    </AccordionBody>
                </AccordionContainer>
                <AccordionContainer panel="panel2">
                    <AccordionTitle title="Shkolla Profesionale" />
                    <AccordionBody>
                        <p className="text-lg py-2">
                            {language.arsimiPasTeMesem}
                            <Button onClick={()=>{
                                dispatch(degreeFilterChange("drejtim"))
                                navigate("/drejtime")
                            }} sx={{color: '#1565c0', background: 'transparent', textTransform:'lowercase', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                                "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.arsimiLink}</Button>
                        </p>

                        <button onClick={()=>{
                            dispatch(schoolFilterChange("shkolla"))
                            navigate("/shkollat")
                        }} style={{color: '#1565c0', background: 'transparent', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                            "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.shkolla}</button>

                    </AccordionBody>
                </AccordionContainer>

                <AccordionContainer panel="panel3">
                    <AccordionTitle title="Qendra Formimi" />
                    <AccordionBody>
                        <p className="text-lg py-2">{language.centerP1}</p>
                        <p className="text-lg py-2">{language.centerP2}</p>
                        <p className="text-lg py-2">{language.centerP3}</p>

                        <p className="text-lg py-2">
                            {language.centerP4}
                            <Button onClick={()=>{
                                dispatch(degreeFilterChange("kurs"))
                                navigate("/drejtime")
                            }} sx={{color: '#1565c0', background: 'transparent', textTransform:'lowercase', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                                "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.link}</Button>
                        </p>
                        <button onClick={()=>{
                            dispatch(schoolFilterChange("qendra"))
                            navigate("/shkollat")
                        }} style={{color: '#1565c0', background: 'transparent', boxShadow: 'none', minWidth: 'auto', padding: '0', fontSize: '1.125rem', fontWeight: "400",
                            "&:hover": {background: 'transparent', boxShadow: 'none'},}}>{language.qendra}</button>

                    </AccordionBody>
                </AccordionContainer>
            </div>

        </>
    )
}

export default withLanguage(ArsimiIMesem, "Highschool")
import MultiCarousel from "../../Community/MultiCarousel";
import {useEffect, useState} from "react";
import Axios from "axios";
import CommunityVideo from "../../Community/CommunityVideo";

export default function Carousel({type}) {
    const [ isLoading, setIsLoading ] = useState(false)
    const [data, setData] = useState([]);

    useEffect( () => {
        const getData = () => {
            setIsLoading(true);
            Axios.get(`/api/data_video/video/${type}`)
                .then((res) => {
                    const imgHolder = [];
                    {
                        res.data.records.forEach(item => {
                           item.image_ids.length && imgHolder.push(item.image_ids[0])
                        })
                    }
                    getImageContent(imgHolder, res.data.records);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        getData();
    }, []);

        const getImageContent = (imageIds, data) => {
            if ( imageIds.length ) {
                Axios.post(`/api/binary_data/`, {
                    "ids": imageIds
                }, {
                    headers: {
                        "Content-Type": 'text/plain'
                    }
                })
                    .then((res) => {
                        let list = [];
                        data.forEach((item,index)=>{
                            list.push({...item,image: res.data[index]})
                        })
                        setData(list);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        setIsLoading(false)
                    });
            }
        }

    return (
        <div>
            {!isLoading &&
                <MultiCarousel>
                {
                    data?.map((item) => (
                        <CommunityVideo
                            key={item.id}
                            videoUrl={item.url}
                            thumbnailUrl={item.image.file_name}
                            name={item.name}
                        />
                    ))
                }
            </MultiCarousel>
            }
        </div>
    )
}
import React from 'react';
import { useLanguage } from "../api/helperFunctions";

const withLanguage = (WrappedComponent, whatToLoad) => {

    const HOC_with_language = (props) => {
        const language = useLanguage(whatToLoad);
        return (
            <>
                <WrappedComponent
                    language={language}
                    {...props}
                />
            </>
        );
    };
    return HOC_with_language;
};

export default withLanguage;
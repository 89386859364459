import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import withLanguage from "../HOC/withLanguage";
import Titles from "../Components/Shared/Layout/Titles";
import React from "react";
import Carousel from "../Components/Shared/Layout/Carousel";


const NewProfessional = ({language})=>{

    return (
        <>
            <Banner img='/NewProfessional/banner.jpg' title={language.banner}/>

            <IntroBlock>{language.introText}</IntroBlock>

            <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                <p className="text-lg py-3 text-justify">{language.p1}</p>
                <p className="text-lg py-3 text-justify">{language.p2}</p>
            </div>

            <Carousel type='success_case'/>

        </>
    )
}

export default withLanguage(NewProfessional,"NewProfessional")
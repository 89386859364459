import { createSlice } from "@reduxjs/toolkit";


export const navSlice = createSlice({
    name: 'nav',
    initialState:{
        navId: null,
        reRenderChange: 0
    },
    reducers: {
        switchNavMenu: (state, action) => {
            state.navId = action.payload
            state.reRenderChange += 1
        },
    }
})

export const { switchNavMenu } = navSlice.actions;
export default navSlice.reducer;
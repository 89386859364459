import React, {useEffect, useRef} from 'react'
import Box from '@mui/material/Box'
import {LayoutContent} from "../Components/Shared/Layout/LayoutContent";
import Image from "../Components/Shared/Layout/Image";
import Banner from "../Components/Shared/Layout/Banner";
import withLanguage from "../HOC/withLanguage";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageText from "../Components/HomePage/ImageText";

const HomePage = ({language})=>{
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const infoBallsConfig = [
        {path: "/si-te-aplikosh",name: language?.inputBanner?.infoBall1, hideMobile: false, src: "/icon1.svg"},
        {path: "https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=12549",name: language?.inputBanner?.infoBall2, hideMobile: false, src: "/icon2.svg"},
        {path: "/shkollat",name: language?.inputBanner?.infoBall3, hideMobile: true, src: "/icon3.svg"}
    ]
    const socialMediaConfig = [
        { platform: 'Facebook', url: 'https://www.facebook.com/akpa.gov.al/', icon: '/fbIcon.svg' },
        { platform: 'Instagram', url: 'https://www.instagram.com/puna.gov.al/?hl=en', icon: '/instaIcon.svg' },
        { platform: 'YouTube', url: 'https://www.youtube.com/channel/UCqxlY8IX71xFJlvfoEHgTzQ', icon: '/ytIcon.svg' },
        { platform: 'LinkedIn', url: 'https://www.linkedin.com/', icon: '/linkedInIcon.svg' },
        { platform: 'Twitter', url: 'https://twitter.com/', icon: '/twitterIcon.svg' }
    ];
    return(
        <>
            <Banner img='/mainBanner.png' title={isMobile ? language.banner2 : language.banner} block={isMobile ? language.bannerBlock : ""} input={true} infoBallsConfig={infoBallsConfig} containerClass="" />

            <LayoutContent mobileFullWidth={true} grid={false} className="bg-[#f4f4f4]">
                <ImageText href="/arsimi-i-mesem" imageStyle={{objectPosition:"60% 75%"}} title={language.afpSistem} format="2" description={language.afpSistemDescription} src="/ArsimiIMesem/banner.jpg"/>
                <div className="md:grid md:grid-cols-2 gap-6">
                    <ImageText href="/Drejtime" title={language.directionOfStudies} format="1" description={language.directionOfStudiesDescription} src="/fileIcon.svg"/>
                    <ImageText href="/shkollat" title={language.aboutInstitution} format="1" description={language.aboutInstitutionDescription} src="/manCheckIcon.svg"/>
                </div>
                <ImageText href="/si-te-aplikosh" title={language.applyOnline} format="3"  src="/laptopE-albaniaIcon.svg"/>
                <ImageText href="https://www.puna.gov.al/" imageStyle={{objectPosition:"25% 75%"}} title={language.internship} format="2" description={language.internshipDetails} src="/block4element.jpg"/>
                <div className="md:grid md:grid-cols-2 gap-6">
                    <ImageText href="/learning-practice" title={language.practicalLearning} format="4" src="/image30996.png"/>
                    <ImageText href="/bursa-transport"  title={language.transportGrants} format="4" src="/g31010.png"/>
                </div>
                <a className='block relative' href="https://afpro.al/">
                    <Box className="bg-black absolute h-full w-full opacity-40"></Box>
                        <Image src="image27824.png" />
                        <Box className="absolute font-bold text-bold flex flex-col text-white top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-[50%] w-[80%] xs:w-auto">
                            <h1 className='text-center md:text-5xl text-3xl uppercase text-[white] font-bold'>
                                {language.buttonElTitle}
                            </h1>
                           <h3 className='text-center md:text-3xl text-md py-3 md:py-5'>
                               {language.buttonElText}
                           </h3>
                        </Box>
                </a>
                <p className="text-3xl md:text-5xl font-bold text-center">{language.socialMedia}</p>
                <div className='flex flex-row justify-center gap-8 pb-8'>
                    {socialMediaConfig.map(({ platform, url, icon }) => (
                        <a key={platform} href={url} target="_blank" className="flex justify-center items-center p-1 sm:mx-3 md:mx-10">
                            <img src={icon} className='max-w-[30px] max-h-[30px] md:max-w-[50px] md:max-h-[50px]' alt={`${platform} Icon`} />
                        </a>
                    ))}
                </div>
            </LayoutContent>
        </>
    )
}
export default withLanguage(HomePage,"Homepage")
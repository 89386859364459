import './App.css'
import { Route, Routes } from 'react-router-dom';
import Container from "./Components/Container/Container";
import HomePage from "./Pages/HomePage.jsx";
import InstitutionDetail from "./Pages/InstitutionDetail";
import Shkollat from "./Pages/Shkollat";
import Praktika from "./Pages/Praktika";
import {Provider} from "react-redux";
import {store} from "./redux/app/store";
import SearchResult from "./Pages/SearchResult";
import ErrorPage from "./Pages/ErrorPage";
import ArsimiIMesem from "./Pages/ArsimiIMesem";
import AftesiNdryshe from "./Pages/AftesiNdryshe";
import HowToApply from "./Pages/HowToApply";
import BursaTransport from "./Pages/BursaTransport";
import Community from "./Pages/Community";
import Dormitory from "./Pages/Dormitory";
import LearningAndPractice from "./Pages/LearningAndPractice";
import CampusesAndSocialLife from "./Pages/CampusesAndSocialLife";
import NewProfessional from "./Pages/NewProfessional";
import CareerAdvice from "./Pages/CareerAdvice";
import Drejtime from "./Pages/Drejtime";
import DegreeDetail from "./Pages/DegreeDetail";

function App() {
    return (
        <>
            <Provider store={store}>
                <Container>
                    <Routes>
                        <Route exact path="/" element={<HomePage/>}/>
                        <Route exact path="/shkollat-e-mesme-profesionale/:id" element={<InstitutionDetail/>}/>
                        <Route exact path="/formation-centers/:id" element={<InstitutionDetail/>}/>
                        <Route exact path="/shkollat" element={<Shkollat/>}/>
                        <Route exact path="/profesionist-i-ri" element={<NewProfessional/>}/>
                        <Route exact path="/praktika" element={<Praktika/>}/>
                        <Route exact path="/search-result/" element={<SearchResult/>}/>
                        <Route exact path="/si-te-aplikosh/" element={<HowToApply/>}/>
                        <Route exact path="/bursa-transport/" element={<BursaTransport/>}/>
                        <Route exact path="/arsimi-i-mesem" element={<ArsimiIMesem/>}/>
                        <Route exact path="/aftesi-ndryshe" element={<AftesiNdryshe/>}/>
                        <Route exact path="/community" element={<Community/>}/>
                        <Route exact path="/campus-social-life" element={<CampusesAndSocialLife/>}/>
                        <Route exact path="/learning-practice" element={<LearningAndPractice/>}/>
                        <Route exact path="/dormitories" element={<Dormitory/>}/>
                        <Route exact path="/career-advice" element={<CareerAdvice/>}/>
                        <Route exact path="/drejtime" element={<Drejtime/>}/>
                        <Route exact path="/drejtime/:id" element={<DegreeDetail/>}/>
                        <Route exact path="*" element={<ErrorPage/>}/>
                    </Routes>
                </Container>
            </Provider>
        </>
    );
}

export default App;

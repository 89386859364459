import React, {useEffect} from 'react'
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import withLanguage from "../../../HOC/withLanguage";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchFilters from "./SearchFilters";
import searchFilters from "./SearchFilters";

// Inside your component

function SearchInput({className, onClick=()=>{}, language, value, closeCollapse = ()=>{}, showFilters = false}){
    const [inputValue,setInputValue] = React.useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Assuming 'sm' breakpoint for mobile
    const borderRadiusStyle = isMobile ? { borderRadius: "15px",width:"100%" } : {
        borderRadius: "0px",
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
    };
    const submitHandler = (e)=>{
        e.preventDefault();
        // window.open((`/search-result/?query=${inputValue}`), "_blank")
        navigate(`/search-result/?query=${inputValue}`);
        closeCollapse()
    }

    useEffect(()=>{
        if(value){
            setInputValue(value)
        }
    },[value])
    return (
        <>
            <div className="flex justify-center mt-10 w-full">
                <Paper
                    component="form"
                    className={`flex items-center w-full py-4 md:px-[4px] ${className}`}
                    onClick={onClick}
                    sx={{
                        ...borderRadiusStyle,
                        border: "1px solid #C82121",
                        boxShadow: "none",
                    }}
                    onSubmit={submitHandler}

                >
                    <InputBase
                        sx={{ml: isMobile ? 1.5 : 2.5, flex: 1, fontSize: "20px"}}
                        placeholder={language.search}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                    />

                </Paper>
                    <Button
                        sx={{
                            backgroundColor: "#C82121",
                            borderRadius: "0px",
                            borderTopRightRadius: "50px",
                            borderBottomRightRadius: "50px",
                            px: "70px",
                            color: "white",
                            "&:hover": {backgroundColor: '#C82121', boxShadow: 'none'},
                            display:isMobile ? "none" : "flex"
                        }}
                        onClick={submitHandler}
                    >
                        <SearchIcon sx={{color: "white", fontSize: "30px"}}/>
                        <Typography sx={{fontSize:"20px"}}>Kërko</Typography>
                    </Button>

            </div>
            <div className="w-full">
                {showFilters && <SearchFilters/>}
            </div>
            <Button
                sx={{
                    backgroundColor: "#C82121",
                    borderRadius: "15px",
                    py: "15px",
                    mt:"15px",
                    width:"100%",
                    color: "white",
                    "&:hover": {backgroundColor: '#C82121', boxShadow: 'none'},
                    display: isMobile ? "flex" : "none"
                }}
                onClick={submitHandler}
            >
                <SearchIcon sx={{color: "white", fontSize: "30px"}}/>
                <Typography sx={{fontSize:"20px"}}>Kërko</Typography>
            </Button>
        </>

    );
}

export default withLanguage(SearchInput, "inputComponent")
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, {useEffect} from "react";

export const AccordionContainer = ({children,panel}) => {

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        event.stopPropagation()
        setExpanded(newExpanded ? panel : false);
    };



    return(
        <Accordion className="mb-9 text-white py-4" expanded={expanded === panel} onChange={handleChange(panel)} sx={{ boxShadow: "none", borderBottom: '2px solid #DB3222', '&::before': {display: 'none'}, "&:last-of-type": {borderRadius: "0"} }}>
            {children}
        </Accordion>
    )
}

export const AccordionTitle = ({title}) => {

    return(
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: '#DB3222'}}/>} sx={{ px: "0" }}>
            <div className="font-bold uppercase text-xl text-[#DB3222]">
                {title}
            </div>
        </AccordionSummary>
    )
}

export const AccordionBody = ({children}) => {

    return(
        <AccordionDetails className="rounded-br-md rounded-bl-md shadow-inner bg-white" sx={{paddingTop: '30px'}}>
            {children}
        </AccordionDetails>
    )
}
import { createSlice } from "@reduxjs/toolkit";
import alData from "../../languages/al.js";
import enData from "../../languages/en.js";

const languageData = {
    albanian: alData,
    english: enData,
};

export const languageSlice = createSlice({
    name: 'language',
    initialState:{
        currentLanguage:"albanian",
        data: languageData["albanian"],
    },
    reducers: {
        switchLanguage: (state, action) => {
            const { language } = action.payload;
            state.currentLanguage = language;
            state.data = languageData[language]
        },
    }
})

export const { switchLanguage } = languageSlice.actions;
export default languageSlice.reducer;
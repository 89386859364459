import React from 'react';
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';

export default function SearchButton({onClick,className}){
    return(
        <IconButton onClick={onClick} className={className} sx={{color: "black", paddingBottom: "0", paddingLeft: "20px"}}>
            <SearchIcon />
        </IconButton>
    )
}
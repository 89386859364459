import React from 'react';
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import WhatsappBubble from "../Shared/Accesibility/WhatsappBubble";



export default function Container({children}){
    return(
        <div className="flex flex-col min-h-screen">
            <NavBar/>
                <div className="flex-grow">
                    {children}
                </div>
            <Footer/>
            <WhatsappBubble />
        </div >
    )
}
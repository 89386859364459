import Banner from "../Components/Shared/Layout/Banner";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import withLanguage from "../HOC/withLanguage";
import Titles from "../Components/Shared/Layout/Titles";

const Praktika = ({language})=>{
    return (
        <>
            <Banner img='/landpage.jpg' title={language.banner}/>

            <IntroBlock> {language.introText}
            </IntroBlock>

            <LayoutContent className="pb-6 gap-0 md:gap-0 lg:gap-0 md:grid-cols-2">
                <LayoutLeft className="col-span-1"></LayoutLeft>

                <LayoutRight className="col-span-1">
                    <p className="font-normal text-justify pb-5">
                        Praesent gravida lectus lacus, non luctus enim tincidunt in. Sed pretium nisl magna, non
                        suscipit sem elementum a. Vivamus ut feugiat metus. Class aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Donec dui nulla, dictum vitae ligula a,
                        pharetra tristique elit. Ut auctor efficitur nulla vitae fringilla. Aenean eget justo justo.
                        Quisque nec aliquet ligula.
                    </p>
                    <h3 className="text-xl font-bold">Lorem ipsum dolor sit amet</h3>
                    <p className="font-normal text-justify pb-5">
                        Praesent gravida lectus lacus, non luctus enim tincidunt in. Sed pretium nisl magna, non
                        suscipit sem elementum a. Vivamus ut feugiat metus. Class aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Donec dui nulla, dictum vitae ligula a,
                        pharetra tristique elit. Ut auctor efficitur nulla vitae fringilla. Aenean eget justo justo.
                        Quisque nec aliquet ligula. Nullam sodales magna a ornare condimentum. Proin vel bibendum quam.
                        Fusce nibh nisl, vestibulum non est vitae, lobortis aliquam tellus. Integer non convallis purus.
                        Ut dictum libero augue, a molestie mauris pharetra in. Quisque vestibulum tellus eget
                        scelerisque ornare.
                    </p>
                    <p className="font-normal text-justify pb-5">
                        Praesent gravida lectus lacus, non luctus enim tincidunt in. Sed pretium nisl magna, non
                        suscipit sem elementum a.
                    </p>
                </LayoutRight>
            </LayoutContent>

            {/*Highlight Block*/}
            <div className="w-full bg-[#DB3222] flex flex-col justify-center items-center py-8">
                <h3 className="md:text-4xl text-2xl font-bold md:max-w-[60%] max-w-[80%] pb-5">“Lorem ipsum dolor sit amet, consectetuer adipiscing
                    elit, sed diam
                    nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat”.</h3>
                <p className="font-normal">Emri, shkolla</p>
            </div>

            <LayoutContent className="md:py-16 gap-0 md:gap-0 lg:gap-0 md:grid-cols-2">
                <LayoutLeft className="col-span-1"></LayoutLeft>

                <LayoutRight className="col-span-1">
                    <h3 className="text-xl font-bold">Lorem ipsum dolor sit amet</h3>
                    <p className="font-normal text-justify pb-5">
                        Praesent gravida lectus lacus, non luctus enim tincidunt in. Sed pretium nisl magna, non
                        suscipit sem elementum a. Vivamus ut feugiat metus. Class aptent taciti sociosqu ad litora
                        torquent per conubia nostra, per inceptos himenaeos. Donec dui nulla, dictum vitae ligula a,
                        pharetra tristique elit. Ut auctor efficitur nulla vitae fringilla. Aenean eget justo justo.
                        Quisque nec aliquet ligula. Nullam sodales magna a ornare condimentum. Proin vel bibendum quam.
                        Fusce nibh nisl, vestibulum non est vitae, lobortis aliquam tellus. Integer non convallis purus.
                        Ut dictum libero augue, a molestie mauris pharetra in. Quisque vestibulum tellus eget
                        scelerisque ornare.
                    </p>
                    <p className="font-normal text-justify pb-5">
                        Praesent gravida lectus lacus, non luctus enim tincidunt in. Sed pretium nisl magna, non
                        suscipit sem elementum a.
                    </p>
                </LayoutRight>
            </LayoutContent>

            {/*Image Text Block*/}
            <div className="flex md:flex-row flex-col">
                <div className="md:w-1/2 w-full">
                    <img src='/landpage.jpg' alt="image-element" className="h-full w-full object-cover"/>
                </div>
                <div className="md:w-1/2 w-full bg-[#db3222] flex flex-col items-center justify-center p-5">
                    <h3 className="md:text-2xl text-xl font-bold md:max-w-[60%] max-w-full pb-5">“Lorem ipsum dolor sit amet, consectetuer
                        adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
                        volutpat”.</h3>
                    <p className="font-light italic">Emri, shkolla</p>
                </div>
            </div>

            <Titles />
        </>
    )
}

export default withLanguage(Praktika,"Practice")
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import al from '../languages/al.js';
import en from '../languages/en.js';

export const useLanguage = (whatToLoad) => {
    const currentLanguage = useSelector((state) => state.language.currentLanguage);
    const [ language, setLanguage ] = useState({});

    function sectionPicker() {
        let allData = currentLanguage === "albanian" ? al : en;
        const dataPicker = whatToLoad.split("_");

        for (let item in dataPicker) {
            if( !!allData ){
                allData = allData[dataPicker[item]];
            }
            else{
                throw({});
            }
        }

        return allData;
    }

    useEffect(() => {
        try{
            setLanguage( sectionPicker() );
        }
        catch(err){
            // TODO manage error
        }
    }, [currentLanguage])


    return language;
}


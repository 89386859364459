import React, {useEffect, useState} from "react";
import IntroBlock from "../Components/Shared/Layout/IntroBlock";
import withLanguage from "../HOC/withLanguage";
import Axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {LayoutContent, LayoutLeft, LayoutRight} from "../Components/Shared/Layout/LayoutContent";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import MapWrapper, {iconMarker} from "../Components/Maps/Map";
import CallMadeIcon from '@mui/icons-material/CallMade';

const openInGoogleMaps = (lat, lng) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, "_blank");
};

function DegreeDetail({language}) {

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([]);
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect( ()=>{
        setIsLoading(true);
        const getDegreeData = () => {
            Axios.post(`/api/institution_degree/details`, {
                "filter": +id
            }, {
                headers: {
                    "Content-Type": 'text/plain'
                }
            })
                .then((res) => {
                    setData(res.data);
                    setIsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);

                })
        }
        getDegreeData();
    },[])

    const navigateHandler = (category, catId) => {
        if(category === "shkolle_profesionale"){
            navigate(`/shkollat-e-mesme-profesionale/${catId}`);
        }else if(category === "qender_formimi") {
            navigate(`/formation-centers/${catId}`);
        }
    }

    function replaceWithBr(item) {

        if (item) {
            return item?.replaceAll("/n", "<br />")
        }
    }
    return (
        <>
            {/*<Banner img='/landpage.jpg' title={data.name}/>*/}

            {
                !!isLoading
                ? <>loading</>
                :
                    <>
                        <IntroBlock>{data?.degree?.name}</IntroBlock>
                        <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
                            <p className="text-lg py-3" dangerouslySetInnerHTML={{__html: replaceWithBr(data?.degree?.description)}}></p>
                        </div>
                        <LayoutContent>
                            <LayoutLeft>
                                {/*Map*/}
                                <h3 className="text-xl pb-5 text-[#DB3222]">{language.mapLocation}</h3>

                                <div className="h-[650px] border-2 border-[#db3222]">
                                    <MapWrapper>
                                        {data.institutions && data.institutions.map((item) =>  (
                                                <Marker position={{ lat: item.latitude, lng: item.longitude }} key={item.id} icon={iconMarker}>
                                                    <Popup>
                                                        <div className='max-w-[200px]'>
                                                            <span className="font-bold">{item.name} ({item?.municipality_id?.name})</span>
                                                            <button className="text-[#DB3222] pt-1 flex mx-auto" onClick={() => openInGoogleMaps(item.latitude, item.longitude)}>
                                                                {language.popupLink}
                                                            </button>
                                                        </div>
                                                    </Popup>
                                                </Marker>
                                            )
                                        )}
                                    </MapWrapper>

                                </div>
                            </LayoutLeft>

                            <LayoutRight>

                                {/*Institutions List*/}
                                <h3 className="text-xl pb-5">{language.list}</h3>

                                <ul>
                                    {data?.institutions?.map((item, index) => {
                                        return (
                                            <li
                                                key={item.id}
                                                className="cursor-pointer my-1 w-fit text-[#DB3222] font-semibold"
                                                onClick={()=>navigateHandler(item.type, item.id)}
                                            >{item.name} <CallMadeIcon className="max-w-[1rem]"/>
                                            </li>
                                        )})
                                    }
                                </ul>
                                <p className="text-lg py-3">
                                    {language.apply}
                                    <a href="https://e-albania.al/eAlbaniaServices/UseService.aspx?service_code=12549" target="_blank" className="text-blue-600">{language.link}</a>
                                </p>
                            </LayoutRight>
                        </LayoutContent>

                    </>

            }


        </>
    )
}

export default withLanguage(DegreeDetail, "DegreeDetail")
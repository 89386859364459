import React, { useState } from 'react';
import {Fab} from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';

function Bubble({ onClick = () => {}, children, size, backgroundColor = "black", textOver }){

    const [anchorEl, setAnchorEl] = useState(null);
    const openPopup = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const id = openPopup ? 'mouse-hover-popper' : undefined;
    return (
        <>
            <Fab
                style={{ backgroundColor: backgroundColor, position: 'sticky', top: '1em', marginRight: '8px', filter: "none"}}
                onClick={onClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                size={size}
            >
                {children}
            </Fab>
            <Popper id={id} placement={"top"} open={openPopup} anchorEl={anchorEl} style={{zIndex:6}} >
                <Paper elevation={5}>
                    <Typography style={{padding: '10px', fontWeight: "bold"}}> {textOver} </Typography>
                </Paper>
            </Popper>
        </>
    )

}
export default Bubble
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Modal, Box } from '@mui/material';
import YoutubeEmbed from "../Shared/Layout/YoutubeEmbed";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    width: '65%',
};

export default function CommunityVideo({ videoUrl, thumbnailUrl, name}) {
    const [modalOpen, setModalOpen] = useState(false);

    const handlePlayVideo = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <div className="h-[450px]">
            <div className="relative bg-gray-500 h-full rounded">
                <img src={`data:image/jpg;base64,${thumbnailUrl}`} alt="Thumbnail" className="opacity-30 h-full object-cover" />
                <div className="absolute top-[50%] left-1/2 transform -translate-x-1/2 -translate-y-[50%] w-full flex flex-col items-center">
                    <div className="font-bold bg-white rounded-full p-2"  onClick={handlePlayVideo} >
                        <PlayArrowIcon />
                        <button className="pl-3">Play Video</button>
                    </div>
                    <h2 className="text-[#aa1e91] pt-5 px-2 font-bold text-2xl uppercase text-center">{name}</h2>
                </div>
            </div>
            <Modal open={modalOpen} onClose={handleCloseModal}>
                <Box sx={style}>
                    <YoutubeEmbed className="pb-5 w-full" embedId={videoUrl} height={650}/>
                    <HighlightOffIcon className="absolute top-[-45px] right-[-45px] min-w-[35px] min-h-[35px] text-white cursor-pointer" onClick={handleCloseModal}/>
                </Box>
            </Modal>
        </div>
    );
}

import withLanguage from "../../../HOC/withLanguage";
import DividerLine from "./DividerLine";

const Titles = ({language})=>{

    return (
        <div className="m-5 xl:mx-[15rem] lg:mx-[7rem] md:mx-[3rem] xs:mx-[2.5rem]">
            <DividerLine/>
            <h1 className='text-4xl py-6 font-bold'>{language.title1}</h1>
            <DividerLine/>
            <h1 className='text-4xl py-6 font-bold'>{language.title2}</h1>
            <DividerLine/>
            <h1 className='text-4xl py-6 font-bold'>{language.title3} </h1>
            <DividerLine/>
        </div>
    )
}

export default withLanguage(Titles,"Titles")